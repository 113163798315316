import { URL_PREFIX, URL_PREFIX_SSL } from './constants';

import { isString } from 'lodash';

/*
---------- STRING ----------
*/

export const capitalize = (str) => `${str.charAt(0).toUpperCase()}${str.slice(1)}`;

/*
---------- ARRAY ----------
*/

// add or remove value in array
export const toggleValue = (arr, value) => {
    arr = arr || [];
    const index = arr.indexOf(value);

    return index < 0 ? [...arr, value] : removeArrayElementFromIndex(arr, index);
};

// return array without value from index
export const removeArrayElementFromIndex = (arr, i) => [...arr.slice(0, i), ...arr.slice(i + 1)];

/*
---------- URL ----------
*/

export const addOrNotUrlPrefix = (url) => {
    if (!isString(url)) {
        return url;
    }
    const hasPrefix = !!url.includes(URL_PREFIX) || !!url.includes(URL_PREFIX_SSL);

    return !hasPrefix ? `${URL_PREFIX}${url}` : url;
};

export const removeUrlPrefix = (url) => (isString(url) ? url.replace(/^https?:\/\//, '') : url);

export const removeSlashAfterUrl = (url) => {
    const lastChar = url.length - 1;
    return isString(url) && url.charAt(lastChar) === '/' ? url.substring(0, lastChar) : url;
};

/*
---------- REGEXP ----------
*/

const matchRegexp = (str, regexp) => {
    if (typeof str !== 'string' || str.length < 1) {
        return false;
    }
    const regex = new RegExp(regexp);
    return str.match(regex);
};

export const isUrl = (str) => {
    /* eslint-disable no-useless-escape */
    const regexp =
        /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
    return matchRegexp(str, regexp);
};

export const isImageUrl = (str) => {
    /* eslint-disable no-useless-escape */
    const regexp = /([/|.|\w|\s|-])*\.(?:jpg|jpeg|gif|png)/g;
    return matchRegexp(str, regexp);
};

export const isEmail = (str) => {
    /* eslint-disable no-useless-escape */
    const regexp = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return matchRegexp(str, regexp);
};

export const isPhoneNumber = (str) => {
    /* eslint-disable no-useless-escape */
    const regexp =
        /^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/;
    return matchRegexp(str, regexp);
};

export const isFrenchZip = (str) => {
    /* eslint-disable no-useless-escape */
    const regexp = /^(([0-8][0-9])|(9[0-5]))[0-9]{3}$/;
    return matchRegexp(str, regexp);
};

/*
---------- SPECIAL CHARS ----------
*/

export const decodeUnicodeEntities = (str) =>
    str ? str.replace(/&#(\d+);/g, (_match, dec) => String.fromCharCode(dec)) : str;

export const decodeHtmlEntities = (str) =>
    typeof str === 'string' ? str.replace('&rsquo;', "'") : str;

export const removeHtmlTags = (str) => (str ? str.replace(/<\/?[^>]+(>|$)/g, '') : null);
