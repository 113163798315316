/* SPACING */
//const spacing = 4;

/* COLORS */
/*const palette = {

};*/

/* TYPOGRAPHY */
const typography = {};

/* COMPONENTS */
const overrides = {};

/* SHADOWS */
const shadows = [];

export default {
    //spacing,
    //palette,
    typography,
    overrides,
    shadows,
};
