import { Button, Menu, MenuItem } from '@mui/material/';

import AltLink from './AltLink';
import { ArrowDropDown as ArrowDropDownIcon } from '@mui/icons-material/';
import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(2),
    },
}));

function AltButtonDropdown({ icon, label, links = [] }) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Button
                variant="outlined"
                color="secondary"
                size="small"
                startIcon={icon}
                endIcon={<ArrowDropDownIcon fontSize="small" />}
                aria-controls="export-menu"
                aria-haspopup="true"
                onClick={handleClick}
                className={classes.root}
            >
                {label}
            </Button>

            <Menu
                anchorEl={anchorEl}
                id="export-menu"
                onClose={handleClose}
                keepMounted={true}
                open={Boolean(anchorEl)}
                transformOrigin={{
                    horizontal: 'left',
                    vertical: -36,
                }}
            >
                {links.map((link, i) => (
                    <MenuItem
                        key={`link-${i}`}
                        onClick={handleClose}
                    >
                        <AltLink
                            href={link.href}
                            to={link.to}
                            target={link.target}
                            title={link.label}
                        >
                            {link.label}
                        </AltLink>
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}

export default AltButtonDropdown;
