import { Box, Link } from '@mui/material/';
import { OSM_URL, getMapByOsm } from './Venues.utils';
import { URL_TARGET_BLANK, URL_TARGET_BLANK_REL } from '../../constants';

import Loader from '../common/Loader';
import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    map: {
        position: 'relative',
        fontSize: 0, // fix : remove white space after osmMapMap
        marginBottom: theme.spacing(2),
    },
    mapOsmMap: {
        width: '100%',
        height: 150,
    },
    mapLink: {
        display: 'block',
        position: 'absolute',
        bottom: 0,
        textAlign: 'center',
        backgroundColor: theme.palette.secondary.main,
        color: '#ffffff',
        textDecoration: 'none',
        padding: '4px 15px',
        fontSize: '12px', // fix : restore size because parent's size is 0
        '&:hover': {
            backgroundColor: theme.palette.secondary.dark,
            textDecoration: 'none',
        },
    },
    placeholder: {
        height: 150,
        overflow: 'hidden',
    },
    placeholderImage: {
        position: 'relative',
        left: '50%',
        transform: 'translateX(-50%)',
    },
}));

function VenueMap({ isLoading, osm, venue }) {
    const classes = useStyles();

    const osmMap = osm ? getMapByOsm(osm) : null;

    const address = venue ? `${venue.address || ''} ${venue.city || ''} ${venue.zip || ''}` : null;
    const search = address ? `search?query=${address}` : '';

    return !isLoading ? (
        <div className={classes.map}>
            {osmMap?.src ? (
                <Box className={classes.map}>
                    <iframe
                        className={classes.mapOsmMap}
                        frameBorder="0"
                        scrolling="no"
                        marginHeight="0"
                        marginWidth="0"
                        src={osmMap.src}
                        title="Carte des événements du périgord vert"
                    ></iframe>
                    {osmMap.link && (
                        <Link
                            href={osmMap.link}
                            title="Ouvrir la carte sur Open street map"
                            className={classes.mapLink}
                            target={URL_TARGET_BLANK}
                            rel={URL_TARGET_BLANK_REL}
                        >
                            Ouvrir la carte
                        </Link>
                    )}
                </Box>
            ) : (
                <Box className={classes.placeholder}>
                    <Link
                        href={`${OSM_URL}/${search}`}
                        title="Rechercher sur Open street map"
                        target={URL_TARGET_BLANK}
                        rel={URL_TARGET_BLANK_REL}
                    >
                        <img
                            src="map-placeholder.png"
                            className={classes.placeholderImage}
                            alt="Carte de la Dordogne"
                            width="675px"
                            height="150px"
                        />
                        <div className={classes.mapLink}>Rechercher sur la carte</div>
                    </Link>
                </Box>
            )}
        </div>
    ) : (
        <Loader />
    );
}

export default VenueMap;
