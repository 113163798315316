import { GET_CATEGORIES, GET_CATEGORIES_ERROR, GET_CATEGORIES_SUCCESS } from './CategoriesActions';

const initialState = {
    errors: [],
    isFetchingCategories: false,
    list: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_CATEGORIES:
            return {
                ...state,
                isFetchingCategories: true,
                list: initialState.list,
            };
        case GET_CATEGORIES_SUCCESS:
            return {
                ...state,
                isFetchingCategories: false,
                list: action.categories,
            };
        case GET_CATEGORIES_ERROR:
            return {
                ...state,
                isFetchingCategories: false,
                errors: [...state.errors, action.err],
            };
        default:
            return state;
    }
};
