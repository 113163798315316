import { Grid, useMediaQuery } from '@mui/material';
import React, { useEffect } from 'react';
import { closeDialog, openDialog } from '../../common/AltDialog/AltDialogActions';
import { confirmDeleteVenueDialog, editVenueDialog } from '../../common/AltDialog/DialogsData';
import { deleteVenue, getVenues } from '../VenuesActions';
import { useDispatch, useSelector } from 'react-redux';

import AltConfirmationDialog from '../../common/AltConfirmationDialog';
import AltPage from '../../common/AltPage';
import VenueForm from '../VenueForm/VenueForm';
import VenuesList from '../VenuesList/VenuesList';
import { WP_ROLES_ADMIN } from '../../../constants';
import { decodeUnicodeEntities } from '../../../utils';
import { filterPastEvents } from '../../AltAgenda/AltAgenda.utils';
import { getEvents } from '../../Events/EventsActions';
import { getOrganizers } from '../../Organizers/OrganizersActions';

function MyVenuesContainer() {
    const isMediumOrMore = useMediaQuery((theme) => theme.breakpoints.up('md'));

    const dispatch = useDispatch();

    const {
        events,
        isFetchingEvents,
        isFetchingOrganizers,
        isFetchingVenues,
        isFetchingPostVenues,
        organizers,
        user,
        venues,
    } = useSelector((state) => ({
        events: state.events.list,
        organizers: state.organizers.list,
        venues: state.venues.list,
        isFetchingEvents: state.events.isFetchingEvents,
        isFetchingOrganizers: state.organizers.isFetchingOrganizers,
        isFetchingVenues: state.venues.isFetchingVenues,
        isFetchingPostVenues: state.venues.isFetchingPostVenues,
        user: state.users.user,
    }));

    useEffect(() => {
        dispatch(getVenues(user.isLogged));
        dispatch(getOrganizers());
        dispatch(getEvents(user.isLogged));
    }, [dispatch, user]);

    const openDialogPostVenue = (venue) => {
        dispatch(
            openDialog({
                ...editVenueDialog,
                component: <VenueForm venue={venue} />,
            }),
        );
    };

    const handleClickDelete = (venue) => {
        dispatch(
            openDialog({
                ...confirmDeleteVenueDialog,
                component: (
                    <AltConfirmationDialog
                        name={decodeUnicodeEntities(venue.venue)}
                        onConfirm={() => dispatch(deleteVenue(venue.id))}
                        onClose={() => dispatch(closeDialog('confirmDeleteVenue'))}
                    />
                ),
            }),
        );
    };

    const removeDuplicates = (arr) => {
        let unique = arr.reduce((a, b) => {
            if (a.indexOf(b) < 0) {
                a.push(b);
            }
            return a;
        }, []);
        return unique;
    };

    const futureEvents = filterPastEvents(events);

    const myVenues = venues.filter((venue) => venue.author === user.id);
    const myEvents = !user.roles?.includes(WP_ROLES_ADMIN)
        ? futureEvents.filter((event) => event.author === user.id)
        : futureEvents;

    const getUsedVenues = (events, userId) => {
        const otherVenues = events
            .map((event) => event.venue)
            .filter((venue) => venue.author !== userId);
        const usedVenuesId = removeDuplicates(otherVenues.map((venue) => venue.id));
        return usedVenuesId.map((id) => otherVenues.find((venue) => venue.id === id));
    };

    const usedVenues = getUsedVenues(myEvents, user.id);

    const isLoading = isFetchingVenues || isFetchingPostVenues;

    return (
        <AltPage
            user={user}
            meta={{ metaTagsId: 'myVenues' }}
        >
            <Grid
                container
                spacing={4}
            >
                <Grid
                    item
                    xs={12}
                    md={6}
                >
                    <VenuesList
                        title="Mes lieux"
                        venues={myVenues}
                        organizers={organizers}
                        events={futureEvents}
                        isLoading={isLoading}
                        isFetchingEvents={isFetchingEvents || isFetchingOrganizers}
                        openDialogPostVenue={openDialogPostVenue}
                        handleClickDelete={handleClickDelete}
                        user={user}
                        fullWidth={!isMediumOrMore}
                        isMyVenues={true}
                        displayEmpty
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                >
                    <VenuesList
                        title="Autres lieux utilisés"
                        venues={usedVenues}
                        organizers={organizers}
                        events={myEvents}
                        isLoading={isLoading}
                        isFetchingEvents={isFetchingEvents || isFetchingOrganizers}
                        openDialogPostVenue={openDialogPostVenue}
                        handleClickDelete={handleClickDelete}
                        user={user}
                        fullWidth={!isMediumOrMore}
                        displayEmpty
                    />
                </Grid>
            </Grid>
        </AltPage>
    );
}

export default MyVenuesContainer;
