import { AppBar, Container, Toolbar, useMediaQuery } from '@mui/material/';
import React, { useEffect, useState } from 'react';
import {
    loginDialog,
    mediasDialog,
    newUserDialog,
    organizerDialog,
    postEventDialog,
    postVenueDialog,
} from '../common/AltDialog/DialogsData';
import { useDispatch, useSelector } from 'react-redux';

import AltAppBarBrand from './AltAppBarBrand';
import AltAppBarMenuMedium from './AltAppBarMenuMd';
import AltAppBarMenuXs from './AltAppBarMenuXs';
import OrganizerForm from '../Organizers/OrganizerForm/OrganizerForm';
import { logout } from '../../providers/AuthenticationProvider/AuthenticationProviderActions';
import { openDialog } from '../common/AltDialog/AltDialogActions';
import { postOrganizer } from '../Organizers/OrganizersActions';
import { removeCookiesUser } from '../../providers/AuthenticationProvider/AuthenticationProvider';
import { useLocation } from 'react-router-dom';

function AltAppBar() {
    const dispatch = useDispatch();
    const location = useLocation();

    const { organizers, user } = useSelector((state) => ({
        organizers: state.organizers.list,
        user: state.users.user,
    }));

    const isLargeOrMore = useMediaQuery((theme) => theme.breakpoints.up('lg'));

    const [isInit, setIsInit] = useState(false);

    useEffect(() => {
        if (!isInit && user.isLogged && organizers.length > 0) {
            const myOrganizer = organizers.find((org) => org.author === user.id);
            if (!myOrganizer) {
                dispatch(
                    postOrganizer({
                        user,
                        organizer: {
                            organizer: user.name,
                        },
                    }),
                );
            }
            setIsInit(true);
        }
    }, [dispatch, isInit, user, organizers]);

    const openDialogLogin = () => {
        dispatch(openDialog(loginDialog));
    };

    const handleLogout = () => {
        removeCookiesUser();
        dispatch(logout());
    };

    const openDialogPostEvent = () => {
        dispatch(openDialog(postEventDialog));
    };

    const openDialogPostVenue = () => {
        dispatch(openDialog(postVenueDialog));
    };

    const openDialogOrganizer = () => {
        dispatch(
            openDialog({
                ...organizerDialog,
                component: <OrganizerForm user={user} />,
            }),
        );
    };

    const openDialogMedias = () => {
        dispatch(openDialog(mediasDialog));
    };

    const openDialogNewUser = () => {
        dispatch(openDialog(newUserDialog));
    };

    return (
        <>
            <AppBar position="fixed">
                <Container maxWidth={false}>
                    <Toolbar disableGutters={!isLargeOrMore}>
                        <AltAppBarBrand />

                        {isLargeOrMore && (
                            <AltAppBarMenuMedium
                                user={user}
                                openDialogLogin={openDialogLogin}
                                handleLogout={handleLogout}
                                openDialogPostEvent={openDialogPostEvent}
                                openDialogPostVenue={openDialogPostVenue}
                                openDialogOrganizer={openDialogOrganizer}
                                openDialogMedias={openDialogMedias}
                                openDialogNewUser={openDialogNewUser}
                                location={location}
                            />
                        )}

                        {!isLargeOrMore && (
                            <AltAppBarMenuXs
                                user={user}
                                handleLogout={handleLogout}
                                openDialogPostEvent={openDialogPostEvent}
                                openDialogPostVenue={openDialogPostVenue}
                                openDialogOrganizer={openDialogOrganizer}
                                openDialogMedias={openDialogMedias}
                                openDialogNewUser={openDialogNewUser}
                            />
                        )}
                    </Toolbar>
                </Container>
            </AppBar>
        </>
    );
}

export default AltAppBar;
