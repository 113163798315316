import { CLOSE_DIALOG, OPEN_DIALOG } from './AltDialogActions';

const initialState = {
    list: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case OPEN_DIALOG:
            return {
                ...state,
                list: [...state.list, action.data],
            };
        case CLOSE_DIALOG:
            return {
                ...state,
                list: state.list.filter((dialog) => dialog.id !== action.id),
            };
        default:
            return state;
    }
};
