import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AltAgendaAside from './AltAgendaAside';
import { getEvents } from '../Events/EventsActions';

function AltAgendaAsideContainer() {
    const dispatch = useDispatch();

    const { events, isFetchingEvents, isFetchingPostEvent, isLogged } = useSelector((state) => ({
        events: state.events.list,
        isFetchingEvents: state.events.isFetchingEvents,
        isFetchingPostEvent: state.events.isFetchingPostEvent,
        isLogged: state.users.user.isLogged,
    }));

    useEffect(() => {
        dispatch(getEvents(isLogged));
    }, [dispatch, isLogged]);

    return (
        <AltAgendaAside
            events={events}
            isLoading={isFetchingEvents || isFetchingPostEvent}
        />
    );
}

export default AltAgendaAsideContainer;
