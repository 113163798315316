import AltListItem from '../../../common/AltListItem';
import React from 'react';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { formatEventDatesToLabel } from '../../Events.utils';

const EventDate = ({ allday, end, start, ...rest }) =>
    start ? (
        <AltListItem
            {...rest}
            icon={<ScheduleIcon fontSize="small" />}
        >
            {formatEventDatesToLabel(start, end, allday)}
        </AltListItem>
    ) : null;

export default EventDate;
