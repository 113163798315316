import {
    AddCircleOutline as AddCircleOutlineIcon,
    AddLocation as AddLocationIcon,
    Event as EventIcon,
    ExitToApp,
    Group as GroupIcon,
    Image as ImageIcon,
    Map as MapIcon,
    Menu as MenuIcon,
    Place as PlaceIcon,
    ViewList as ViewListIcon,
} from '@mui/icons-material/';
import {
    ROUTE_PATH_AGENDA,
    ROUTE_PATH_CALENDAR,
    ROUTE_PATH_MAP,
    ROUTE_PATH_MY_EVENTS,
    ROUTE_PATH_MY_VENUES,
    ROUTE_PATH_ORGANIZERS,
    ROUTE_PATH_VENUES,
} from '../../AppRoutes';

import { IconButton } from '@mui/material/';
import MobileDialog from '../common/MobileDialog';
import OrganizerLogo from '../common/OrganizerLogo';
import React from 'react';
import UserLoginForm from '../User/UserLogin/UserLoginForm';
import { WP_ROLES_ADMIN } from '../../constants';

function AltAppBarMenuXs({
    openDialogPostEvent,
    openDialogOrganizer,
    openDialogPostVenue,
    openDialogMedias,
    openDialogNewUser,
    handleLogout,
    user,
}) {
    return (
        <>
            <MobileDialog
                component={!user.isLogged ? <UserLoginForm /> : null}
                lists={[
                    [
                        {
                            primaryLabel: 'Agenda',
                            icon: <ViewListIcon />,
                            isLink: true,
                            to: ROUTE_PATH_AGENDA,
                        },
                        {
                            primaryLabel: 'Calendrier',
                            icon: <EventIcon />,
                            isLink: true,
                            to: ROUTE_PATH_CALENDAR,
                        },
                        {
                            primaryLabel: 'Carte',
                            icon: <MapIcon />,
                            isLink: true,
                            to: ROUTE_PATH_MAP,
                        },
                        {
                            isDivider: true,
                        },
                        {
                            primaryLabel: 'Lieux',
                            icon: <PlaceIcon />,
                            isLink: true,
                            to: ROUTE_PATH_VENUES,
                        },
                        {
                            primaryLabel: 'Organisateurs',
                            icon: <GroupIcon />,
                            isLink: true,
                            to: ROUTE_PATH_ORGANIZERS,
                        },
                    ],
                    [
                        {
                            primaryLabel: user.isLogged ? user.name : 'Connexion organisateur',
                            icon: !user.isLogged ? (
                                <GroupIcon />
                            ) : (
                                <OrganizerLogo
                                    user={user}
                                    size={30}
                                    spacing={0}
                                    isContrib
                                />
                            ),
                            noClose: true,
                        },

                        {
                            primaryLabel: 'Ajouter un utilisateur',
                            icon: <GroupIcon />,
                            isButton: true,
                            onClick: openDialogNewUser,
                            hideItem: !user.roles?.includes(WP_ROLES_ADMIN),
                            noClose: false,
                        },

                        {
                            primaryLabel: 'Créer un événement',
                            icon: <AddCircleOutlineIcon />,
                            isButton: true,
                            onClick: openDialogPostEvent,
                            hideItem: !user.isLogged,
                            noClose: false,
                        },

                        {
                            primaryLabel: 'Ajouter un lieu',
                            icon: <AddLocationIcon />,
                            isButton: true,
                            onClick: openDialogPostVenue,
                            hideItem: !user.isLogged,
                            noClose: false,
                        },

                        {
                            primaryLabel: 'Mon profil',
                            icon: <GroupIcon />,
                            isButton: true,
                            onClick: openDialogOrganizer,
                            hideItem: !user.isLogged,
                            noClose: false,
                        },

                        {
                            primaryLabel: 'Mes événements',
                            icon: <EventIcon />,
                            isButton: true,
                            onClick: () => {
                                window.location.href = `/#${ROUTE_PATH_MY_EVENTS}`;
                                return null;
                            },
                            hideItem: !user.isLogged,
                            noClose: false,
                        },

                        {
                            primaryLabel: 'Mes lieux',
                            icon: <PlaceIcon />,
                            isButton: true,
                            onClick: () => {
                                window.location.href = `/#${ROUTE_PATH_MY_VENUES}`;
                                return null;
                            },
                            hideItem: !user.isLogged,
                            noClose: false,
                        },

                        {
                            primaryLabel: 'Mes images',
                            icon: <ImageIcon />,
                            isButton: true,
                            onClick: openDialogMedias,
                            hideItem: !user.isLogged,
                            noClose: false,
                        },

                        {
                            isDivider: true,
                        },

                        {
                            primaryLabel: 'Déconnexion',
                            onClick: handleLogout,
                            isButton: true,
                            hideItem: !user.isLogged,
                            icon: <ExitToApp />,
                        },
                    ],
                ]}
            >
                <IconButton
                    color="inherit"
                    aria-label="Ouvrir le menu principal"
                    size="large"
                >
                    <MenuIcon />
                </IconButton>
            </MobileDialog>
        </>
    );
}

export default AltAppBarMenuXs;
