import { AltAccordion, AltAccordionDetails, AltAccordionSummary } from '../../common/AltAccordion';
import { Box, Card, CardContent, Grid, Paper, Typography, useMediaQuery } from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon, ExpandMore } from '@mui/icons-material';
import { ROUTE_PATH_EVENT, ROUTE_PATH_ORGANIZER, ROUTE_PATH_VENUE } from '../../../AppRoutes';
import React, { useEffect, useState } from 'react';

import AltButton from '../../common/AltButton';
import AltLink from '../../common/AltLink';
import Loader from '../../common/Loader';
import VenueAddress from '../VenueAddress/VenueAddress';
import VenueContacts from '../VenueContacts/VenueContacts';
import VenueMap from '../VenueMap';
import { WP_ROLES_ADMIN } from '../../../constants';
import WriteHtml from '../../common/WriteHtml';
import { decodeHtmlEntities } from '../../../utils';
import { makeStyles } from '@mui/styles';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    title: {
        marginBottom: theme.spacing(2),
    },

    content: {
        '&:last-child': {
            paddingBottom: theme.spacing(1),
        },
    },

    eventsList: {
        padding: 0,
        margin: 0,
        maxWidth: '100%',
    },

    eventItem: {
        height: 24,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },

    map: {
        position: 'relative',
        fontSize: 0, // fix : remove white space after osmMap
        marginBottom: theme.spacing(2),
    },
    mapOsmMap: {
        width: '100%',
        height: 150,
    },
    mapLink: {
        display: 'block',
        position: 'absolute',
        bottom: 0,
        textAlign: 'center',
        backgroundColor: theme.palette.secondary.main,
        color: '#ffffff',
        textDecoration: 'none',
        padding: '2px 0',
        width: 113,
        fontSize: '12px', // fix : restore size because parent's size is 0
        '&:hover': {
            backgroundColor: theme.palette.secondary.dark,
        },
    },
    organizerName: {
        height: 48,
        lineHeight: '36px',
    },
}));

function VenuesList({
    displayEmpty,
    displayEmptyContacts,
    events,
    fullWidth,
    handleClickDelete,
    openDialogPostVenue,
    hideOrganizer,
    isFetchingEvents,
    isLoading,
    isMyVenues,
    organizers,
    title,
    user,
    venues,
}) {
    const classes = useStyles();
    const isMediumOrMore = useMediaQuery((theme) => theme.breakpoints.up('md'));

    const [expanded, setExpanded] = useState({});

    useEffect(() => {
        if (!expanded[0] && venues.length > 0) {
            const initialState = venues
                .map(() => 'panel1')
                .reduce(
                    (obj, item, index) => ({
                        ...obj,
                        [index]: item,
                    }),
                    {},
                );
            setExpanded(initialState);
        }
    }, [expanded, setExpanded, venues]);

    const handleChange = (index, panel) => (_event, _isExpanded) => {
        setExpanded({
            ...expanded,
            [index]: expanded[index] !== panel ? panel : expanded[index],
        });
    };

    return (
        <>
            {title && (
                <Typography
                    variant="h2"
                    className={classes.title}
                >
                    {title}
                </Typography>
            )}
            {!isLoading ? (
                <Grid
                    container
                    spacing={fullWidth ? 3 : 4}
                >
                    {venues.length > 0 ? (
                        venues.map((venue, index) => {
                            const eventsInVenue = events
                                .filter((ev) => ev.venue.id === venue.id)
                                .sort((a, b) => a.startDate > b.startDate);
                            const hasEvents = eventsInVenue.length > 0;

                            const organizer = organizers.find((org) => org.author === venue.author);
                            const organizerName = organizer?.organizer;

                            return (
                                <Grid
                                    item
                                    xs={12}
                                    md={fullWidth ? 6 : 12}
                                    key={venue.id}
                                >
                                    <Paper>
                                        <Card
                                            className={classes.cardWithDivider}
                                            elevation={0}
                                            square
                                            key={venue.id}
                                        >
                                            <CardContent className={classes.content}>
                                                <AltLink
                                                    to={`${ROUTE_PATH_VENUE}/${venue.id}`}
                                                    title={`En savoir plus sur le lieu ${venue.venue}`}
                                                    component="h2"
                                                    variant="h3"
                                                    gutterBottom
                                                >
                                                    {decodeHtmlEntities(venue.venue)}
                                                </AltLink>

                                                <VenueAddress
                                                    venue={venue}
                                                    displayEmpty={displayEmpty}
                                                    boxProps={{ mb: 2 }}
                                                />

                                                <VenueContacts
                                                    venue={venue}
                                                    displayEmptyBlock={displayEmpty}
                                                    displayEmptyContacts={displayEmptyContacts}
                                                    boxProps={{ mb: 2 }}
                                                />

                                                <VenueMap
                                                    venue={venue}
                                                    osm={venue.osm}
                                                />

                                                <Box mb={2}>
                                                    <AltAccordion
                                                        square
                                                        expanded={expanded[index] === 'panel1'}
                                                        onChange={handleChange(index, 'panel1')}
                                                    >
                                                        <AltAccordionSummary
                                                            aria-controls="panel1a-content-1"
                                                            id="panel1a-header-1"
                                                        >
                                                            Description
                                                        </AltAccordionSummary>
                                                        <AltAccordionDetails>
                                                            <Typography
                                                                component="div"
                                                                variant="body2"
                                                            >
                                                                <WriteHtml component="div">
                                                                    {venue.description}
                                                                </WriteHtml>
                                                            </Typography>
                                                        </AltAccordionDetails>
                                                    </AltAccordion>

                                                    <AltAccordion
                                                        square
                                                        expanded={expanded[index] === 'panel2'}
                                                        onChange={handleChange(index, 'panel2')}
                                                        disabled={!hasEvents}
                                                    >
                                                        <AltAccordionSummary
                                                            aria-controls="panel1a-content-2"
                                                            id="panel1a-header-2"
                                                            expandIcon={
                                                                isFetchingEvents ? (
                                                                    <Loader
                                                                        size="xxs"
                                                                        margin="none"
                                                                    />
                                                                ) : (
                                                                    <ExpandMore />
                                                                )
                                                            }
                                                        >
                                                            {isMyVenues && venue.author === user.id
                                                                ? 'Mes p'
                                                                : 'P'}
                                                            rochains événements{' '}
                                                            {isMediumOrMore && <>dans ce lieu </>}(
                                                            {eventsInVenue.length})
                                                        </AltAccordionSummary>
                                                        <AltAccordionDetails>
                                                            <ul className={classes.eventsList}>
                                                                {hasEvents ? (
                                                                    eventsInVenue.map((ev) => {
                                                                        const startDate = moment(
                                                                            ev.startDate,
                                                                        ).format('DD/MM/YY');
                                                                        return (
                                                                            <li
                                                                                key={ev.id}
                                                                                className={
                                                                                    classes.eventItem
                                                                                }
                                                                            >
                                                                                <AltLink
                                                                                    to={`${ROUTE_PATH_EVENT}/${ev.id}`}
                                                                                    title={`En savoir plus sur l'événement ${ev.name}`}
                                                                                    variant="body1"
                                                                                >
                                                                                    {`${startDate} - ${ev.name}`}
                                                                                </AltLink>
                                                                            </li>
                                                                        );
                                                                    })
                                                                ) : (
                                                                    <li>Aucun événement prévu</li>
                                                                )}
                                                            </ul>
                                                        </AltAccordionDetails>
                                                    </AltAccordion>
                                                </Box>

                                                {venue.author === user.id ||
                                                user?.roles?.includes(WP_ROLES_ADMIN) ? (
                                                    <Box
                                                        className={classes.buttonsContainer}
                                                        display="flex"
                                                    >
                                                        <AltButton
                                                            variant="contrib"
                                                            startIcon={<EditIcon />}
                                                            onClick={() =>
                                                                openDialogPostVenue(venue)
                                                            }
                                                            margin="normal"
                                                            lowerCase
                                                        >
                                                            Modifier le lieu
                                                        </AltButton>

                                                        <AltButton
                                                            variant="delete"
                                                            onClick={() => handleClickDelete(venue)}
                                                            startIcon={<DeleteIcon />}
                                                            disabled={hasEvents}
                                                            margin="normal"
                                                            lowerCase
                                                        >
                                                            Supprimer
                                                        </AltButton>
                                                    </Box>
                                                ) : (
                                                    <>
                                                        {!hideOrganizer && (
                                                            <Typography
                                                                className={classes.organizerName}
                                                            >
                                                                {organizerName && (
                                                                    <span>
                                                                        Lieu ajouté par&nbsp;
                                                                        <AltLink
                                                                            to={`${ROUTE_PATH_ORGANIZER}/${organizer.id}`}
                                                                            title={`En savoir plus sur l'organisateur ${organizerName}`}
                                                                            variant="body1"
                                                                        >
                                                                            {decodeHtmlEntities(
                                                                                organizerName,
                                                                            )}
                                                                        </AltLink>
                                                                    </span>
                                                                )}
                                                            </Typography>
                                                        )}
                                                    </>
                                                )}
                                            </CardContent>
                                        </Card>
                                    </Paper>
                                </Grid>
                            );
                        })
                    ) : (
                        <Grid
                            item
                            xs={12}
                            md={fullWidth ? 6 : 12}
                        >
                            Aucun lieu
                        </Grid>
                    )}
                </Grid>
            ) : (
                <Loader />
            )}
        </>
    );
}

export default VenuesList;
