import {
    Box,
    Card,
    CardContent,
    CardHeader,
    CardMedia,
    Grid,
    Paper,
    Typography,
    useMediaQuery,
} from '@mui/material/';
import {
    Cancel as CancelIcon,
    CheckCircle as CheckCircleIcon,
    Delete as DeleteIcon,
    Edit as EditIcon,
} from '@mui/icons-material';
import { EVENT_IMAGE_PLACEHOLDER, LAYOUT_GRID_SPACING, WP_ROLES_ADMIN } from '../../../constants';

import AltButton from '../../common/AltButton';
import AltLink from '../../common/AltLink';
import EventHeaderDate from '../EventHeaderDate/EventHeaderDate';
import EventImageModal from '../EventImageModal/EventImageModal';
import EventInfos from '../EventInfos/EventInfos';
import EventUser from '../EventUser/EventUser';
import OrganizerWidget from '../../Organizers/OrganizerWidget/OrganizerWidget';
import React from 'react';
import Venue from '../../Venues/VenueWidget/VenueWidget';
import { decodeHtmlEntities } from '../../../utils';
import { eventIsCanceled } from '../Events.utils';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    content: {
        paddingTop: theme.spacing(1),
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
        borderWidth: '1px 0',
        borderStyle: 'solid',
        borderColor: theme.palette.action.focus,
    },
    more: {
        marginLeft: 'auto',
    },
    headerDate: {
        padding: theme.spacing(3.5, 0, 3, 4),
    },
    linksTitle: {
        padding: theme.spacing(2, 3, 1),
    },
    linkButton: {
        padding: theme.spacing(1, 3),
    },
    paper: {
        marginBottom: theme.spacing(3),
    },
    deletedEventTitle: {
        marginTop: theme.spacing(20),
    },
}));

function Event({
    event,
    handleClickCancel,
    handleClickConfirm,
    handleClickDelete,
    openDialogPostEvent,
    openDialogPostVenue,
    openDialogOrganizer,
    user,
    users,
}) {
    const classes = useStyles();
    const isMediumOrMore = useMediaQuery((theme) => theme.breakpoints.up('md'));

    return event?.status === 'publish' ? (
        <>
            {(event.author === user?.id || user?.roles?.includes(WP_ROLES_ADMIN)) && (
                <Box display="flex">
                    <AltButton
                        variant="contrib"
                        onClick={openDialogPostEvent}
                        startIcon={<EditIcon />}
                        margin="normal"
                        lowerCase
                    >
                        Modifier{isMediumOrMore ? " l'événement" : ''}
                    </AltButton>

                    {!eventIsCanceled(event.name) ? (
                        <AltButton
                            variant="warning"
                            onClick={handleClickCancel}
                            startIcon={<CancelIcon />}
                            margin="normal"
                            lowerCase
                        >
                            Annuler{isMediumOrMore ? " l'événement" : ''}
                        </AltButton>
                    ) : (
                        <AltButton
                            variant="confirm"
                            onClick={handleClickConfirm}
                            startIcon={<CheckCircleIcon />}
                            margin="normal"
                            lowerCase
                        >
                            Confirmer{isMediumOrMore ? " l'événement" : ''}
                        </AltButton>
                    )}

                    <AltButton
                        variant="delete"
                        onClick={handleClickDelete}
                        startIcon={<DeleteIcon />}
                        margin="normal"
                        lowerCase
                    >
                        Supprimer
                    </AltButton>
                </Box>
            )}

            <Grid
                container
                spacing={LAYOUT_GRID_SPACING}
            >
                <Grid
                    item
                    xs={12}
                    md={7}
                >
                    <Card>
                        <Box display="flex">
                            <Box className={classes.headerDate}>
                                <EventHeaderDate start={event.start} />
                            </Box>
                            <Box>
                                <CardHeader
                                    title={decodeHtmlEntities(event.name)}
                                    titleTypographyProps={{ component: 'h2', variant: 'h2' }}
                                    subheader={event.venue.city}
                                    subheaderTypographyProps={{ variant: 'subtitle1' }}
                                />
                            </Box>
                        </Box>

                        <EventImageModal
                            image={event.image}
                            alt={event.title}
                        >
                            <CardMedia
                                className={classes.media}
                                image={event.image.url || EVENT_IMAGE_PLACEHOLDER}
                                title={event.title}
                            />
                        </EventImageModal>

                        <CardContent className={classes.content}>
                            <EventInfos
                                event={event}
                                hideLocation
                                hideOrganizer
                                showDescription
                                showPrice
                                showTags
                                divider
                            />
                        </CardContent>
                    </Card>
                </Grid>

                <Grid
                    item
                    xs={12}
                    md={5}
                >
                    <Paper className={classes.paper}>
                        <Venue
                            venue={event.venue}
                            user={user}
                            openDialogPostVenue={openDialogPostVenue}
                        />
                    </Paper>

                    <Paper className={classes.paper}>
                        <OrganizerWidget
                            organizer={event.organizer[0]}
                            user={user}
                            openDialogOrganizer={openDialogOrganizer}
                            users={users}
                        />
                    </Paper>

                    <Paper className={classes.paper}>
                        <EventUser event={event} />
                    </Paper>
                </Grid>
            </Grid>
        </>
    ) : (
        <>
            <Typography
                variant="h3"
                component="h3"
                align="center"
                className={classes.deletedEventTitle}
                gutterBottom
            >
                Cet événement n'existe pas ou a été supprimé
            </Typography>
            <AltLink
                to="/"
                aria-label="Retourner sur la page d'accueil"
                align="center"
                component="p"
                variant="body1"
            >
                Retourner sur la page d'accueil
            </AltLink>
        </>
    );
}

export default Event;
