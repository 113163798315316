import { Backdrop, Box, Fade, Modal } from '@mui/material/';

import React from 'react';
import { ZoomIn as ZoomInIcon } from '@mui/icons-material/';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(8),
    },
    image: {
        position: 'relative',
        maxWidth: '100%',
        maxHeight: '100%',

        '&:focus': {
            outline: 'none',
        },
    },
    overlay: {
        position: 'absolute',
        top: 0,
        width: '100%',
        height: '100%',
        cursor: 'pointer',
        paddingTop: 150,
        boxSizing: 'border-box',
        textAlign: 'center',
        color: 'transparent',

        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, .3)',
            color: '#ffffff',
            transition: 'all 300ms ease-in',
        },
    },
}));

function EventImageModal({ alt, children, image: { url, width, height } }) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box position="relative">
            {children}

            {url && (
                <>
                    <Box
                        className={classes.overlay}
                        onClick={handleOpen}
                    >
                        <ZoomInIcon fontSize="large" />
                    </Box>
                    <Modal
                        className={classes.modal}
                        open={open}
                        onClose={handleClose}
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 300,
                        }}
                    >
                        <Fade in={open}>
                            <img
                                src={url}
                                alt={alt}
                                className={classes.image}
                                width={width}
                                height={height}
                            />
                        </Fade>
                    </Modal>
                </>
            )}
        </Box>
    );
}

export default EventImageModal;
