import {
    DELETE_EVENT,
    DELETE_EVENT_ERROR,
    DELETE_EVENT_SUCCESS,
    GET_EVENT,
    GET_EVENTS,
    GET_EVENTS_ERROR,
    GET_EVENTS_SUCCESS,
    GET_EVENT_ERROR,
    GET_EVENT_SUCCESS,
    POST_EVENT,
    POST_EVENT_ERROR,
    POST_EVENT_SUCCESS,
    RESET_FILTERS,
    UPDATE_FILTERS,
} from './EventsActions';

const initialState = {
    event: null,
    errors: [],
    isFetchingEvent: false,
    isFetchingEvents: false,
    isFetchingPostEvent: false,
    list: [],
    filters: {
        categories: [],
        organizers: [],
        tags: [],
        venues: [],
    },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_EVENTS:
            return {
                ...state,
                list: initialState.list,
                isFetchingEvents: true,
            };
        case GET_EVENTS_SUCCESS:
            return {
                ...state,
                list: action.events,
                isFetchingEvents: false,
            };
        case GET_EVENTS_ERROR:
            return {
                ...state,
                errors: [...state.errors, action.err],
                isFetchingEvents: false,
            };
        case GET_EVENT:
            return {
                ...state,
                event: initialState.event,
                isFetchingEvent: true,
            };
        case GET_EVENT_SUCCESS:
            return {
                ...state,
                event: action.event,
                isFetchingEvent: false,
            };
        case GET_EVENT_ERROR:
            return {
                ...state,
                errors: [...state.errors, action.err],
                isFetchingEvent: false,
            };
        case POST_EVENT:
            return {
                ...state,
                isFetchingPostEvent: true,
            };
        case POST_EVENT_SUCCESS:
            return {
                ...state,
                event: state.event ? action.event : null,
                list: [...state.list.filter((ev) => ev.id !== action.event.id), action.event],
                isFetchingPostEvent: false,
            };
        case POST_EVENT_ERROR:
            return {
                ...state,
                errors: [...state.errors, action.err],
                isFetchingPostEvent: false,
            };
        case DELETE_EVENT:
            return {
                ...state,
                isFetchingDeleteEvent: true,
            };
        case DELETE_EVENT_SUCCESS:
            return {
                ...state,
                list: state.list.filter((ev) => ev.id !== action.event.id),
                event: null,
                isFetchingDeleteEvent: false,
            };
        case DELETE_EVENT_ERROR:
            return {
                ...state,
                errors: [...state.errors, action.err],
                isFetchingDeleteEvent: false,
            };
        case UPDATE_FILTERS:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...action.filter,
                },
            };
        case RESET_FILTERS:
            return {
                ...state,
                filters: initialState.filters,
            };
        default:
            return state;
    }
};
