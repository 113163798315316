import { Alert, Button, Snackbar } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import React from 'react';
import Slide from '@mui/material/Slide';
import { deleteNotification } from './AltNotificationsActions';
import { makeStyles } from '@mui/styles';
import notificationsData from './AltNotificationsData';

function SlideTransition(props) {
    return (
        <Slide
            {...props}
            direction="left"
        />
    );
}

const useStyles = makeStyles((_theme) => ({
    notificationsContainer: {
        maxWidth: 150,
    },
    alert: {
        width: 500,
    },
    actionButton: {
        borderColor: '#ffffff',
        color: '#ffffff',
    },
}));

function AltNotificationsContainer() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { id } = useSelector((state) => ({
        id: state.notifications.id,
    }));

    const handleClose = (id) => {
        dispatch(deleteNotification(id));
    };

    const notification = notificationsData?.[id];

    return notification ? (
        <Snackbar
            key={id}
            open={true}
            autoHideDuration={4000}
            onClose={() => handleClose(id)}
            TransitionComponent={SlideTransition}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
        >
            <Alert
                variant="filled"
                onClose={() => handleClose(id)}
                severity={notification.severity}
                closeText="Fermer"
                className={classes.alert}
                action={
                    notification.action ? (
                        <Button
                            variant="outlined"
                            size="small"
                            className={classes.actionButton}
                            onClick={() => (window.location.href = notification.action.link)}
                        >
                            {notification.action.label}
                        </Button>
                    ) : null
                }
            >
                {notification.message}
            </Alert>
        </Snackbar>
    ) : null;
}

export default AltNotificationsContainer;
