const contactAction = {
    label: 'Nous contacter',
    link: '/#/contacts',
};

export default {
    // Events
    getEventError: {
        message:
            "L'événement n'a pas pu être récupéré. Tu peux réessayer plus tard ou contacter l'administrateur du site.",
        severity: 'error',
        action: contactAction,
    },
    getEventsError: {
        message:
            "Les événements n'ont pas pu être récupérés. Tu peux réessayer plus tard ou contacter l'administrateur du site.",
        severity: 'error',
        action: contactAction,
    },
    createEventSuccess: {
        message: "L'événement a bien été ajouté.",
        severity: 'success',
    },
    createEventError: {
        message:
            "L'événement n'a pas été ajouté. Tu peux réessayer plus tard ou contacter l'administrateur du site.",
        severity: 'error',
        action: contactAction,
    },
    updateEventSuccess: {
        message: "L'événement a bien été mis à jour.",
        severity: 'success',
    },
    updateEventError: {
        message:
            "L'événement n'a pas été mis à jour. Tu peux réessayer plus tard ou contacter l'administrateur du site.",
        severity: 'error',
        action: contactAction,
    },
    deleteEventSuccess: {
        message: "L'événement a bien été supprimé.",
        severity: 'success',
    },
    deleteEventError: {
        message:
            "L'événement n'a pas été supprimé. Tu peux réessayer plus tard ou contacter l'administrateur du site.",
        severity: 'error',
        action: contactAction,
    },

    // Venues
    getVenueError: {
        message:
            "Le lieu n'a pas pu être récupéré. Tu peux réessayer plus tard ou contacter l'administrateur du site.",
        severity: 'error',
        action: contactAction,
    },
    getVenuesError: {
        message:
            "Les lieux n'ont pas pu être récupérés. Tu peux réessayer plus tard ou contacter l'administrateur du site.",
        severity: 'error',
        action: contactAction,
    },
    createVenueSuccess: {
        message: 'Le lieu a bien été ajouté.',
        severity: 'success',
    },
    createVenueError: {
        message:
            "Le lieu n'a pas été ajouté. Tu peux réessayer plus tard ou contacter l'administrateur du site.",
        severity: 'error',
        action: contactAction,
    },
    updateVenueSuccess: {
        message: 'Le lieu a bien été mis à jour.',
        severity: 'success',
    },
    updateVenueError: {
        message:
            "Le lieu n'a pas été mis à jour. Tu peux réessayer plus tard ou contacter l'administrateur du site.",
        severity: 'error',
        action: contactAction,
    },
    deleteVenueSuccess: {
        message: 'Le lieu a bien été supprimé.',
        severity: 'success',
    },
    deleteVenueError: {
        message:
            "Le lieu n'a pas été supprimé. Tu peux réessayer plus tard ou contacter l'administrateur du site.",
        severity: 'error',
        action: contactAction,
    },

    // Organizers
    getOrganizerError: {
        message:
            "L'organisateur n'a pas pu être récupéré. Tu peux réessayer plus tard ou contacter l'administrateur du site.",
        severity: 'error',
        action: contactAction,
    },
    getOrganizersError: {
        message:
            "Les organisateurs n'ont pas pu être récupérés. Tu peux réessayer plus tard ou contacter l'administrateur du site.",
        severity: 'error',
        action: contactAction,
    },
    createOrganizerSuccess: {
        message: "L'organisateur a bien été ajouté.",
        severity: 'success',
    },
    createOrganizerError: {
        message:
            "L'organisateur n'a pas été ajouté. Tu peux réessayer plus tard ou contacter l'administrateur du site.",
        severity: 'error',
        action: contactAction,
    },
    updateOrganizerSuccess: {
        message: "L'organisateur a bien été mis à jour.",
        severity: 'success',
    },
    updateOrganizerError: {
        message:
            "L'organisateur n'a pas été mis à jour. Tu peux réessayer plus tard ou contacter l'administrateur du site.",
        severity: 'error',
        action: contactAction,
    },

    // Categories and tags
    getCategoriesError: {
        message:
            "Les catégories n'ont pas pu être récupérées. Tu peux réessayer plus tard ou contacter l'administrateur du site.",
        severity: 'error',
        action: contactAction,
    },
    getTagsError: {
        message:
            "Les tags n'ont pas pu être récupérés. Tu peux réessayer plus tard ou contacter l'administrateur du site.",
        severity: 'error',
        action: contactAction,
    },

    // Authentification
    loginSuccess: {
        message: 'Bienvenue ! Tu es maintenant connecté à ton compte organisateur.',
        severity: 'success',
    },
    loginError: {
        message:
            "Erreur de connexion. Tu peux réessayer plus tard ou contacter l'administrateur du site.",
        severity: 'error',
        action: contactAction,
    },
    loginError403: {
        message: "L'identifiant et/ou le mot de passe ne sont pas valides.",
        severity: 'error',
    },
    logoutSuccess: {
        message: 'Tu es maintenant déconnecté. À bientôt.',
        severity: 'success',
    },
    resetPasswordSuccess: {
        message:
            "Le code a bien été envoyé par e-mail. S'il n'apparaît pas, pense à regarder dans les spams.",
        severity: 'success',
    },
    resetPasswordError: {
        message:
            "Erreur lors de l'envoi de l'e-mail de réinitialiation du mot de passe. Tu peux réessayer plus tard ou contacter l'administrateur du site.",
        severity: 'error',
        action: contactAction,
    },
    resetPasswordErrorBadEmail: {
        message: "Aucun utilisateur n'a été trouvé avec cette adresse e-mail.",
        severity: 'error',
    },
    setPasswordSuccess: {
        message: 'Le mot de passe a bien été modifié.',
        severity: 'success',
    },
    setPasswordError: {
        message: "Le code de confirmation n'est pas valide ou a expiré.",
        severity: 'error',
    },

    // Users
    newUserSuccess: {
        message: 'Le nouvel utilisateur a bien été créé.',
        severity: 'success',
    },
    newUserError: {
        message:
            "Le nouvel utilisateur n'a pas pu être créé. Tu peux réessayer plus tard ou contacter l'administrateur du site.",
        severity: 'error',
        action: contactAction,
    },
    newUserErrorExistingEmail: {
        message: 'Cette adresse e-mail est déjà utilisée par un utilisateur existant.',
        severity: 'error',
    },
    newUserErrorExistingLogin: {
        message: 'Ce nom est déjà utilisée par un utilisateur existant.',
        severity: 'error',
    },
    getUserError: {
        message:
            "L'utilisateur n'a pas pu être récupéré. Tu peux réessayer plus tard ou contacter l'administrateur du site.",
        severity: 'error',
        action: contactAction,
    },
    getUsersError: {
        message:
            "Les utilisateurs n'ont pas pu être récupérés. Tu peux réessayer plus tard ou contacter l'administrateur du site.",
        severity: 'error',
        action: contactAction,
    },

    // Medias
    uploadMediaError: {
        message:
            "Ton fichier n'a pas pu être envoyé. Tu peux réessayer plus tard ou contacter l'administrateur du site.",
        severity: 'error',
        action: contactAction,
    },
    updateMediaError: {
        message:
            "Ton fichier n'a pas pu être modifié. Tu peux réessayer plus tard ou contacter l'administrateur du site.",
        severity: 'error',
        action: contactAction,
    },
    deleteMediaError: {
        message:
            "Ton fichier n'a pas pu être supprimé. Tu peux réessayer plus tard ou contacter l'administrateur du site.",
        severity: 'error',
        action: contactAction,
    },
};
