import AltListItem from '../../../common/AltListItem';
import { Chip } from '@mui/material/';
import { LabelOutlined as LabelIcon } from '@mui/icons-material/';
import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    chip: {
        height: 20,
        marginRight: theme.spacing(0.5),
    },
}));

const EventTags = ({ tags = [], ...rest }) => {
    const classes = useStyles();

    return tags.length > 0 ? (
        <AltListItem
            {...rest}
            icon={<LabelIcon fontSize="small" />}
        >
            {tags.map((tag, i) => (
                <Chip
                    key={`tag-${i}`}
                    label={tag.name}
                    className={classes.chip}
                    size="small"
                />
            ))}
        </AltListItem>
    ) : null;
};

export default EventTags;
