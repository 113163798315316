import 'moment/locale/fr';

import React from 'react';
import Typography from '@mui/material/Typography';
import classnames from 'classnames';
import { makeStyles } from '@mui/styles';
import moment from 'moment';

const styles = () => ({
    allDay: {
        fontSize: '.9rem',
    },
});

const useStyles = makeStyles(styles);

function EventHeaderHour({ allDay, className = '', start }) {
    const classes = useStyles();

    return start ? (
        <Typography
            component="div"
            variant="h5"
            color="textSecondary"
            className={classnames(className, { [classes.allDay]: allDay })}
        >
            {allDay
                ? 'toute la journée'
                : `${moment(start).format('HH')}h${moment(start).format('mm')}`}
        </Typography>
    ) : null;
}

export default EventHeaderHour;
