import { LAYOUT_APPBAR_HEIGHT, LAYOUT_GRID_GUTTER_SIZE, THEME_CUSTOM } from './constants';
import { StyledEngineProvider, ThemeProvider, createTheme } from '@mui/material/styles';
import { applyMiddleware, createStore } from 'redux';

import AltAppBar from './components/AltAppBar/AltAppBarContainer';
import AltDialog from './components/common/AltDialog/AltDialogContainer';
import AltNotifications from './components/common/AltNotifications/AltNotificationsContainer';
import AppReducers from './reducers';
import AppRoutes from './AppRoutes';
import AuthProvider from './providers/AuthenticationProvider/AuthenticationProvider';
import { Box } from '@mui/material';
import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { HashRouter as Router } from 'react-router-dom';
import baseTheme from './themes/_base';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { makeStyles } from '@mui/styles';
import rootSaga from './sagas';

const sagaMiddleware = createSagaMiddleware();

let store;
// Production
/*if(process?.env?.NODE_ENV === 'production') {
  store = createStore(
    AppReducers,
    applyMiddleware(sagaMiddleware),
  );*/

// Development : store with Redux Devtools
//} else {
store = createStore(AppReducers, composeWithDevTools(applyMiddleware(sagaMiddleware)));
//}

sagaMiddleware.run(rootSaga);

let customTheme = {};
try {
    customTheme = THEME_CUSTOM ? require(`./themes/${THEME_CUSTOM}`) || {} : customTheme;
} catch (e) {
    console.log(e);
}

const theme = createTheme({ ...baseTheme, ...customTheme });

const useStyles = makeStyles(() => ({
    container: {
        marginTop: `calc(${LAYOUT_APPBAR_HEIGHT}px + ${LAYOUT_GRID_GUTTER_SIZE}px)`,
        /*[theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 3),
    },*/
    },
}));

function App() {
    const classes = useStyles();

    return (
        <ReduxProvider store={store}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <AuthProvider>
                        <Box
                            id="top"
                            className="App"
                        >
                            <Router>
                                <Box component="header">
                                    <AltAppBar />
                                </Box>
                                <Box className={classes.container}>
                                    <AppRoutes />
                                </Box>
                            </Router>
                            <AltNotifications />
                            <AltDialog />
                        </Box>
                    </AuthProvider>
                </ThemeProvider>
            </StyledEngineProvider>
        </ReduxProvider>
    );
}

export default App;
