import React, { useEffect } from 'react';
import { filterFutureEvents, filterPastEvents } from './AltAgenda.utils';
import { getEvents, resetFilters } from './../Events/EventsActions';
import { useDispatch, useSelector } from 'react-redux';

import AltAgenda from './AltAgenda';
import AltPage from '../common/AltPage';
import { filterEvents } from './../Events/Events.utils';

function AltAgendaContainer({ isPastEvents }) {
    const dispatch = useDispatch();

    const { events, filters, isFetchingEvents, isFetchingPostEvent, user } = useSelector(
        (state) => ({
            events: state.events.list,
            filters: state.events.filters,
            isFetchingEvents: state.events.isFetchingEvents,
            isFetchingPostEvent: state.events.isFetchingPostEvent,
            user: state.users.user,
        }),
    );

    useEffect(() => {
        dispatch(getEvents(user.isLogged));
    }, [dispatch, user]);

    const handleResetFilters = () => {
        dispatch(resetFilters());
    };

    const prefilteredEvents = isPastEvents
        ? filterFutureEvents(events).reverse()
        : filterPastEvents(events);
    const filteredEvents = filterEvents(prefilteredEvents, filters);

    return (
        <AltPage
            user={user}
            meta={{ metaTagsId: 'agenda' }}
        >
            <AltAgenda
                events={filteredEvents}
                isLoading={isFetchingEvents || isFetchingPostEvent}
                isFiltered={prefilteredEvents.length !== filteredEvents.length}
                handleResetFilters={handleResetFilters}
                title={isPastEvents ? 'Événements' : null}
                isPastEvents={isPastEvents}
            />
        </AltPage>
    );
}

export default AltAgendaContainer;
