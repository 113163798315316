import { getOsmByVenue } from '../../api/nominatim/osm';

// const osmSrc = "https://www.openstreetmap.org/export/embed.html?bbox=0.7342976331710815%2C45.489031527482915%2C0.7428967952728271%2C45.49231452525246&amp;layer=mapnik&amp;marker=45.49057715508256%2C0.7395386695861816";
// const osmLink = "https://www.openstreetmap.org/?mlat=45.49058&amp;mlon=0.73954#map=18/45.49058/0.73954&amp;layers=N";

export const OSM_URL = 'https://www.openstreetmap.org';
const OSM_BASE_SRC = `${OSM_URL}/export/embed.html?layer=mapnik`;
const OSM_BASE_LINK = `${OSM_URL}/?layers=N`;

export const getMapByVenue = (venue) =>
    getOsmByVenue(venue, true).then((res) => (res ? getMapByOsm(res) : null));

export const getMapByOsm = (osm) => {
    if (!osm) {
        return null;
    }
    const src = getOsmSrc(osm);
    const link = getOsmLink(osm);
    return { src, link };
};

const getOsmSrc = (osm) => {
    const position = osm.boundingbox;
    const bbox = `bbox=${position[2]}%2C${position[0]}%2C${position[3]}%2C${position[1]}`;
    const marker = `marker=${osm.lat}%2C${osm.lon}`;
    return `${OSM_BASE_SRC}&${bbox}&${marker}`;
};

const getOsmLink = (osm) => {
    const lat = `mlat=${osm.lat}`;
    const lon = `mlon=${osm.lon}`;
    const hash = `#map=18/${osm.lat}/${osm.lon}`;
    return `${OSM_BASE_LINK}&amp;${lat}&amp;${lon}&amp;${hash}`;
};
