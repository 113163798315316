import 'moment/locale/fr';

import moment from 'moment';

export function filterPastEvents(events) {
    const now = moment();
    const isAfter = (start, end) =>
        (start && moment(start).isAfter(now)) || (end && moment(end).isAfter(now));

    return events.length > 0
        ? events.filter((item) => isAfter(item.startDate, item.endDate))
        : events;
}

export function filterFutureEvents(events) {
    const now = moment();
    const isBefore = (start, end) =>
        (start && moment(start).isBefore(now)) || (end && moment(end).isBefore(now));

    return events.length > 0
        ? events.filter((item) => isBefore(item.startDate, item.endDate))
        : events;
}

/*
    Function constructTree

    return Object

    {
        201912: {
            date: "2019-12",
            days: {
                20191208: {
                    date: "2019-12-08",
                    list: [...]
                }
            }
        }
    }
*/
export function constructTreeAgenda(events) {
    const tree = {};
    events.forEach((item) => {
        const monthDate = moment(item.start).format('YYYY-MM');
        const dayDate = moment(item.start).format('YYYY-MM-DD');
        tree[monthDate] = tree[monthDate] || { date: monthDate, days: {} };
        tree[monthDate].days[dayDate] = tree[monthDate].days[dayDate] || {
            date: dayDate,
            list: [],
        };
        tree[monthDate].days[dayDate].list.push(item);
    });
    return tree;
}
