import 'moment/locale/fr';

import {
    ROUTE_PATH_AGENDA,
    ROUTE_PATH_MY_EVENTS,
    ROUTE_PATH_MY_PAST_EVENTS,
    ROUTE_PATH_PAST_AGENDA,
} from '../../AppRoutes';

import AltAgendaEvent from '../Events/Event/AltAgendaEvent/AltAgendaEvent';
import AltLink from '../common/AltLink';
import { Box } from '@mui/material';
import Loader from '../common/Loader';
import Paper from '@mui/material/Paper';
import React from 'react';
import { THEME_AGENDA_DAYS_ARE_SPACED } from '../../constants';
import Typography from '@mui/material/Typography';
import { constructTreeAgenda } from './AltAgenda.utils';
import { makeStyles } from '@mui/styles';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    monthLabel: {
        marginTop: theme.spacing(3),
        padding: theme.spacing(1.5, 2),
        backgroundColor: theme.palette.action.disabledBackground,
        lineHeight: 1,
        '&:first-child': {
            marginTop: 0,
        },
        [theme.breakpoints.up('lg')]: {
            padding: theme.spacing(1.5, 3),
        },
    },
    monthLabelCaption: {
        marginLeft: theme.spacing(1),
        fontSize: '1rem',
    },
    dayLabel: {
        padding: theme.spacing(1, 2),
        margin: THEME_AGENDA_DAYS_ARE_SPACED ? theme.spacing(3, 0, 0) : 0,
        background: theme.palette.primary.main,
        lineHeight: 1,
        color: '#fff',
        [theme.breakpoints.up('lg')]: {
            padding: theme.spacing(1.5, 3),
        },
    },
    dayLabelCaption: {
        marginLeft: theme.spacing(1),
        fontSize: '.9rem',
    },
    noEventTitle: {
        marginTop: theme.spacing(20),
    },
}));

export const PastEventsLink = ({ isMyEvents }) => (
    <AltLink
        to={isMyEvents ? ROUTE_PATH_MY_PAST_EVENTS : ROUTE_PATH_PAST_AGENDA}
        variant="body1"
    >
        Afficher les événements passés
    </AltLink>
);

export const FutureEventsLink = ({ isMyEvents }) => (
    <AltLink
        to={isMyEvents ? ROUTE_PATH_MY_EVENTS : ROUTE_PATH_AGENDA}
        variant="body1"
    >
        Afficher les événements à venir
    </AltLink>
);

function AltAgendaEvents({
    detailsDense,
    events,
    isFiltered,
    isLoading,
    isMyEvents,
    isPastEvents,
    handleResetFilters,
}) {
    const classes = useStyles();

    const tree = constructTreeAgenda(events);

    return (
        <>
            {!isLoading ? <>{renderAgenda(tree, classes, detailsDense)}</> : <Loader />}

            {!isLoading && events.length === 0 && !isFiltered && (
                <Box align="center">
                    <Typography
                        variant="h3"
                        component="h3"
                        className={classes.noEventTitle}
                        gutterBottom
                    >
                        Il n'y a aucun événement {isPastEvents ? 'passé' : 'à venir'}
                    </Typography>

                    {!isMyEvents && (
                        <Typography>
                            {isPastEvents ? (
                                <FutureEventsLink isMyEvents={isMyEvents} />
                            ) : (
                                <PastEventsLink isMyEvents={isMyEvents} />
                            )}
                        </Typography>
                    )}
                </Box>
            )}
            {!isLoading && events.length === 0 && isFiltered && (
                <Box align="center">
                    <Typography
                        variant="h3"
                        component="h3"
                        className={classes.noEventTitle}
                        gutterBottom
                    >
                        Aucun résultat à afficher pour cette recherche
                    </Typography>
                    <AltLink
                        component="p"
                        variant="body1"
                        onClick={handleResetFilters}
                    >
                        Réinitialiser les filtres
                    </AltLink>
                </Box>
            )}
        </>
    );
}

export default AltAgendaEvents;

const renderAgenda = (tree, classes, detailsDense) => {
    const month = [];
    for (const monthKey in tree) {
        const date = moment(tree[monthKey].date);
        month.push(
            <React.Fragment key={date}>
                <Paper className={classes.monthLabel}>
                    <Typography
                        variant="h4"
                        component="strong"
                        align="center"
                        display="block"
                    >
                        {moment(date).format('MMMM YYYY')}

                        {date.isSame(moment(), 'month') && (
                            <Typography
                                variant="caption"
                                component="em"
                                className={classes.monthLabelCaption}
                            >
                                - ce mois-ci
                            </Typography>
                        )}
                        {date.add(1, 'month').isSame(moment(), 'month') && (
                            <Typography
                                variant="caption"
                                component="em"
                                className={classes.monthLabelCaption}
                            >
                                - le mois prochain
                                {date.add(1, 'month')}
                            </Typography>
                        )}
                    </Typography>
                </Paper>
                {renderDays(tree[monthKey].days, classes, detailsDense)}
            </React.Fragment>,
        );
    }
    return <>{month.map((item) => item)}</>;
};

function getDayInterval(date) {
    if (date.isSame(moment(), 'day')) {
        return "aujourd'hui";
    }
    const diff = date.diff(moment(), 'days');
    if (diff < 1) {
        return 'demain';
    }
    return date.fromNow();
}

const renderDays = (days, classes, detailsDense) => {
    const daysList = [];
    for (const dayKey in days) {
        const date = moment(days[dayKey].date);
        daysList.push(
            <React.Fragment key={days[dayKey].date}>
                <Box
                    className={classes.dayLabel}
                    display="flex"
                    justifyContent="space-between"
                >
                    <Box
                        component="strong"
                        variant="h4"
                        fontWeight={500}
                    >
                        {date.format('dddd DD MMMM')}
                    </Box>
                    {date.isSame(moment(), 'month') && (
                        <Box
                            variant="caption"
                            component="em"
                            className={classes.dayLabelCaption}
                        >
                            {getDayInterval(date)}
                        </Box>
                    )}
                </Box>
                <Paper>
                    {days[dayKey].list.map((event) => (
                        <AltAgendaEvent
                            key={event.id}
                            event={event}
                            detailsDense={detailsDense}
                        />
                    ))}
                </Paper>
            </React.Fragment>,
        );
    }
    return <>{daysList.map((item) => item)}</>;
};
