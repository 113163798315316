import AltButton from '../../common/AltButton';
import AltWidget from '../../common/AltWidget';
import { Box } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import GroupIcon from '@mui/icons-material/Group';
import { Link } from 'react-router-dom';
import OrganizerContacts from '../OrganizerContacts/OrganizerContacts';
import OrganizerLogo from '../../common/OrganizerLogo';
import { ROUTE_PATH_ORGANIZER } from '../../../AppRoutes';
import React from 'react';
import Typography from '@mui/material/Typography';
import { WP_ROLES_ADMIN } from '../../../constants';
import WriteHtml from '../../common/WriteHtml';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    GroupIcon: {
        marginRight: theme.spacing(2),
    },
    organizer: {
        display: 'flex',
        verticalAlign: 'middle',
    },
    organizerLabel: {
        color: theme.palette.grey[600],
    },
    organizerLink: {
        color: theme.palette.secondary.main,
        textDecoration: 'none',
        '&:hover': {
            color: theme.palette.secondary.dark,
            textDecoration: 'underline',
        },
    },
    statut: {
        margin: theme.spacing(0, 0, 1),
    },
    boxLink: {
        '&:hover': {
            textDecoration: 'underline',
        },
    },
}));

function Organizer({ openDialogOrganizer, hideTitle, organizer, user, users }) {
    const classes = useStyles();

    return organizer ? (
        <AltWidget
            icon={
                !hideTitle ? (
                    <GroupIcon
                        color="action"
                        fontSize="small"
                    />
                ) : null
            }
            title={!hideTitle ? 'Organisateur' : ''}
        >
            <Box
                mt={0.5}
                mb={2}
            >
                <Link
                    to={`${ROUTE_PATH_ORGANIZER}/${organizer.id}`}
                    className={classes.boxLink}
                    title={`En savoir plus sur l'organisateur ${organizer.organizer}`}
                >
                    <Box
                        display="flex"
                        alignItems="center"
                        mt={1}
                    >
                        <OrganizerLogo
                            organizer={organizer}
                            users={users}
                            size={30}
                        />
                        <Typography
                            component="h3"
                            variant="h4"
                        >
                            <WriteHtml>{organizer.organizer}</WriteHtml>
                        </Typography>
                    </Box>
                </Link>
            </Box>

            {organizer.statut && (
                <Typography
                    variant="caption"
                    component="p"
                    className={classes.statut}
                >
                    {organizer.statut}
                </Typography>
            )}

            {organizer.description && (
                <Box mb={2}>
                    <Typography variant="body2">
                        <WriteHtml>{organizer.description}</WriteHtml>
                    </Typography>
                </Box>
            )}

            <OrganizerContacts
                organizer={organizer}
                boxProps={{ mb: 2 }}
            />

            {openDialogOrganizer &&
                (organizer.author === user?.id || user?.roles?.includes(WP_ROLES_ADMIN)) && (
                    <AltButton
                        variant="contrib"
                        startIcon={<EditIcon />}
                        onClick={() => openDialogOrganizer(organizer)}
                        lowerCase
                    >
                        Modifier mon profil
                    </AltButton>
                )}
        </AltWidget>
    ) : null;
}

export default Organizer;
