import { Grid, Paper, Typography, useMediaQuery } from '@mui/material';
import { LAYOUT_GRID_ASIDE_MD, LAYOUT_GRID_MAIN_MD, LAYOUT_GRID_SPACING } from '../../../constants';

import AltAgendaAside from '../../AltAgenda/AltAgendaAsideContainer';
import AltAgendaEvent from '../Event/AltAgendaEvent/AltAgendaEvent';
import Loader from '../../common/Loader';
import React from 'react';
import { makeStyles } from '@mui/styles';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    agendaContainer: {
        // remove when responsive agenda is available
        [theme.breakpoints.down('xl') && theme.breakpoints.up('md')]: {
            maxWidth: 802.5,
            margin: '0 auto',
        },
    },
    list: {
        marginTop: theme.spacing(3),
    },
}));

function DaysEvents({ allEvents, currentDate, events, isLoading }) {
    const classes = useStyles();

    // TO DO : use 'md' breakpoint when responsive agenda is available
    const isLargeOrMore = useMediaQuery((theme) => theme.breakpoints.up('lg'));

    return (
        <Grid
            container
            spacing={LAYOUT_GRID_SPACING}
        >
            <Grid
                item
                xs={12}
                lg={LAYOUT_GRID_MAIN_MD}
                className={classes.agendaContainer}
            >
                <Typography
                    variant="h2"
                    component="h2"
                    gutterBottom
                >
                    Événements du {moment(currentDate).format('dddd DD MMMM YYYY')}
                </Typography>
                {!isLoading ? (
                    <Paper className={classes.list}>
                        {events.map((event) => (
                            <AltAgendaEvent
                                key={event.id}
                                event={event}
                            />
                        ))}
                    </Paper>
                ) : (
                    <Loader />
                )}
            </Grid>
            {isLargeOrMore && (
                <Grid
                    item
                    lg={LAYOUT_GRID_ASIDE_MD}
                >
                    <AltAgendaAside />
                </Grid>
            )}
        </Grid>
    );
}

export default DaysEvents;
