import { WORDPRESS_URL, WP_API_BASE } from '../../constants';
import { addOrNotUrlPrefix, removeSlashAfterUrl } from '../../utils';

import axios from '../axiosConf';
import { getCookiesUser } from '../../providers/AuthenticationProvider/AuthenticationProvider';

export const WP_BASE_URL = addOrNotUrlPrefix(removeSlashAfterUrl(WORDPRESS_URL));

export const WP_API_BASE_URL = `${WP_BASE_URL}/${WP_API_BASE}`;

const WP_API_PATH = 'wp/v2/';
const WP_API_USERS = 'users';
const WP_API_MEDIA = 'media';

const wpApi = axios.create({
    baseURL: `${WP_API_BASE_URL}${WP_API_PATH}`,
});

wpApi.interceptors.request.use((config) => {
    if (!config.headers.Authorization) {
        const { token } = getCookiesUser();
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
    }
    return config;
});

const newUser = (user) =>
    wpApi
        .post(`${WP_API_BASE_URL}${WP_API_PATH}${WP_API_USERS}`, user)
        .then((response) => mapUser(response.data))
        .catch((err) => Promise.reject(err.response.data));

const getUser = (id) => {
    const param = id ? id : 'me';
    return wpApi
        .get(`${WP_API_BASE_URL}${WP_API_PATH}${WP_API_USERS}/${param}?context=edit`)
        .then((response) => mapUser(response.data));
};

const getUsers = () =>
    wpApi
        .get(`${WP_API_BASE_URL}${WP_API_PATH}${WP_API_USERS}?per_page=100`)
        .then((response) => response.data.map((user) => mapUser(user)));

const postUser = (user) =>
    wpApi
        .post(`${WP_API_BASE_URL}${WP_API_PATH}${WP_API_USERS}/${user.id}`, user)
        .then((response) => mapUser(response.data));

const getMedias = (userId, page = 1, perPage = 100, mediaType) => {
    const pageParam = `page=${page}`;
    const perPageParam = `&per_page=${perPage}`;
    const authorParam = userId ? `&author=${userId}` : '';
    const typeParam = mediaType ? `&media_type=${mediaType}` : '';
    return wpApi
        .get(
            `${WP_API_BASE_URL}${WP_API_PATH}${WP_API_MEDIA}?${pageParam}${perPageParam}${authorParam}${typeParam}`,
        )
        .then((response) => response.data);
};

const uploadMedia = (formData) => {
    const header = {
        headers: {
            'Content-Disposition': `attachment; filename=${formData.name}`,
            'Content-Type': formData.type,
        },
        timeout: 30000,
    };
    return wpApi
        .post(`${WP_API_BASE_URL}${WP_API_PATH}${WP_API_MEDIA}`, formData, header)
        .then((response) => response.data);
};

const updateMedia = (media) => {
    const header = {
        headers: {
            'Content-Disposition': `attachment; filename=${media.name}`,
            'Content-Type': media.type,
        },
        timeout: 30000,
    };

    wpApi
        .post(`${WP_API_BASE_URL}${WP_API_PATH}${WP_API_MEDIA}/${media.id}`, media, header)
        .then((response) => response.data);
};

const deleteMedia = (id) =>
    wpApi
        .delete(`${WP_API_BASE_URL}${WP_API_PATH}${WP_API_MEDIA}/${id}?force=true`)
        .then((response) => response.data.previous.id);

export default {
    newUser,
    getUser,
    getUsers,
    postUser,
    getMedias,
    uploadMedia,
    updateMedia,
    deleteMedia,
};

const mapUser = (user) => ({
    id: user.id.toString(),
    name: user.name,
    logo: user.description,
    roles: user.roles,
    email: user.email,
});
