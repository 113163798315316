import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AltEventsMap from './AltEventsMap';
import AltPage from '../common/AltPage';
import { filterEvents } from '../Events/Events.utils';
import { filterRemovePastEvents } from './utils';
import { getEvents } from '../Events/EventsActions';

function AltEventsMapContainer() {
    const dispatch = useDispatch();

    const { events, filters, isFetchingEvents, user } = useSelector((state) => ({
        events: filterRemovePastEvents(state.events.list),
        filters: state.events.filters,
        isFetchingEvents: state.events.isFetchingEvents,
        user: state.users.user,
    }));

    useEffect(() => {
        dispatch(getEvents(user.isLogged));
    }, [dispatch, user]);

    const filteredEvents = filterEvents(events, filters);

    return (
        <AltPage
            user={user}
            meta={{ metaTagsId: 'map' }}
            fullWidth
        >
            <AltEventsMap
                events={filteredEvents}
                isFetchingEvents={isFetchingEvents}
            />
        </AltPage>
    );
}

export default AltEventsMapContainer;
