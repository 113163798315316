import { useDispatch, useSelector } from 'react-redux';

import AltDialog from './AltDialog';
import React from 'react';
import { closeDialog } from './AltDialogActions';

function AltDialogContainer() {
    const { dialogs } = useSelector((state) => ({
        dialogs: state.dialogs.list,
    }));

    const dispatch = useDispatch();

    const handleClose = (id) => {
        dispatch(closeDialog(id));
    };

    return (
        <AltDialog
            dialogs={dialogs}
            handleClose={handleClose}
        />
    );
}

export default AltDialogContainer;
