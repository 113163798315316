import { WP_API_BASE_URL } from './wp';
import applyConverters from 'axios-case-converter';
import axios from '../axiosConf';

export const WP_BDPWR_PATH = 'bdpwr/v1/';
const WP_BDPWR_RESET_PASSWORD = 'reset-password';
const WP_BDPWR_SET_PASSWORD = 'set-password';

const WpBdpwrAPI = applyConverters(
    axios.create({
        baseURL: `${WP_API_BASE_URL}${WP_BDPWR_PATH}`,
    }),
);

const resetPassword = (email) =>
    WpBdpwrAPI.post(WP_BDPWR_RESET_PASSWORD, {
        email,
    })
        .then((response) => response.data)
        .catch((err) => Promise.reject(err.response.data));

const setPassword = (data) =>
    WpBdpwrAPI.post(WP_BDPWR_SET_PASSWORD, data)
        .then((response) => response.data)
        .catch((err) => Promise.reject(err.response.data));

export default {
    resetPassword,
    setPassword,
};
