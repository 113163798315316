import AltMetaTags from './AltMetaTags';
import { KeyboardArrowUp as ArrowUpIcon } from '@mui/icons-material/';
import Container from '@mui/material/Container';
import { Fab } from '@mui/material/';
import React from 'react';
import ScrollTop from './ScrollTop';
import { THEME_CONTAINER_MAX_WIDTH_KEY } from '../../constants';
import UserAlertSubscribe from './UserAlertSubscribe';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    container: {
        paddingBottom: theme.spacing(6),
    },
}));

function AltPage({ children, fullWidth, meta, user }) {
    const classes = useStyles();

    return (
        <>
            <AltMetaTags {...meta} />

            <Container
                maxWidth={fullWidth ? THEME_CONTAINER_MAX_WIDTH_KEY : false}
                fixed={!fullWidth}
                className={!fullWidth ? classes.container : ''}
            >
                {!user.isLogged && <UserAlertSubscribe />}
                {children}
            </Container>

            <ScrollTop>
                <Fab
                    color="secondary"
                    size="small"
                    aria-label="Remonter en haut de la page"
                >
                    <ArrowUpIcon />
                </Fab>
            </ScrollTop>
        </>
    );
}

export default AltPage;
