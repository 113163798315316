import { green, grey } from '@mui/material/colors';

import { ArrowDropDown as ArrowDropDownIcon } from '@mui/icons-material';
import Loader from './Loader';
import React from 'react';
import { Select } from '@mui/material';
import { withStyles } from '@mui/styles';

const ValidationSelect = withStyles({
    root: {
        '&:valid ~ fieldset, &:valid:focus ~ fieldset, &:valid:hover ~ fieldset': {
            borderColor: green[600],
        },
        '&.Mui-disabled': {
            cursor: 'wait',
        },
        '&.Mui-disabled ~ fieldset': {
            backgroundColor: grey[100],
            opacity: 0.5,
        },
        '&.Mui-disabled:hover ~ fieldset': {
            borderColor: 'rgba(0, 0, 0, 0.23)',
        },
    },
})(({ isFetching, ...rest }) => (
    <Select
        native
        IconComponent={
            isFetching
                ? () => (
                      <Loader
                          size="xxs"
                          margin="none"
                          isInField
                      />
                  )
                : ArrowDropDownIcon
        }
        {...rest}
    />
));

function AltSelect(props) {
    return <ValidationSelect {...props} />;
}

export default AltSelect;
