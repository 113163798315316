import { AttachMoney as AttachMoneyIcon, MoneyOff as MoneyOffIcon } from '@mui/icons-material/';
import { isEmpty, isNumber, isUndefined } from 'lodash';

import AltListItem from '../../../common/AltListItem';
import React from 'react';
import { getCostLabel } from '../../Events.utils';

const EventCost = ({ cost, costDetails = {}, ...rest }) => {
    const value = costDetails?.values?.[0];
    const isNumberValue = isNumber(parseInt(value, 10)) && !isNaN(value);

    if (isUndefined(value) || isEmpty(value)) {
        return null;
    }

    return (
        <AltListItem
            {...rest}
            icon={
                isNumberValue ? (
                    <AttachMoneyIcon fontSize="small" />
                ) : (
                    <MoneyOffIcon fontSize="small" />
                )
            }
        >
            {isNumberValue ? (
                getCostLabel(value, costDetails.currencySymbol, costDetails.currencyPosition)
            ) : (
                <span dangerouslySetInnerHTML={{ __html: cost }} />
            )}
        </AltListItem>
    );
};

export default EventCost;
