import {
    LOGIN,
    LOGIN_ERROR,
    LOGIN_SUCCESS,
    LOGOUT,
    RESET_PASSWORD,
    RESET_PASSWORD_ERROR,
    RESET_PASSWORD_SUCCESS,
    RESET_TOKEN,
    SET_PASSWORD,
    SET_PASSWORD_ERROR,
    SET_PASSWORD_SUCCESS,
    SET_TOKEN,
} from './AuthenticationProviderActions';

const initialState = {
    errors: [],
    isFetching: false,
    isFetchingResetPassword: false,
    isSent: false,
    id: null,
    token: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOGIN:
            return {
                ...initialState,
                isFetching: true,
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                isFetching: false,
                id: action.user.id,
                token: action.user.token,
            };
        case LOGIN_ERROR:
            return {
                ...state,
                errors: [...state.errors, action.err],
                isFetching: false,
            };
        case SET_TOKEN:
            return {
                ...state,
                token: action.auth.token,
                id: action.auth.id,
            };
        case RESET_TOKEN:
            return initialState;
        case LOGOUT:
            return initialState;
        case RESET_PASSWORD:
            return {
                ...state,
                isFetchingResetPassword: true,
            };
        case RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                isFetchingResetPassword: false,
                isSent: true,
            };
        case RESET_PASSWORD_ERROR:
            return {
                ...state,
                isFetchingResetPassword: false,
            };
        case SET_PASSWORD:
            return {
                ...state,
                isFetchingSetPassword: true,
            };
        case SET_PASSWORD_SUCCESS:
            return {
                ...state,
                isFetchingSetPassword: false,
                isSent: false,
            };
        case SET_PASSWORD_ERROR:
            return {
                ...state,
                isFetchingSetPassword: false,
                isSent: false,
            };
        default:
            return state;
    }
};
