import {
    DELETE_MEDIA,
    DELETE_MEDIA_ERROR,
    DELETE_MEDIA_SUCCESS,
    GET_MEDIAS,
    GET_MEDIAS_ERROR,
    GET_MEDIAS_SUCCESS,
    GET_MORE_MEDIAS,
    GET_MORE_MEDIAS_ERROR,
    GET_MORE_MEDIAS_SUCCESS,
    UPDATE_MEDIA,
    UPDATE_MEDIA_ERROR,
    UPDATE_MEDIA_SUCCESS,
    UPLOAD_IMAGE,
    UPLOAD_IMAGE_ERROR,
    UPLOAD_IMAGE_SUCCESS,
} from './MediasActions';

const initialState = {
    errors: [],
    isFetchingMedias: false,
    list: [],
    isFetchingUploadImage: false,
    isFetchingMoreMedias: false,
    isFetchingUpdateMedia: false,
    isFetchingDeleteMedia: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_MEDIAS:
            return {
                ...state,
                isFetchingMedias: true,
                list: initialState.list,
            };
        case GET_MEDIAS_SUCCESS:
            return {
                ...state,
                isFetchingMedias: false,
                list: action.medias,
            };
        case GET_MEDIAS_ERROR:
            return {
                ...state,
                isFetchingMedias: false,
                errors: [...state.errors, action.err],
            };
        case GET_MORE_MEDIAS:
            return {
                ...state,
                isFetchingMoreMedias: true,
            };
        case GET_MORE_MEDIAS_SUCCESS:
            return {
                ...state,
                isFetchingMoreMedias: false,
                list: [...state.list, ...action.medias],
            };
        case GET_MORE_MEDIAS_ERROR:
            return {
                ...state,
                isFetchingMoreMedias: false,
                errors: [...state.errors, action.err],
            };
        case UPLOAD_IMAGE:
            return {
                ...state,
                isFetchingUploadImage: true,
            };
        case UPLOAD_IMAGE_SUCCESS:
            return {
                ...state,
                isFetchingUploadImage: false,
                list: [action.file, ...state.list],
            };
        case UPLOAD_IMAGE_ERROR:
            return {
                ...state,
                isFetchingUploadImage: false,
            };
        case UPDATE_MEDIA:
            return {
                ...state,
                isFetchingUpdateMedia: true,
            };
        case UPDATE_MEDIA_SUCCESS:
            const orderedList = [
                action.media,
                ...state.list.filter((media) => media.id !== action.media.id),
            ].sort((a, b) => (a.date < b.date ? 1 : -1));

            return {
                ...state,
                isFetchingUpdateMedia: false,
                list: [...orderedList],
            };
        case UPDATE_MEDIA_ERROR:
            return {
                ...state,
                isFetchingUpdateMedia: false,
            };
        case DELETE_MEDIA:
            return {
                ...state,
                isFetchingDeleteMedia: true,
            };
        case DELETE_MEDIA_SUCCESS:
            return {
                ...state,
                isFetchingDeleteMedia: false,
                list: [...state.list.filter((media) => media.id !== action.id)],
            };
        case DELETE_MEDIA_ERROR:
            return {
                ...state,
                isFetchingDeleteMedia: false,
            };
        default:
            return state;
    }
};
