export const OPEN_DIALOG = 'OPEN_DIALOG';
export const CLOSE_DIALOG = 'CLOSE_DIALOG';

export const openDialog = (data) => ({
    type: OPEN_DIALOG,
    data,
});

export const closeDialog = (id) => ({
    type: CLOSE_DIALOG,
    id,
});
