import { ADD_NOTIFICATION, DELETE_NOTIFICATION } from './AltNotificationsActions';

const initialState = {
    id: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ADD_NOTIFICATION:
            return {
                ...state,
                id: action.id,
            };
        case DELETE_NOTIFICATION:
            return initialState;
        default:
            return state;
    }
};
