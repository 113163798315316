export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const SET_TOKEN = 'SET_TOKEN';
export const RESET_TOKEN = 'RESET_TOKEN';
export const SET_USER = 'SET_USER';
export const LOGOUT = 'LOGOUT';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const SET_PASSWORD = 'SET_PASSWORD';
export const SET_PASSWORD_SUCCESS = 'SET_PASSWORD_SUCCESS';
export const SET_PASSWORD_ERROR = 'SET_PASSWORD_ERROR';

export const login = (username, password) => ({
    type: LOGIN,
    username,
    password,
});

export const loginSuccess = (user) => ({
    type: LOGIN_SUCCESS,
    user,
});

export const loginError = (err) => ({
    type: LOGIN_ERROR,
    err,
});

export const logout = () => ({
    type: LOGOUT,
});

export const setToken = (auth) => ({
    type: SET_TOKEN,
    auth,
});

export const resetToken = () => ({
    type: RESET_TOKEN,
});

export const resetPassword = (email) => ({
    type: RESET_PASSWORD,
    email,
});

export const resetPasswordSuccess = (data) => ({
    type: RESET_PASSWORD_SUCCESS,
    data,
});

export const resetPasswordError = (err) => ({
    type: RESET_PASSWORD_ERROR,
    err,
});

export const setPassword = (data) => ({
    type: SET_PASSWORD,
    data,
});

export const setPasswordSuccess = (data) => ({
    type: SET_PASSWORD_SUCCESS,
    data,
});

export const setPasswordError = (err) => ({
    type: SET_PASSWORD_ERROR,
    err,
});
