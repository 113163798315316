import { AltAccordion, AltAccordionDetails, AltAccordionSummary } from '../../common/AltAccordion';
import { Box, Card, CardContent, Grid, Paper, Typography, useMediaQuery } from '@mui/material';
import { ROUTE_PATH_EVENT, ROUTE_PATH_ORGANIZER, ROUTE_PATH_VENUE } from '../../../AppRoutes';
import React, { useEffect, useState } from 'react';

import AltLink from '../../common/AltLink';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import Loader from '../../common/Loader';
import OrganizerContacts from '../OrganizerContacts/OrganizerContacts';
import OrganizerLogo from '../../common/OrganizerLogo';
import WriteHtml from '../../common/WriteHtml';
import { decodeHtmlEntities } from '../../../utils';
import { makeStyles } from '@mui/styles';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    eventsList: {
        padding: 0,
        margin: 0,
        maxWidth: '100%',
    },

    eventItem: {
        height: 24,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },

    boxLink: {
        '&:hover': {
            textDecoration: 'underline',
        },
    },
}));

function OrganizersList({
    events,
    organizers,
    venues,
    isFetchingEvents,
    isFetchingVenues,
    isLoading,
    users,
}) {
    const classes = useStyles();
    const isMediumOrMore = useMediaQuery((theme) => theme.breakpoints.up('md'));

    const [expanded, setExpanded] = useState({});

    useEffect(() => {
        if (!expanded[0] && organizers.length > 0) {
            const initialState = organizers
                .map(() => 'panel1')
                .reduce(
                    (obj, item, index) => ({
                        ...obj,
                        [index]: item,
                    }),
                    {},
                );
            setExpanded(initialState);
        }
    }, [expanded, organizers, setExpanded]);

    const handleChange = (index, panel) => (_event, _isExpanded) => {
        setExpanded({
            ...expanded,
            [index]: expanded[index] !== panel ? panel : expanded[index],
        });
    };

    return (
        <>
            <Typography
                variant="h2"
                className={classes.title}
                gutterBottom
            >
                Organisateurs
            </Typography>

            {!isLoading ? (
                <Grid
                    container
                    spacing={3}
                >
                    {organizers.map((organizer, index) => {
                        const organizedEvents = events
                            .filter((ev) => ev.organizer[0].id === organizer.id)
                            .sort((a, b) => a.startDate > b.startDate);
                        const hasEvents = organizedEvents.length > 0;

                        const addedVenues = venues.filter((ven) => ven.author === organizer.author);
                        const hasVenues = addedVenues.length > 0;

                        return (
                            <Grid
                                item
                                xs={12}
                                md={6}
                                key={organizer.id}
                            >
                                <Paper>
                                    <Card
                                        className={classes.cardWithDivider}
                                        elevation={0}
                                        square
                                        key={organizer.id}
                                    >
                                        <CardContent>
                                            <Link
                                                to={`${ROUTE_PATH_ORGANIZER}/${organizer.id}`}
                                                className={classes.boxLink}
                                                title={`En savoir plus sur l'organisateur ${organizer.organizer}`}
                                            >
                                                <Box
                                                    display="flex"
                                                    alignItems="flex-end"
                                                    mb={2}
                                                >
                                                    <OrganizerLogo
                                                        organizer={organizer}
                                                        users={users}
                                                    />
                                                    <Typography
                                                        component="h2"
                                                        variant="h3"
                                                        color="secondary"
                                                        className={classes.name}
                                                        gutterBottom
                                                        noWrap
                                                    >
                                                        <WriteHtml>{organizer.organizer}</WriteHtml>
                                                    </Typography>
                                                </Box>
                                            </Link>

                                            <OrganizerContacts
                                                organizer={organizer}
                                                boxProps={{ mb: 2 }}
                                            />

                                            <Box mb={3}>
                                                <AltAccordion
                                                    square
                                                    expanded={expanded[index] === 'panel1'}
                                                    onChange={handleChange(index, 'panel1')}
                                                >
                                                    <AltAccordionSummary
                                                        aria-controls="panel1a-content-1"
                                                        id="panel1a-header-1"
                                                    >
                                                        Description
                                                    </AltAccordionSummary>
                                                    <AltAccordionDetails>
                                                        <Typography
                                                            component="div"
                                                            variant="body2"
                                                        >
                                                            <WriteHtml component="div">
                                                                {organizer.description}
                                                            </WriteHtml>
                                                        </Typography>
                                                    </AltAccordionDetails>
                                                </AltAccordion>

                                                <AltAccordion
                                                    square
                                                    expanded={expanded[index] === 'panel2'}
                                                    onChange={handleChange(index, 'panel2')}
                                                    disabled={!hasVenues}
                                                >
                                                    <AltAccordionSummary
                                                        aria-controls="panel1a-content-2"
                                                        id="panel1a-header-2"
                                                        expandIcon={
                                                            isFetchingVenues ? (
                                                                <Loader
                                                                    size="xxs"
                                                                    margin="none"
                                                                />
                                                            ) : (
                                                                <ExpandMoreIcon />
                                                            )
                                                        }
                                                    >
                                                        Lieux ajoutés ({addedVenues.length})
                                                    </AltAccordionSummary>
                                                    <AltAccordionDetails>
                                                        <ul className={classes.eventsList}>
                                                            {hasVenues ? (
                                                                addedVenues.map((venue) => (
                                                                    <li
                                                                        key={venue.id}
                                                                        className={
                                                                            classes.eventItem
                                                                        }
                                                                    >
                                                                        <AltLink
                                                                            to={`${ROUTE_PATH_VENUE}/${venue.id}`}
                                                                            title={`En savoir plus sur le lieu ${venue.venue}`}
                                                                            variant="body1"
                                                                        >
                                                                            {`${decodeHtmlEntities(
                                                                                venue.venue,
                                                                            )}${
                                                                                venue.city
                                                                                    ? ` (${venue.city})`
                                                                                    : ''
                                                                            }`}
                                                                        </AltLink>
                                                                    </li>
                                                                ))
                                                            ) : (
                                                                <li>Aucun lieu ajouté</li>
                                                            )}
                                                        </ul>
                                                    </AltAccordionDetails>
                                                </AltAccordion>

                                                <AltAccordion
                                                    square
                                                    expanded={expanded[index] === 'panel3'}
                                                    onChange={handleChange(index, 'panel3')}
                                                    disabled={!hasEvents}
                                                >
                                                    <AltAccordionSummary
                                                        aria-controls="panel1a-content-3"
                                                        id="panel1a-header-3"
                                                        expandIcon={
                                                            isFetchingEvents ? (
                                                                <Loader
                                                                    size="xxs"
                                                                    margin="none"
                                                                />
                                                            ) : (
                                                                <ExpandMoreIcon />
                                                            )
                                                        }
                                                    >
                                                        Prochains événements{' '}
                                                        {isMediumOrMore && <>organisés </>}(
                                                        {organizedEvents.length})
                                                    </AltAccordionSummary>
                                                    <AltAccordionDetails>
                                                        <ul className={classes.eventsList}>
                                                            {hasEvents ? (
                                                                organizedEvents.map((ev) => (
                                                                    <li
                                                                        key={ev.id}
                                                                        className={
                                                                            classes.eventItem
                                                                        }
                                                                    >
                                                                        <AltLink
                                                                            to={`${ROUTE_PATH_EVENT}/${ev.id}`}
                                                                            title={`En savoir plus sur l'événement ${ev.name}`}
                                                                            variant="body1"
                                                                        >
                                                                            {`${moment(
                                                                                ev.startDate,
                                                                            ).format(
                                                                                'DD/MM/YY',
                                                                            )} - ${ev.name}`}
                                                                        </AltLink>
                                                                    </li>
                                                                ))
                                                            ) : (
                                                                <li>Aucun événement prévu</li>
                                                            )}
                                                        </ul>
                                                    </AltAccordionDetails>
                                                </AltAccordion>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Paper>
                            </Grid>
                        );
                    })}
                </Grid>
            ) : (
                <Loader />
            )}
        </>
    );
}

export default OrganizersList;
