import { Box, Card, CardContent } from '@mui/material/';

import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {},
    icon: {
        marginRight: theme.spacing(2),
    },
}));

function AltWidget(props) {
    const classes = useStyles();
    const { children, icon, title } = props;

    return children ? (
        <Card className={classes.root}>
            <CardContent>
                <AltWidgetHeader icon={icon}>{title}</AltWidgetHeader>

                {children}
            </CardContent>
        </Card>
    ) : null;
}

function AltWidgetHeader(props) {
    const classes = useStyles();
    const { children, icon } = props;

    return children ? (
        <Box display="flex">
            {icon && <Box className={classes.icon}>{React.cloneElement(icon, {})}</Box>}
            <Box
                component="span"
                variant="body1"
                color="text.secondary"
                fontWeight={500}
            >
                {children}
            </Box>
        </Box>
    ) : null;
}

export default AltWidget;
