import {
    DELETE_VENUE,
    DELETE_VENUE_ERROR,
    DELETE_VENUE_SUCCESS,
    GET_VENUE,
    GET_VENUES,
    GET_VENUES_ERROR,
    GET_VENUES_SUCCESS,
    GET_VENUE_ERROR,
    GET_VENUE_SUCCESS,
    POST_VENUE,
    POST_VENUE_ERROR,
    POST_VENUE_SUCCESS,
} from './VenuesActions';

const initialState = {
    errors: [],
    isFetchingVenue: false,
    isFetchingVenues: false,
    list: [],
    venue: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_VENUE:
            return {
                ...state,
                isFetchingVenue: true,
            };
        case GET_VENUE_SUCCESS:
            return {
                ...state,
                isFetchingVenue: false,
                venue: action.venue,
            };
        case GET_VENUE_ERROR:
            return {
                ...state,
                isFetchingVenue: false,
                errors: [...state.errors, action.err],
            };
        case GET_VENUES:
            return {
                ...state,
                isFetchingVenues: true,
            };
        case GET_VENUES_SUCCESS:
            return {
                ...state,
                isFetchingVenues: false,
                list: action.venues,
            };
        case GET_VENUES_ERROR:
            return {
                ...state,
                isFetchingVenues: false,
                errors: [...state.errors, action.err],
            };
        case POST_VENUE:
            return {
                ...state,
                isFetchingPostVenue: true,
            };
        case POST_VENUE_SUCCESS:
            return {
                ...state,
                isFetchingPostVenue: false,
                list: [...state.list.filter((venue) => venue.id !== action.venue.id), action.venue],
            };
        case POST_VENUE_ERROR:
            return {
                ...state,
                isFetchingPostVenue: false,
                errors: [...state.errors, action.err],
            };
        case DELETE_VENUE:
            return {
                ...state,
                isFetchingDeleteVenue: true,
            };
        case DELETE_VENUE_SUCCESS:
            return {
                ...state,
                list: state.list.filter((venue) => venue.id !== action.venue.id),
                isFetchingDeleteVenue: false,
            };
        case DELETE_VENUE_ERROR:
            return {
                ...state,
                errors: [...state.errors, action.err],
                isFetchingDeleteVenue: false,
            };
        default:
            return state;
    }
};
