import React, { useEffect } from 'react';
import { closeDialog, openDialog } from '../../common/AltDialog/AltDialogActions';
import { confirmDeleteVenueDialog, editVenueDialog } from '../../common/AltDialog/DialogsData';
import { deleteVenue, getVenues } from '../VenuesActions';
import { useDispatch, useSelector } from 'react-redux';

import AltConfirmationDialog from '../../common/AltConfirmationDialog';
import AltPage from '../../common/AltPage';
import VenueForm from '../VenueForm/VenueForm';
import VenuesList from '../VenuesList/VenuesList';
import { decodeUnicodeEntities } from '../../../utils';
import { filterPastEvents } from '../../AltAgenda/AltAgenda.utils';
import { getEvents } from '../../Events/EventsActions';
import { getOrganizers } from '../../Organizers/OrganizersActions';

function VenuesListContainer() {
    const dispatch = useDispatch();

    const {
        events,
        isFetchingEvents,
        isFetchingOrganizers,
        isFetchingVenues,
        isFetchingPostVenues,
        organizers,
        user,
        venues,
    } = useSelector((state) => ({
        events: state.events.list,
        organizers: state.organizers.list,
        venues: state.venues.list,
        isFetchingEvents: state.events.isFetchingEvents,
        isFetchingOrganizers: state.organizers.isFetchingOrganizers,
        isFetchingVenues: state.venues.isFetchingVenues,
        isFetchingPostVenues: state.venues.isFetchingPostVenues,
        user: state.users.user,
    }));

    useEffect(() => {
        dispatch(getVenues(user.isLogged));
        dispatch(getOrganizers());
        dispatch(getEvents(user.isLogged));
    }, [dispatch, user]);

    const openDialogPostVenue = (venue) => {
        dispatch(
            openDialog({
                ...editVenueDialog,
                component: <VenueForm venue={venue} />,
            }),
        );
    };

    const handleClickDelete = (venue) => {
        dispatch(
            openDialog({
                ...confirmDeleteVenueDialog,
                component: (
                    <AltConfirmationDialog
                        name={decodeUnicodeEntities(venue.venue)}
                        onConfirm={() => dispatch(deleteVenue(venue.id))}
                        onClose={() => dispatch(closeDialog('confirmDeleteVenue'))}
                    />
                ),
            }),
        );
    };

    const compare = (a, b) => {
        // Use toUpperCase() to ignore character casing
        const valueA = a.venue.toUpperCase();
        const valueB = b.venue.toUpperCase();

        let comparison = 0;
        if (valueA > valueB) {
            comparison = 1;
        } else if (valueA < valueB) {
            comparison = -1;
        }
        return comparison;
    };

    const futureEvents = filterPastEvents(events);

    const orderedVenues = venues.sort(compare);

    const isLoading = isFetchingVenues || isFetchingPostVenues;

    return (
        <AltPage
            user={user}
            meta={{ metaTagsId: 'venues' }}
        >
            <VenuesList
                title="Lieux"
                venues={orderedVenues}
                organizers={organizers}
                events={futureEvents}
                isLoading={isLoading}
                isFetchingEvents={isFetchingEvents || isFetchingOrganizers}
                openDialogPostVenue={openDialogPostVenue}
                handleClickDelete={handleClickDelete}
                user={user}
                fullWidth
                displayEmpty={true}
                displayEmptyContacts={true}
            />
        </AltPage>
    );
}

export default VenuesListContainer;
