import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import cookie from 'react-cookies';
import { setToken } from './AuthenticationProviderActions';
import { setUser } from '../../components/User/UserActions';

const NAME = 'NAME';
const EMAIL = 'EMAIL';
const TOKEN = 'TOKEN';
const ID = 'ID';
const LOGO = 'LOGO';
const ROLES = 'ROLES';

export const getCookiesUser = () => ({
    name: cookie.load(NAME),
    email: cookie.load(EMAIL),
    token: cookie.load(TOKEN),
    id: cookie.load(ID),
    logo: cookie.load(LOGO),
    roles: cookie.load(ROLES),
});

export const setCookiesUser = (user) => {
    cookie.save(EMAIL, user.email, {
        path: '/',
    });
    cookie.save(NAME, user.name, {
        path: '/',
    });
    cookie.save(ID, user.id, {
        path: '/',
    });
    cookie.save(ROLES, user.roles, {
        path: '/',
    });
    if (user.logo) {
        cookie.save(LOGO, user.logo, {
            path: '/',
        });
    }
};

export const setCookieToken = (token) => {
    cookie.save(TOKEN, token, {
        path: '/',
    });
};

export const updateCookiesUser = (user) => {
    cookie.save(EMAIL, user.email, {
        path: '/',
    });
    cookie.save(NAME, user.name, {
        path: '/',
    });
    if (user.logo) {
        cookie.save(LOGO, user.logo, {
            path: '/',
        });
    }
};

export const removeCookiesUser = () => {
    cookie.remove(TOKEN, {
        path: '/',
    });
    cookie.remove(EMAIL, {
        path: '/',
    });
    cookie.remove(NAME, {
        path: '/',
    });
    cookie.remove(ID, {
        path: '/',
    });
    cookie.remove(LOGO, {
        path: '/',
    });
    cookie.remove(ROLES, {
        path: '/',
    });
};

function AuthenticationProvider({ children }) {
    const dispatch = useDispatch();

    const { token, user } = useSelector((state) => ({
        token: state.auth.token,
        user: state.users.user,
    }));

    const cookieUser = getCookiesUser();

    useEffect(() => {
        if (cookieUser.token && !token) {
            dispatch(setUser(cookieUser));
            dispatch(setToken({ id: cookieUser.id, token: cookieUser.token }));
        } else if (!cookieUser.token && token) {
            setCookieToken(token);
        }
        if (user.id && !cookieUser.id) {
            setCookiesUser(user);
        }
    }, [cookieUser, dispatch, token, user]);

    return React.cloneElement(children, {});
}

export default AuthenticationProvider;
