import React, { useEffect } from 'react';
import { filterFutureEvents, filterPastEvents } from '../../AltAgenda/AltAgenda.utils';
import { getEvents, resetFilters } from '../EventsActions';
import { useDispatch, useSelector } from 'react-redux';

import AltAgenda from '../../AltAgenda/AltAgenda';
import AltPage from '../../common/AltPage';
import { WP_ROLES_ADMIN } from '../../../constants';
import { filterEvents } from '../Events.utils';

function MyEventsContainer({ isPastEvents }) {
    const dispatch = useDispatch();

    const { events, filters, isFetchingEvents, isFetchingPostEvent, user } = useSelector(
        (state) => ({
            events: state.events.list,
            filters: state.events.filters,
            isFetchingEvents: state.events.isFetchingEvents,
            isFetchingPostEvent: state.events.isFetchingPostEvent,
            user: state.users.user,
        }),
    );

    useEffect(() => {
        dispatch(getEvents(user.isLogged));
    }, [dispatch, user]);

    const handleResetFilters = () => {
        dispatch(resetFilters());
    };

    const myEvents = !user.roles?.includes(WP_ROLES_ADMIN)
        ? events.filter((ev) => ev.author === user.id)
        : events;

    const futureOrPastEvents = isPastEvents
        ? filterFutureEvents(myEvents).reverse()
        : filterPastEvents(myEvents);

    const filteredEvents = filterEvents(futureOrPastEvents, filters);

    return (
        <AltPage
            user={user}
            meta={{ metaTagsId: 'myEvents' }}
        >
            <AltAgenda
                title="Mes événements"
                events={filteredEvents}
                handleResetFilters={handleResetFilters}
                isLoading={isFetchingEvents || isFetchingPostEvent}
                isPastEvents={isPastEvents}
                isFiltered={futureOrPastEvents.length !== filteredEvents.length}
                isMyEvents
            />
        </AltPage>
    );
}

export default MyEventsContainer;
