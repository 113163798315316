import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    Divider,
    FormLabel,
    Typography,
    capitalize,
} from '@mui/material';
import React, { useEffect, useReducer } from 'react';
import {
    addOrNotUrlPrefix,
    decodeUnicodeEntities,
    isEmail,
    isPhoneNumber,
    isUrl,
    removeHtmlTags,
} from '../../../utils';
import { getOrganizers, postOrganizer } from '../OrganizersActions';
import { useDispatch, useSelector } from 'react-redux';

import AltButton from '../../common/AltButton';
import AltLink from '../../common/AltLink';
import AltTextField from '../../common/AltTextField';
import Loader from '../../common/Loader';
import MediaForm from '../../common/MediaForm';
import { makeStyles } from '@mui/styles';
import { mediasDialog } from '../../common/AltDialog/DialogsData';
import { openDialog } from '../../common/AltDialog/AltDialogActions';

const useStyles = makeStyles((theme) => ({
    loaderContainer: {
        padding: theme.spacing(2, 0),
        textAlign: 'center',
    },

    divider: {
        margin: theme.spacing(2, 0, 3),
    },

    image: {
        width: 150,
        height: 150,
        border: '1px solid',
        borderColor: theme.palette.grey[200],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
}));

const defaultValuesOrganizer = {
    status: 'publish',
};

const initialState = {
    errors: {
        // undefined = empty optional, null = empty required, false = valid
        // mandatories fields :
        title: null,
        description: null,
        email: null,
    },
};

const reducer = (state, action) => {
    let value, error;
    switch (action.type) {
        case 'all':
            return {
                ...state,
                ...action.value,
            };
        case 'title':
            value = action.value;
            error = !(value?.length > 0);
            return {
                ...state,
                title: value,
                errors: {
                    ...state.errors,
                    title: error,
                },
            };
        case 'description':
            value = action.value;
            error = !(value?.length > 0);
            return {
                ...state,
                description: value,
                errors: {
                    ...state.errors,
                    description: error,
                },
            };
        case 'userEmail':
            value = action.value;
            error = !(value?.length > 0 && isEmail(value));
            return {
                ...state,
                userEmail: value,
                errors: {
                    ...state.errors,
                    userEmail: error,
                },
            };
        case 'logo':
            value = action.value;
            return {
                ...state,
                logo: value,
                errors: {
                    ...state.errors,
                    logo: false,
                },
            };
        case 'email':
            value = action.value;
            error = value?.length > 0 && !isEmail(value);
            return {
                ...state,
                email: value,
                errors: {
                    ...state.errors,
                    email: error,
                },
            };
        case 'phone':
            value = action.value;
            error = value?.length > 0 && !isPhoneNumber(value);
            return {
                ...state,
                phone: value,
                errors: {
                    ...state.errors,
                    phone: error,
                },
            };
        case 'website':
            value = action.value;
            error = value?.length > 0 && !isUrl(value);
            return {
                ...state,
                website: value,
                errors: {
                    ...state.errors,
                    website: error,
                },
            };
        default:
            throw new Error('Unexpected action');
    }
};

function OrganizerForm({ callback, handleClose, user }) {
    const classes = useStyles();

    const { isFetchingOrganizers, isFetchingPostOrganizer, organizers } = useSelector((state) => ({
        organizers: state.organizers.list,
        isFetchingOrganizers: state.organizers.isFetchingOrganizers,
        isFetchingPostOrganizer: state.organizers.isFetchingPostOrganizer,
    }));

    const dispatch = useDispatch();
    useEffect(() => {
        if (organizers.length < 1 && !isFetchingOrganizers) {
            dispatch(getOrganizers());
        }
    });

    const [form, setForm] = useReducer(reducer, initialState);

    // Init organizer fields
    const organizer = organizers?.filter((org) => org.author === user?.id)?.[0];
    if (user && !isFetchingOrganizers && !form.isInit) {
        const initValues = organizer
            ? {
                  description: decodeUnicodeEntities(removeHtmlTags(organizer.description)),
                  email: organizer.email,
                  phone: organizer.phone,
                  website: organizer.website,
                  logo: user.logo,
                  errors: {
                      description: organizer.description ? false : null,
                  },
              }
            : {
                  errors: {
                      description: null,
                  },
              };

        setForm({
            type: 'all',
            value: {
                ...initValues,
                title: decodeUnicodeEntities(user.name),
                userEmail: user.email,
                logo: user.logo,
                errors: {
                    ...initValues.errors,
                    title: false,
                    userEmail: false,
                },
                isInit: true,
            },
        });
    }

    const handleChangeValue = (type, e) => setForm({ type, value: e.currentTarget.value });

    const setSelectedImageUrl = (url) => {
        setForm({ type: 'logo', value: url });
    };

    const openMediaDialog = () => {
        dispatch(
            openDialog({
                ...mediasDialog,
                title: 'Choisir un logo',
                component: (
                    <MediaForm
                        setSelectedImageUrl={setSelectedImageUrl}
                        isThumb
                    />
                ),
            }),
        );
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const initValuesUser = user?.id ? { id: user.id } : {};
        const name = capitalize(title);
        const userPayload = {
            ...initValuesUser,
            name, // public name
            email: userEmail,
            firstName: '', // override wordpress form values (not used in this app)
            lastName: name,
            description: logo || null, // use description field for avatar instead gravatar
        };

        const initValuesOrganizer = organizer?.id ? { id: organizer.id } : defaultValuesOrganizer;
        dispatch(
            postOrganizer(
                {
                    user: userPayload,
                    organizer: {
                        ...initValuesOrganizer,
                        organizer: name,
                        description: `<div>${description}</div>`,
                        email,
                        phone,
                        website: website?.length > 0 ? addOrNotUrlPrefix(website) : undefined,
                    },
                },
                callback,
            ),
        );
    };

    const { title, description, userEmail, logo, email, phone, website, errors, isInit } = form;

    const errorsValues = Object.values(errors);
    const isValid = errorsValues.every((val) => val === false);
    const hasEmptyMandatoriesFields = errorsValues.some((val) => val === null);

    return isInit ? (
        <form
            onSubmit={handleSubmit}
            noValidate
            autoComplete="off"
        >
            <DialogContent dividers>
                <AltTextField
                    label="Nom de l'organisation"
                    value={title || ''}
                    onChange={(e) => handleChangeValue('title', e)}
                    required
                    error={errors.title}
                    autoFocus
                />

                <AltTextField
                    label="Adresse e-mail privée"
                    value={userEmail || ''}
                    onChange={(e) => handleChangeValue('userEmail', e)}
                    type="email"
                    required
                    error={errors.userEmail}
                    helperText={
                        errors.userEmail
                            ? "L'adresse e-mail n'est pas au bon format."
                            : 'Cette adresse est utilisée pour te connecter et ne sera pas affichée sur le site. Un e-mail de confirmation sera envoyé en cas de modification.'
                    }
                />

                <AltTextField
                    multiline
                    label="Description, objet social..."
                    rows={10}
                    value={description || ''}
                    onChange={(e) => handleChangeValue('description', e)}
                    required
                    error={errors.description}
                />

                <Box mt={1}>
                    <FormLabel>Logo</FormLabel>
                    {logo && (
                        <Box
                            className={classes.image}
                            style={{
                                backgroundImage: `url(${logo})`,
                            }}
                            mt={3}
                        ></Box>
                    )}
                    <Box mt={2}>
                        {logo && (
                            <AltLink
                                onClick={() => setSelectedImageUrl(null)}
                                component="p"
                                variant="body1"
                            >
                                Supprimer
                            </AltLink>
                        )}
                        <AltLink
                            onClick={openMediaDialog}
                            component="p"
                            variant="body1"
                        >
                            {logo ? 'Choisir une autre image' : 'Choisir une image'}
                        </AltLink>
                    </Box>
                </Box>

                <Divider className={classes.divider} />

                <Typography variant="h5">Coordonnées</Typography>

                <AltTextField
                    label="Adresse e-mail publique"
                    value={email || ''}
                    onChange={(e) => handleChangeValue('email', e)}
                    type="email"
                    error={errors.email}
                    helperText={
                        errors.email
                            ? "L'adresse e-mail n'est pas au bon format."
                            : "Addresse publique affichée sur le site et utilisée pour contacter ton organisation. Elle peut être identique à l'adresse privée."
                    }
                />

                <AltTextField
                    label="Téléphone"
                    value={phone || ''}
                    onChange={(e) => handleChangeValue('phone', e)}
                    helperText={
                        errors.phone
                            ? "Le numéro de téléphone n'est pas au bon format."
                            : 'Laisse ce champ vide si tu ne veux pas que le numéro apparaisse sur le site.'
                    }
                    error={errors.phone}
                    type="tel"
                    inputProps={{ minLength: 3 }}
                />

                <AltTextField
                    label="Site web de l'organisateur"
                    value={website || ''}
                    onChange={(e) => handleChangeValue('website', e)}
                    helperText={
                        errors.website ? "L'adresse doit être au format 'mondomaine.fr'." : null
                    }
                    error={errors.website}
                />
            </DialogContent>

            <DialogActions>
                {hasEmptyMandatoriesFields && (
                    <Typography variant="subtitle2">
                        Tous les champs obligatoires (*) doivent être remplis
                    </Typography>
                )}
                {!isValid && !hasEmptyMandatoriesFields && (
                    <Typography
                        color="error"
                        variant="subtitle2"
                    >
                        Merci de corriger les erreurs avant de valider
                    </Typography>
                )}
                {organizer && (
                    <Button
                        variant="contained"
                        onClick={handleClose}
                        className={classes.cancelButton}
                    >
                        Annuler
                    </Button>
                )}
                <AltButton
                    type="submit"
                    variant="contrib"
                    onClick={handleSubmit}
                    disabled={!isValid}
                    endIcon={
                        isFetchingPostOrganizer ? (
                            <Loader
                                margin="none"
                                size="xxs"
                                color="white"
                            />
                        ) : null
                    }
                >
                    Valider
                </AltButton>
            </DialogActions>
        </form>
    ) : (
        <Loader />
    );
}

export default OrganizerForm;
