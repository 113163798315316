import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AltPage from '../../common/AltPage';
import Loader from '../../common/Loader';
import Venue from './Venue';
import VenueForm from '../VenueForm/VenueForm';
import { editVenueDialog } from '../../common/AltDialog/DialogsData';
import { filterPastEvents } from '../../AltAgenda/AltAgenda.utils';
import { getEvents } from '../../Events/EventsActions';
import { getOrganizers } from '../../Organizers/OrganizersActions';
import { getVenue } from '../VenuesActions';
import { openDialog } from '../../common/AltDialog/AltDialogActions';
import { removeHtmlTags } from '../../../utils';
import { useParams } from 'react-router-dom';

function VenueContainer() {
    const params = useParams();
    const dispatch = useDispatch();

    const { events, isFetchingEvents, isFetchingVenue, organizers, user, venue } = useSelector(
        (state) => ({
            events: state.events.list,
            organizers: state.organizers.list,
            venue: state.venues.venue,
            isFetchingEvents: state.events.isFetchingEvents,
            isFetchingVenue: state.venues.isFetchingVenue,
            user: state.users.user,
        }),
    );

    const venueId = parseInt(params.id, 10);

    useEffect(() => {
        dispatch(getVenue(venueId, user.isLogged));
        dispatch(getOrganizers());
        dispatch(getEvents(user.isLogged));
    }, [dispatch, venueId, user]);

    const openDialogPostVenue = () => {
        dispatch(
            openDialog({
                ...editVenueDialog,
                component: <VenueForm venue={venue} />,
                callback: () => dispatch(getVenue(venueId, user.isLogged)),
            }),
        );
    };

    const venueEvents = venue ? events.filter((event) => event.venue.id === venue.id) : [];
    const futureVenueEvents = filterPastEvents(venueEvents);

    const venueOrganizer =
        organizers && venue ? organizers.find((org) => org.author === venue.author) : null;

    return (
        <AltPage
            user={user}
            meta={
                venue
                    ? { title: venue.venue, description: removeHtmlTags(venue.description) }
                    : { metaTagsId: 'default' }
            }
        >
            {!isFetchingVenue ? (
                <Venue
                    events={futureVenueEvents}
                    organizer={venueOrganizer}
                    venue={venue}
                    isLoading={isFetchingVenue}
                    isFetchingEvents={isFetchingEvents}
                    openDialogPostVenue={openDialogPostVenue}
                    user={user}
                />
            ) : (
                <Loader />
            )}
        </AltPage>
    );
}

export default VenueContainer;
