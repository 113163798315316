import auth from './providers/AuthenticationProvider/AuthenticationProviderReducers';
import categories from './components/Categories/CategoriesReducer';
import { combineReducers } from 'redux';
import dialogs from './components/common/AltDialog/AltDialogReducer';
import events from './components/Events/EventsReducers';
import medias from './components/Medias/MediasReducer';
import notifications from './components/common/AltNotifications/AltNotificationsReducer';
import organizers from './components/Organizers/OrganizersReducers';
import tags from './components/Tags/TagsReducer';
import users from './components/User/UserReducer';
import venues from './components/Venues/VenuesReducers';

export default combineReducers({
    auth,
    categories,
    dialogs,
    events,
    medias,
    notifications,
    organizers,
    tags,
    users,
    venues,
});
