import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AltPage from '../../common/AltPage';
import OrganizersList from './OrganizersList';
import { getEvents } from '../../Events/EventsActions';
import { getOrganizers } from '../OrganizersActions';
import { getUsers } from '../../User/UserActions';
import { getVenues } from '../../Venues/VenuesActions';

function OrganizersListContainer() {
    const dispatch = useDispatch();

    const {
        events,
        isFetchingEvents,
        isFetchingOrganizers,
        isFetchingVenues,
        organizers,
        user,
        users,
        venues,
    } = useSelector((state) => ({
        events: state.events.list,
        organizers: state.organizers.list,
        venues: state.venues.list,
        isFetchingEvents: state.events.isFetchingEvents,
        isFetchingOrganizers: state.organizers.isFetchingOrganizers,
        isFetchingVenues: state.venues.isFetchingVenues,
        user: state.users.user,
        users: state.users.list,
    }));

    useEffect(() => {
        dispatch(getVenues(user.isLogged));
        dispatch(getOrganizers());
        dispatch(getEvents(user.isLogged));
        dispatch(getUsers());
    }, [dispatch, user]);

    const compare = (a, b) => {
        // Use toUpperCase() to ignore character casing
        const valueA = a.organizer.toUpperCase();
        const valueB = b.organizer.toUpperCase();

        let comparison = 0;
        if (valueA > valueB) {
            comparison = 1;
        } else if (valueA < valueB) {
            comparison = -1;
        }
        return comparison;
    };

    const orderedOrganizers = organizers.sort(compare);

    return (
        <AltPage
            user={user}
            meta={{ metaTagsId: 'organizers' }}
        >
            <OrganizersList
                organizers={orderedOrganizers}
                events={events}
                venues={venues}
                isLoading={isFetchingOrganizers}
                isFetchingEvents={isFetchingEvents}
                isFetchingVenues={isFetchingVenues}
                users={users}
            />
        </AltPage>
    );
}

export default OrganizersListContainer;
