import { Box, Typography } from '@mui/material/';
import { Edit as EditIcon, Place as PlaceIcon } from '@mui/icons-material/';

import AltButton from '../../common/AltButton';
import AltLink from '../../common/AltLink';
import AltWidget from '../../common/AltWidget';
import { ROUTE_PATH_VENUE } from '../../../AppRoutes';
import React from 'react';
import VenueAddress from '../VenueAddress/VenueAddress';
import VenueContacts from '../VenueContacts/VenueContacts';
import VenueMap from '../VenueMap';
import { WP_ROLES_ADMIN } from '../../../constants';
import WriteHtml from '../../common/WriteHtml';
import { decodeHtmlEntities } from '../../../utils';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    venueIcon: {
        marginRight: theme.spacing(2),
    },
    venue: {
        display: 'flex',
        verticalAlign: 'middle',
    },
    venueContent: {
        width: '100%',
    },
    venueLabel: {
        color: theme.palette.grey[600],
    },
    address: {
        marginBottom: theme.spacing(2),
    },
    contactsList: {
        paddingTop: 0,
    },
    listItem: {
        padding: theme.spacing(0.5, 0),
    },
    description: {
        marginBottom: theme.spacing(2),
    },
}));

function VenueWidget({ openDialogPostVenue, venue, user }) {
    const classes = useStyles();

    if (!venue) {
        return null;
    }

    return (
        <AltWidget
            icon={
                <PlaceIcon
                    color="action"
                    fontSize="small"
                />
            }
            title={'Lieu'}
        >
            <Box mb={2}>
                {venue.isPrivate && !user.isLogged ? (
                    <Typography
                        component="h3"
                        variant="h4"
                    >
                        <WriteHtml>{venue.venue}</WriteHtml>
                    </Typography>
                ) : (
                    <AltLink
                        to={`${ROUTE_PATH_VENUE}/${venue.id}`}
                        title={`En savoir plus sur le lieu ${venue.venue}`}
                        component="h3"
                        variant="h4"
                    >
                        {decodeHtmlEntities(venue.venue)}
                    </AltLink>
                )}
            </Box>

            <VenueAddress
                venue={venue}
                boxProps={{ mb: 2 }}
            />

            <VenueMap osm={venue.osm} />

            {venue.description && (
                <Box mb={2}>
                    <Typography
                        variant="body2"
                        component="div"
                        className={classes.description}
                    >
                        <WriteHtml
                            component="div"
                            className={classes.descriptionHtml}
                        >
                            {venue.description}
                        </WriteHtml>
                    </Typography>
                </Box>
            )}

            <VenueContacts
                venue={venue}
                boxProps={{ mb: 2 }}
            />

            {openDialogPostVenue &&
                (venue.author === user?.id || user?.roles?.includes(WP_ROLES_ADMIN)) && (
                    <AltButton
                        variant="contrib"
                        startIcon={<EditIcon />}
                        onClick={() => openDialogPostVenue(venue)}
                        lowerCase
                    >
                        Modifier ce lieu
                    </AltButton>
                )}
        </AltWidget>
    );
}

export default VenueWidget;
