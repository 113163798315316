import { APP_LOGO_SRC, APP_NAME, APP_SLOGAN } from '../../constants';

import { Box } from '@mui/material/';
import { Link } from 'react-router-dom';
import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    logo: {
        margin: theme.spacing(0.5, 0),
        marginRight: theme.spacing(2),
    },
}));

const AltAppBarBrand = () => {
    const classes = useStyles();
    return (
        <Box
            display="flex"
            flexGrow="1"
        >
            <Link
                to="/"
                className={classes.logoLink}
                title="Aller à la page d'accueil de l'agenda alternatif du Périgord vert"
            >
                <Box
                    display="flex"
                    alignItems="center"
                >
                    <img
                        src={APP_LOGO_SRC}
                        alt={APP_NAME}
                        className={classes.logo}
                        width="84px"
                        height="28px"
                    />
                    <Box
                        component="h1"
                        color="secondary.main"
                        fontSize="fontSize"
                        fontWeight="fontWeightMedium"
                        flexGrow={1}
                        letterSpacing=".8px"
                    >
                        {APP_SLOGAN}
                    </Box>
                </Box>
            </Link>
        </Box>
    );
};

export default AltAppBarBrand;
