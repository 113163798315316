import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    element: {
        '& > p:first-child': {
            marginTop: 0,
        },
    },
}));

function WriteHtml(props) {
    const classes = useStyles();
    const { children, component } = props;
    const element = component || 'span';
    return children ? (
        <React.Fragment>
            {element === 'span' && (
                <span
                    className={classes.element}
                    dangerouslySetInnerHTML={{ __html: children }}
                />
            )}
            {element === 'div' && (
                <div
                    className={classes.element}
                    dangerouslySetInnerHTML={{ __html: children }}
                />
            )}
        </React.Fragment>
    ) : null;
}

export default WriteHtml;
