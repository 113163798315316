import applyConverters from 'axios-case-converter';
import axios from 'axios';

applyConverters(axios);

axios.defaults.timeout = 15000;

/* axios.interceptors.response.use(
  response => response,
  error => {
    const { status } = error.response || {};

    if (status === 401) {
      logout();
    }

    return Promise.reject(error);
  }
); */

/* NOT WORKING
export const setAuthHeader = token =>
  axios.defaults.headers.common.Authorization = token
    ? `Bearer ${token}`
    : null;
*/

export default axios;
