import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import classnames from 'classnames';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    loader: {
        display: 'block',
        margin: 'auto',
        color: theme.palette.action.disabled,
        // height: 'auto !important',
    },
    none: {
        marginTop: 0,
        marginBottom: 0,
    },
    dense: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
    },
    normal: {
        marginTop: theme.spacing(15),
        marginBottom: theme.spacing(15),
    },
    white: {
        color: '#ffffff',
    },
    field: {
        position: 'absolute',
        right: 14,
        zIndex: 10,
    },
}));

const sizes = {
    xxs: 20,
    xs: 24,
    sm: 30,
    md: 40,
    lg: 50,
};

function Loader({ color, margin, size = 'md', isInField }) {
    const classes = useStyles();
    return (
        <CircularProgress
            className={classnames(
                classes.loader,
                { [`${classes[margin]}`]: !!margin },
                { [classes.normal]: !!!margin },
                { [`${classes[color]}`]: !!color },
                { [classes.field]: isInField },
            )}
            color="inherit"
            size={sizes[size]}
        />
    );
}

export default Loader;
