import {
    EVENT_IMAGE_RATIO,
    LAYOUT_CONTAINER_WIDTH,
    LAYOUT_GRID_GUTTER_SIZE,
    LAYOUT_GRID_MAIN_MD,
    LAYOUT_GRID_NB_COLUMNS,
    LAYOUT_SPACING,
} from '../../../../constants';

const mainColumnRatio = LAYOUT_GRID_MAIN_MD / LAYOUT_GRID_NB_COLUMNS; // Cf App.js

const imageWidth = 190; // content height (approximately)

const containerInnerWidth = LAYOUT_CONTAINER_WIDTH - LAYOUT_GRID_GUTTER_SIZE * 2; // 1052px
const columnWidth = (containerInnerWidth - LAYOUT_GRID_GUTTER_SIZE) * mainColumnRatio; // 775.5px
const detailsWidth = columnWidth - imageWidth - LAYOUT_GRID_GUTTER_SIZE; // 567.5px

const columnWidthDense =
    (containerInnerWidth - LAYOUT_GRID_GUTTER_SIZE) * (7 / LAYOUT_GRID_NB_COLUMNS) -
    2 * LAYOUT_GRID_GUTTER_SIZE -
    4 * LAYOUT_SPACING;
const detailsWidthDense = columnWidthDense - imageWidth - LAYOUT_GRID_GUTTER_SIZE;

export default (theme) => ({
    details: {
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: detailsWidth,
            padding: theme.spacing(1, 0),
        },
    },
    detailsDense: {
        [theme.breakpoints.up('md')]: {
            width: detailsWidthDense,
        },
    },
    content: {
        padding: theme.spacing(0),
        '&:last-child': {
            paddingBottom: 0,
        },

        [theme.breakpoints.up('md')]: {
            padding: 0,
            paddingRight: theme.spacing(3),
            '&:last-child': {
                paddingBottom: 0,
            },
        },
    },
    hour: {
        marginBottom: 4,
        [theme.breakpoints.up('md')]: {
            width: 55,
            marginTop: 2, // fix baseline alignment with event title
            marginBottom: 0,
        },
    },
    description: {
        marginLeft: theme.spacing(0),
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(3),
        },
    },
    image: {
        minHeight: imageWidth * EVENT_IMAGE_RATIO,
        width: imageWidth,
        border: '1px solid',
        borderColor: theme.palette.action.hover,
    },
    boxLink: {
        display: 'flex',
        textDecoration: 'none',
        color: theme.palette.text.primary,
        alignItems: 'center',
        '&:hover': {
            textDecoration: 'none',
            backgroundColor: theme.palette.action.hover,
        },
    },
    cardWithDivider: {
        borderBottom: '1px solid #eee',
        '&:last-child': {
            borderBottom: 0,
        },
        '& > a': {
            padding: theme.spacing(1),
            margin: theme.spacing(1),

            [theme.breakpoints.up('md')]: {
                padding: theme.spacing(1.5),
                margin: theme.spacing(1.5),
            },
        },
    },
});
