import AltLink from '../common/AltLink';
import { Paper } from '@mui/material';
import { ROUTE_PATH_MAP } from '../../AppRoutes';
import React from 'react';
import WidgetCalendar from '../Widgets/WidgetCalendar/WidgetCalendar';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    widgetCalendar: {
        padding: theme.spacing(0),
        marginBottom: theme.spacing(3),
        backgroundColor: 'rgba(255, 255, 255, .5)',
    },
    mapImage: {
        border: '1px solid',
        borderColor: theme.palette.action.active,
        marginLeft: -1,
        transition: 'all 150ms ease-out',
        '&:hover': {
            borderColor: theme.palette.secondary.light,
            transition: 'all 250ms ease-in',
        },
    },
}));

function AltAgendaAside({ events, isLoading }) {
    const classes = useStyles();

    return (
        <>
            <Paper className={classes.widgetCalendar}>
                <WidgetCalendar
                    events={events}
                    isLoading={isLoading}
                />
            </Paper>
            <AltLink
                component="div"
                to={ROUTE_PATH_MAP}
                title="Visualiser les événements sur une carte de la Dordogne"
            >
                <img
                    className={classes.mapImage}
                    src="canalpv-carte-evenements-dordogne-250-370.jpg"
                    alt="Carte des événements associatifs de Dordogne"
                    width="250px"
                    height="370px"
                />
            </AltLink>
        </>
    );
}

export default AltAgendaAside;
