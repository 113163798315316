export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';

export const addNotification = (id) => ({
    type: ADD_NOTIFICATION,
    id,
});

export const deleteNotification = (id) => ({
    type: DELETE_NOTIFICATION,
    id,
});
