import 'moment/locale/fr';

import React from 'react';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import moment from 'moment';

const styles = (theme) => ({
    monthLabel: {
        fontSize: '.9rem',
        textTransform: 'uppercase',
    },
    start: {
        // border: `1px solid ${theme.palette.primary.main}`,
        // borderRadius: "0.2em",
        lineHeight: 1.3,
        textAlign: 'center',
    },
    /*startMonth: {
    fontSize: '.8rem',
    textTransform: 'uppercase',
  },*/
    startDay: {
        fontSize: '1.5rem',
        fontWeight: 500,
        color: theme.palette.primary.main,
    },
});

const useStyles = makeStyles(styles);

function EventHeaderDate(props) {
    const classes = useStyles();
    const { start } = props;

    return start ? (
        <Typography className={classes.start}>
            <span className={classes.monthLabel}>{moment(start).format('MMM')}</span>
            <br />
            <span className={classes.startDay}>{moment(start).format('DD')}</span>
            <br />
            {/* <span className={classes.startDayLabel}>{moment(start).format("ddd")}</span><br /> */}
            {/* <span className={classes.startDay}>{moment(start).format("HH")}h</span><br /> */}
            {/*<span className={classes.startMonth}>{moment(start).format("MMM")}</span>*/}
        </Typography>
    ) : null;
}

export default EventHeaderDate;
