import AltListItem from '../../../common/AltListItem';
import GroupIcon from '@mui/icons-material/Group';
import React from 'react';
import { Typography } from '@mui/material/';
import WriteHtml from '../../../common/WriteHtml';

const EventOrganizer = ({ noWrap, organizer, showLabel, ...rest }) =>
    organizer ? (
        <AltListItem
            {...rest}
            icon={<GroupIcon fontSize="small" />}
        >
            <Typography
                variant="body2"
                noWrap={noWrap}
            >
                {showLabel && (
                    <Typography
                        component="span"
                        variant="body2"
                        color="textSecondary"
                    >
                        organisé par&nbsp;
                    </Typography>
                )}
                <WriteHtml>{organizer.organizer}</WriteHtml>
            </Typography>
        </AltListItem>
    ) : null;

export default EventOrganizer;
