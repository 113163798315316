import 'moment/locale/fr';
import 'leaflet/dist/leaflet.css';
import './AltEventsMap.css';

import { Box, useMediaQuery } from '@mui/material/';
import { LAYOUT_APPBAR_HEIGHT, LAYOUT_GRID_GUTTER_SIZE } from '../../constants';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';

import AltLink from '../common/AltLink';
import EventInfos from '../Events/EventInfos/EventInfos';
import EventsFilters from '../Events/EventsFilters/EventsFiltersContainer';
import L from 'leaflet';
import Loader from '../common/Loader';
import { ROUTE_PATH_EVENT } from '../../AppRoutes';
import React from 'react';
import classnames from 'classnames';
import { decodeHtmlEntities } from '../../utils';
import { makeStyles } from '@mui/styles';
import moment from 'moment';

const MARGIN = LAYOUT_GRID_GUTTER_SIZE;
const NAVBAR_HEIGHT = LAYOUT_APPBAR_HEIGHT + MARGIN;
const FILTERS_HEIGHT = 64 + MARGIN;
const TOTAL = NAVBAR_HEIGHT + FILTERS_HEIGHT + MARGIN;
const mapHeight = `calc(100vh - ${TOTAL}px)`;

const useStyles = makeStyles((theme) => ({
    filtersContainer: {
        marginBottom: theme.spacing(3),
    },
    map: {
        overflow: 'hidden',
        width: '100%',
        height: mapHeight,
        opacity: 0.4,
    },
    loadedMap: {
        opacity: 1,
    },
    loader: {
        position: 'absolute',
        top: 'calc(50% - 20px)',
        left: 'calc(50% - 20px)',
        zIndex: 500, // leaflet-pane z-index is 400
    },
    popupImage: {
        maxWidth: '100%',
    },
    date: {
        margin: 0,
    },
    error: {
        marginTop: 100,
    },
    box: {
        padding: theme.spacing(3),
    },
}));

const MAP_POPUP_MAXWIDTH = {
    xs: 300,
    md: 450,
    lg: 606,
};

const icon = {
    html: '',
    iconSize: 'auto',
    className: 'leaflet-div-icon-custom',
    iconAnchor: [21, 30],
};

const addMarker = (event, classes, popupMaxWidth) => {
    // no marker when osm is not find (no place in osmPlaces.json)
    if (!event.venue.osm) {
        return null;
    }

    const {
        id,
        // name,
        start,
        venue,
        venue: {
            osm: { lat, lon },
        },
    } = event;
    const label = moment(start).format('DD/MM');
    const venuesList = {};

    const isAfter = venuesList[venue.id] && start > venuesList[venue.id];
    venuesList[venue.id] = !isAfter ? start : venuesList[venue.id];

    return !isAfter && moment(event.start).isAfter(moment()) ? (
        <Marker
            key={`marker-event-${id}`}
            position={{ lat, lon, lng: lon }}
            icon={new L.DivIcon({ ...icon, html: label })}
        >
            <Popup maxWidth={popupMaxWidth}>
                <Box className={classes.box}>
                    <AltLink
                        to={`${ROUTE_PATH_EVENT}/${event.id}`}
                        component="h2"
                        variant="h2"
                    >
                        {decodeHtmlEntities(event.name)}
                    </AltLink>
                    <EventInfos
                        event={event}
                        hideCategories
                        dense
                    />
                </Box>
            </Popup>
        </Marker>
    ) : null;
};

function AltEventsMap({ events, isFetchingEvents }) {
    const classes = useStyles();

    const state = {
        lat: 45.5,
        lng: 0.72,
        zoom: 9.6,
    };

    const initialPosition = [state.lat, state.lng];

    let popupMaxWidth = MAP_POPUP_MAXWIDTH['xs'];
    popupMaxWidth = useMediaQuery((theme) => theme.breakpoints.up('md'))
        ? MAP_POPUP_MAXWIDTH['md']
        : popupMaxWidth;
    popupMaxWidth = useMediaQuery((theme) => theme.breakpoints.up('lg'))
        ? MAP_POPUP_MAXWIDTH['lg']
        : popupMaxWidth;

    return (
        <>
            <Box className={classes.filtersContainer}>
                <EventsFilters fullWidth />
            </Box>

            <MapContainer
                center={initialPosition}
                zoom={state.zoom}
                className={classnames(classes.map, { [classes.loadedMap]: !isFetchingEvents })}
            >
                <TileLayer
                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {events.map((event) => {
                    const { osm } = event.venue;

                    return osm?.lat && osm?.lon ? addMarker(event, classes, popupMaxWidth) : null;
                })}
            </MapContainer>
            {isFetchingEvents && (
                <div className={classes.loader}>
                    <Loader margin="none" />
                </div>
            )}
        </>
    );
}

export default AltEventsMap;
