import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';

import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import React from 'react';
import { withStyles } from '@mui/styles';

export const AltAccordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .1)',
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
            '& .MuiIconButton-label': {
                display: 'none',
            },
        },
        '&.Mui-disabled': {
            backgroundColor: 'rgba(0, 0, 0, .05)',
            '& .MuiIconButton-label': {
                display: 'none',
            },
        },
    },
    expanded: {},
})(Accordion);

export const AltAccordionSummary = withStyles((theme) => ({
    root: {
        backgroundColor: 'rgba(0, 0, 0, .02)',
        borderBottom: '1px solid rgba(0, 0, 0, .1)',
        marginBottom: -1,
        minHeight: 0,
        '&$expanded': {
            minHeight: 0,
        },
    },
    content: {
        margin: theme.spacing(1.5, 0),
        '&$expanded': {
            margin: theme.spacing(1.5, 0),
        },
    },
    expanded: {},
}))((props) => (
    <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        {...props}
    >
        <Typography>{props.children}</Typography>
    </AccordionSummary>
));

export const AltAccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        height: 120,
        overflow: 'auto',
    },
}))(AccordionDetails);
