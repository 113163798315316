import {
    GET_ORGANIZER,
    GET_ORGANIZERS,
    GET_ORGANIZERS_ERROR,
    GET_ORGANIZERS_SUCCESS,
    GET_ORGANIZER_ERROR,
    GET_ORGANIZER_SUCCESS,
    POST_ORGANIZER,
    POST_ORGANIZER_ERROR,
    POST_ORGANIZER_SUCCESS,
} from './OrganizersActions';

const initialState = {
    errors: [],
    isFetchingOrganizer: false,
    isFetchingOrganizers: false,
    isFetchingPostOrganizer: false,
    organizer: null,
    list: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_ORGANIZER:
            return {
                ...state,
                isFetchingOrganizer: true,
                organizer: null,
            };
        case GET_ORGANIZER_SUCCESS:
            return {
                ...state,
                isFetchingOrganizer: false,
                organizer: action.organizer,
            };
        case GET_ORGANIZER_ERROR:
            return {
                ...state,
                isFetchingOrganizer: false,
                errors: [...state.errors, action.err],
            };
        case GET_ORGANIZERS:
            return {
                ...state,
                isFetchingOrganizers: true,
                list: initialState.list,
            };
        case GET_ORGANIZERS_SUCCESS:
            return {
                ...state,
                isFetchingOrganizers: false,
                list: action.organizers,
            };
        case GET_ORGANIZERS_ERROR:
            return {
                ...state,
                isFetchingOrganizers: false,
                errors: [...state.errors, action.err],
            };
        case POST_ORGANIZER:
            return {
                ...state,
                isFetchingPostOrganizer: true,
            };
        case POST_ORGANIZER_SUCCESS:
            return {
                ...state,
                isFetchingPostOrganizer: false,
                list: [
                    ...state.list.filter((organizer) => organizer.id !== action.organizer.id),
                    action.organizer,
                ],
            };
        case POST_ORGANIZER_ERROR:
            return {
                ...state,
                isFetchingPostOrganizer: false,
                errors: [...state.errors, action.err],
            };
        default:
            return state;
    }
};
