import { Button, DialogActions, DialogContent, DialogContentText } from '@mui/material';

import AltButton from './AltButton';
import React from 'react';

function AltConfirmationDialog({ name, onCancel, onClose, onConfirm }) {
    const handleCancel = () => {
        if (onCancel) {
            onCancel();
        }
        onClose();
    };

    const handleConfirm = () => {
        if (onConfirm) {
            onConfirm();
        }
        onClose();
    };

    return (
        <>
            <DialogContent>
                <DialogContentText>{name}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    onClick={handleCancel}
                >
                    Annuler
                </Button>
                <AltButton
                    variant="contrib"
                    onClick={handleConfirm}
                    autoFocus
                >
                    Confirmer
                </AltButton>
            </DialogActions>
        </>
    );
}

export default AltConfirmationDialog;
