export const GET_TAGS = 'GET_TAGS';
export const GET_TAGS_SUCCESS = 'GET_TAGS_SUCCESS';
export const GET_TAGS_ERROR = 'GET_TAGS_ERROR';

export const getTags = () => ({
    type: GET_TAGS,
});

export const getTagsSuccess = (tags) => ({
    type: GET_TAGS_SUCCESS,
    tags,
});

export const getTagsError = (err) => ({
    type: GET_TAGS_ERROR,
    err,
});
