import { Box, Typography } from '@mui/material';

import AltListItem from '../../../common/AltListItem';
import { Place as PlaceIcon } from '@mui/icons-material';
import React from 'react';
import WriteHtml from '../../../common/WriteHtml';

const EventVenue = ({ venue = {}, onlyAddress, ...rest }) =>
    venue.venue ? (
        <AltListItem
            {...rest}
            icon={<PlaceIcon fontSize="small" />}
        >
            <Box lineHeight={1}>
                <Typography variant="body2">
                    <WriteHtml>{venue.venue}</WriteHtml>
                </Typography>

                <Typography variant="caption">
                    {!onlyAddress && (
                        <Box>
                            <WriteHtml>{venue.address}</WriteHtml>
                            {venue.address ? ', ' : ''}
                            {venue.zip}
                            {venue.zip ? ' ' : ''}
                            {venue.city}
                        </Box>
                    )}
                    {onlyAddress && <WriteHtml variant="caption">{venue.address}</WriteHtml>}
                </Typography>
            </Box>
        </AltListItem>
    ) : null;

export default EventVenue;
