import React, { useEffect } from 'react';
import { resetFilters, updateFilters } from '../EventsActions';
import { useDispatch, useSelector } from 'react-redux';

import EventsFilters from './EventsFilters';
import { getCategories } from '../../Categories/CategoriesActions';
import { getOrganizers } from '../../Organizers/OrganizersActions';
import { getTags } from '../../Tags/TagsActions';
import { getVenues } from '../../Venues/VenuesActions';

function EventsFiltersContainer({ fullWidth, hideOrganizers }) {
    const dispatch = useDispatch();

    const {
        categories,
        filters,
        isFetchingCategories,
        isFetchingOrganizers,
        isFetchingTags,
        isFetchingVenues,
        organizers,
        tags,
        venues,
        isLogged,
    } = useSelector((state) => ({
        categories: state.categories.list,
        filters: state.events.filters,
        isFetchingCategories: state.categories.isFetchingCategories,
        isFetchingOrganizers: state.organizers.isFetchingOrganizers,
        isFetchingTags: state.tags.isFetchingTags,
        isFetchingVenues: state.venues.isFetchingVenues,
        organizers: state.organizers.list,
        tags: state.tags.list,
        venues: state.venues.list,
        isLogged: state.users.user.isLogged,
    }));

    useEffect(() => {
        dispatch(getCategories());
        dispatch(getTags());
        dispatch(getOrganizers());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getVenues(isLogged));
    }, [dispatch, isLogged]);

    const handleChange = (event, key) => {
        const filter = {
            [key]: event.target.value,
        };
        dispatch(updateFilters(filter));
    };

    const handleResetFilters = () => {
        dispatch(resetFilters());
    };

    return (
        <EventsFilters
            handleChange={handleChange}
            filters={filters}
            categories={categories}
            organizers={organizers}
            tags={tags}
            venues={venues}
            handleResetFilters={handleResetFilters}
            isFetchingCategories={isFetchingCategories}
            isFetchingOrganizers={isFetchingOrganizers}
            isFetchingTags={isFetchingTags}
            isFetchingVenues={isFetchingVenues}
            fullWidth={!!fullWidth}
            hideOrganizers={hideOrganizers}
        />
    );
}

export default EventsFiltersContainer;
