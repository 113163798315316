import { WP_API_BASE_URL } from './wp';
import applyConverters from 'axios-case-converter';
import axios from '../axiosConf';

export const WP_JWT_PATH = 'jwt-auth/v1/';
const WP_JWT_LOGIN = 'token';

const WpJwtAuthAPI = applyConverters(
    axios.create({
        baseURL: `${WP_API_BASE_URL}${WP_JWT_PATH}`,
    }),
);

const login = (username, password) =>
    WpJwtAuthAPI.post(WP_JWT_LOGIN, {
        username,
        password,
    })
        .then((response) => mapUser(response.data))
        .catch((err) => Promise.reject(err.response.data));

export default {
    login,
};

const mapUser = (user) => ({
    token: user.token,
    email: user.userEmail,
    id: user.userId,
    name: user.userNiceName || user.userDisplayName,
});
