export const NEW_USER = 'NEW_USER';
export const NEW_USER_ERROR = 'NEW_USER_ERROR';
export const NEW_USER_SUCCESS = 'NEW_USER_SUCCESS';
export const GET_USER = 'GET_USER';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_ERROR = 'GET_USER_ERROR';
export const GET_USERS = 'GET_USERS';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_ERROR = 'GET_USERS_ERROR';
export const SET_USER = 'SET_USER';
export const RESET_USER = 'RESET_USER';
export const UPDATE_USER = 'UPDATE_USER';

export const newUser = (user) => ({
    type: NEW_USER,
    user,
});

export const newUserSuccess = (user) => ({
    type: NEW_USER_SUCCESS,
    user,
});

export const newUserError = (err) => ({
    type: NEW_USER_ERROR,
    err,
});

export const getUser = (id) => ({
    type: GET_USER,
    id,
});

export const getUserSuccess = (user) => ({
    type: GET_USER_SUCCESS,
    user,
});

export const getUserError = (err) => ({
    type: GET_USER_ERROR,
    err,
});

export const getUsers = () => ({
    type: GET_USERS,
});

export const getUsersSuccess = (users) => ({
    type: GET_USERS_SUCCESS,
    users,
});

export const getUsersError = (err) => ({
    type: GET_USERS_ERROR,
    err,
});

export const setUser = (user) => ({
    type: SET_USER,
    user,
});

export const resetUser = () => ({
    type: RESET_USER,
});

export const updateUser = (user) => ({
    type: UPDATE_USER,
    user,
});
