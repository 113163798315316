import 'react-big-calendar/lib/sass/styles.scss'; // ! mandatory in first position (overrides base styles)
import './AltCalendar.css';
import './AltCalendarEvent.css';
import './AltCalendarHeader.css';
import './AltCalendarToolbar.css';
import './AltCalendarWidget.css'; // ! mandatory in last position (overrides base styles)
import 'moment/locale/fr';

import { Calendar, momentLocalizer } from 'react-big-calendar';
import { LAYOUT_APPBAR_HEIGHT, LAYOUT_GRID_GUTTER_SIZE, LAYOUT_SPACING } from '../../constants';
import React, { useState } from 'react';

import AltCalendarDialog from './AltCalendarDialog/AltCalendarDialog';
import { Box } from '@mui/material';
import EventInfos from '../Events/EventInfos/EventInfos';
import EventsFilters from '../Events/EventsFilters/EventsFiltersContainer';
import Loader from '../common/Loader';
import { ROUTE_PATH_DAYS_EVENTS } from '../../AppRoutes';
import { decodeUnicodeEntities } from '../../utils';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { useTheme } from '@mui/material/styles';

const localizer = momentLocalizer(moment);

var frMessages = {
    date: 'date',
    time: 'heure',
    event: 'événement',
    allDay: 'journée entière',
    week: 'semaine',
    work_week: 'semaine ouvrée',
    day: 'jour',
    month: 'mois',
    previous: 'précédent',
    next: 'suivant',
    yesterday: 'hier',
    tomorrow: 'demain',
    today: `Aujourd'hui`,
    agenda: 'agenda',
    noEventsInRange: 'Aucun événement pour cette période',
    showMore: (total) => `+ ${total} de plus`,
};

const styles = (theme) => ({
    filtersContainer: {
        marginBottom: theme.spacing(3),
    },
    fetchingCalendar: {
        opacity: 0.4,
    },
    loader: {
        position: 'absolute',
        top: 'calc(50% - 20px)',
        left: 'calc(50% - 20px)',
    },
    rbcDay: {
        backgroundColor: theme.palette.background.paper,
    },
    rbcEvent: {
        background: theme.palette.secondary.main,
        fontSize: '.8rem',
        borderRadius: 0,

        '&:hover': {
            background: theme.palette.secondary.dark,
        },
        '&:focus': {
            background: theme.palette.secondary.dark,
        },
        '&:active': {
            outline: 0,
        },
    },
    rbcSlot: {
        background: theme.palette.background.paper,
    },
});

const MARGIN = LAYOUT_GRID_GUTTER_SIZE;
const NAVBAR_HEIGHT = LAYOUT_APPBAR_HEIGHT + MARGIN;
const FILTERS_HEIGHT = 64 + MARGIN;
const TOTAL = NAVBAR_HEIGHT + FILTERS_HEIGHT + MARGIN;
const calendarHeight = `calc(100vh - ${TOTAL}px)`;

const useStyles = makeStyles(styles);

function AltCalendar({ events, isFetchingEvents }) {
    const classes = useStyles();
    const theme = useTheme();

    const [isOpened, setIsOpened] = useState(false);
    const [eventData, setEventData] = useState(null);

    const handleClickOpen = (eventData) => {
        if (!eventData) {
            return null;
        }
        setEventData(eventData);
        setIsOpened(true);
    };

    const handleClose = () => {
        setIsOpened(false);
        setEventData(null);
    };

    /*
  todo: use components prop to render calendar

  let components = {
    event: MyEvent, // used by each view (Month, Day, Week)
    toolbar: MyToolbar,
    agenda: {
         event: MyAgendaEvent // with the agenda view use a different component to render events
    }
  }*/

    const mappedEvents = events.map((ev) => ({ ...ev, title: decodeUnicodeEntities(ev.name) }));

    return (
        <Box
            position="relative"
            bgcolor={theme.palette.background.default}
        >
            <Box className={classes.filtersContainer}>
                <EventsFilters fullWidth />
            </Box>

            <Calendar
                localizer={localizer}
                events={mappedEvents}
                startAccessor="start"
                endAccessor="end"
                style={{ height: calendarHeight, marginBottom: LAYOUT_SPACING * 3 }}
                messages={frMessages}
                culture="fr"
                showMultiDayTimes
                onSelectEvent={(eventData) => handleClickOpen(eventData)}
                views={['month']}
                className={isFetchingEvents ? classes.fetchingCalendar : ''}
                onDrillDown={(date) => {
                    const formattedDate = moment(date).format('YYYY-MM-DD');
                    window.location.href = `/#${ROUTE_PATH_DAYS_EVENTS}/${formattedDate}`;
                }}
                dayPropGetter={(_day) => ({ className: classes.rbcDay })}
                eventPropGetter={(_event, _start, _end, _isSelected) => ({
                    className: classes.rbcEvent,
                })}
                slotPropGetter={(_event) => ({ className: classes.rbcSlot })}
            />
            {isFetchingEvents && (
                <Box className={classes.loader}>
                    <Loader margin="none" />
                </Box>
            )}

            {eventData && (
                <AltCalendarDialog
                    event={eventData}
                    handleClose={handleClose}
                    isOpened={isOpened}
                >
                    <EventInfos event={eventData} />
                </AltCalendarDialog>
            )}
        </Box>
    );
}

export default AltCalendar;
