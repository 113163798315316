import React, { useEffect } from 'react';
import { closeDialog, openDialog } from '../../common/AltDialog/AltDialogActions';
import {
    confirmDeleteEventDialog,
    editEventDialog,
    editVenueDialog,
    organizerDialog,
} from '../../common/AltDialog/DialogsData';
import { decodeUnicodeEntities, removeHtmlTags } from '../../../utils';
import { deleteEvent, getEvent, postEvent } from '../EventsActions';
import { useDispatch, useSelector } from 'react-redux';

import AltConfirmationDialog from '../../common/AltConfirmationDialog';
import AltPage from '../../common/AltPage';
import { CANCELED_PREFIX } from '../Events.utils';
import Event from './Event';
import EventForm from '../EventForm/EventForm';
import Loader from '../../common/Loader';
import OrganizerForm from '../../Organizers/OrganizerForm/OrganizerForm';
import VenueForm from '../../Venues/VenueForm/VenueForm';
import { useParams } from 'react-router-dom';

function EventContainer() {
    const params = useParams();
    const dispatch = useDispatch();

    const { event, isFetchingDeleteEvent, isFetchingEvent, isFetchingPostEvent, user, users } =
        useSelector((state) => ({
            event: state.events.event,
            isFetchingDeleteEvent: state.events.isFetchingDeleteEvent,
            isFetchingEvent: state.events.isFetchingEvent,
            isFetchingPostEvent: state.events.isFetchingPostEvent,
            user: state.users.user,
            users: state.users.list,
        }));

    const eventId = parseInt(params.id, 10) || params.slug;

    useEffect(() => {
        dispatch(getEvent(eventId, user.isLogged));
    }, [dispatch, eventId, user]);

    const openDialogPostEvent = () => {
        dispatch(
            openDialog({
                ...editEventDialog,
                component: <EventForm event={event} />,
            }),
        );
    };

    const handleClickCancel = () => {
        dispatch(
            postEvent({
                ...event,
                image: event.image?.url,
                title: `${CANCELED_PREFIX} ${event.name}`,
            }),
        );
    };

    const handleClickConfirm = () => {
        dispatch(
            postEvent({
                ...event,
                image: event.image?.url,
                title: event.name.replace(CANCELED_PREFIX, ''),
            }),
        );
    };

    const handleClickDelete = () => {
        dispatch(
            openDialog({
                ...confirmDeleteEventDialog,
                component: (
                    <AltConfirmationDialog
                        name={decodeUnicodeEntities(event.name)}
                        onConfirm={() => dispatch(deleteEvent(event.id))}
                        onClose={() => dispatch(closeDialog('confirmDeleteEvent'))}
                    />
                ),
            }),
        );
    };

    const openDialogPostVenue = (venue) => {
        dispatch(
            openDialog({
                ...editVenueDialog,
                component: <VenueForm venue={venue} />,
                callback: () => dispatch(getEvent(eventId, user.isLogged)),
            }),
        );
    };

    const openDialogOrganizer = () => {
        dispatch(
            openDialog({
                ...organizerDialog,
                component: <OrganizerForm user={user} />,
                callback: () => dispatch(getEvent(eventId, user.isLogged)),
            }),
        );
    };

    return !isFetchingEvent && !isFetchingPostEvent && !isFetchingDeleteEvent ? (
        <AltPage
            user={user}
            meta={
                event
                    ? {
                          title: event.name,
                          description: removeHtmlTags(event.description),
                          image: event.image.url,
                      }
                    : { metaTagsId: 'agenda' }
            }
        >
            <Event
                event={event}
                user={user}
                users={users}
                openDialogPostEvent={openDialogPostEvent}
                handleClickCancel={handleClickCancel}
                handleClickConfirm={handleClickConfirm}
                handleClickDelete={handleClickDelete}
                openDialogPostVenue={openDialogPostVenue}
                openDialogOrganizer={openDialogOrganizer}
            />
        </AltPage>
    ) : (
        <Loader />
    );
}

export default EventContainer;
