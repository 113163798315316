import {
    Event as EventIcon,
    Group as GroupIcon,
    Image as ImageIcon,
    Place as PlaceIcon,
} from '@mui/icons-material';

import EventForm from '../../Events/EventForm/EventForm';
import ForgotPasswordForm from '../../User/ForgotPasswordForm/ForgotPasswordForm';
import MediaForm from '../MediaForm';
import NewUserForm from '../../User/NewUserForm/NewUserForm';
import React from 'react';
import UserLoginForm from '../../User/UserLogin/UserLoginForm';
import { UserSubscribeDialogContent } from '../UserAlertSubscribe';
import VenueForm from '../../Venues/VenueForm/VenueForm';

const notClosableDialog = {
    hideCloseButton: true,
    dialogProps: {
        onClose: (_e, reason) => {
            if (reason === 'backdropClick') {
                return;
            }
        },
    },
};

export const userSubscribeDialog = {
    id: 'userSubscribe',
    component: <UserSubscribeDialogContent />,
    title: "Demande de création d'un compte organisateur",
    icon: <GroupIcon />,
    dialogProps: {
        maxWidth: 'sm',
    },
};

export const newUserDialog = {
    id: 'login',
    component: <NewUserForm />,
    title: 'Créer un nouvel utilisateur',
    icon: <GroupIcon />,
    dialogProps: {
        maxWidth: 'sm',
    },
};

export const loginDialog = {
    id: 'login',
    component: <UserLoginForm />,
    title: 'Connexion à mon compte organisateur',
    icon: <GroupIcon />,
    dialogProps: {
        maxWidth: 'sm',
    },
};

export const forgotPasswordDialog = {
    ...notClosableDialog,
    id: 'forgotPassword',
    component: <ForgotPasswordForm />,
    title: 'Réinitialiser mon mot de passe',
    icon: <GroupIcon />,
    dialogProps: {
        ...notClosableDialog.dialogProps,
        maxWidth: 'sm',
    },
};

export const postEventDialog = {
    ...notClosableDialog,
    id: 'postEvent',
    component: <EventForm />,
    title: 'Créer un événement',
    icon: <EventIcon />,
    dialogProps: {
        ...notClosableDialog.dialogProps,
        maxWidth: 'lg',
    },
};

export const editEventDialog = {
    ...postEventDialog,
    id: 'editEvent',
    title: "Modifier l'événement",
};

export const confirmDeleteEventDialog = {
    ...notClosableDialog,
    id: 'confirmDeleteEvent',
    title: 'Es-tu sûr de vouloir supprimer cet événement ?',
    hideCloseButton: true,
    dialogProps: {
        ...notClosableDialog.dialogProps,
        maxWidth: 'xs',
    },
};

export const postVenueDialog = {
    ...notClosableDialog,
    id: 'postVenue',
    component: <VenueForm />,
    title: 'Ajouter un lieu',
    icon: <PlaceIcon />,
    dialogProps: {
        ...notClosableDialog.dialogProps,
        maxWidth: 'md',
    },
};

export const editVenueDialog = {
    ...postVenueDialog,
    id: 'editVenue',
    title: 'Modifier le lieu',
};

export const confirmDeleteVenueDialog = {
    ...notClosableDialog,
    id: 'confirmDeleteVenue',
    title: 'Es-tu sûr de vouloir supprimer ce lieu ?',
    dialogProps: {
        ...notClosableDialog.dialogProps,
        maxWidth: 'xs',
    },
};

export const organizerDialog = {
    ...notClosableDialog,
    id: 'postOrganizer',
    title: 'Mon profil organisateur',
    icon: <GroupIcon />,
    dialogProps: {
        ...notClosableDialog.dialogProps,
        maxWidth: 'md',
    },
};

export const mediasDialog = {
    id: 'medias',
    component: <MediaForm />,
    title: 'Gérer mes images',
    icon: <ImageIcon />,
    dialogProps: {
        maxWidth: 'lg',
    },
};
