import { GET_TAGS, GET_TAGS_ERROR, GET_TAGS_SUCCESS } from './TagsActions';

const initialState = {
    errors: [],
    isFetchingTags: false,
    list: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_TAGS:
            return {
                ...state,
                isFetchingTags: true,
                list: initialState.list,
            };
        case GET_TAGS_SUCCESS:
            return {
                ...state,
                isFetchingTags: false,
                list: action.tags,
            };
        case GET_TAGS_ERROR:
            return {
                ...state,
                isFetchingTags: false,
                errors: [...state.errors, action.err],
            };
        default:
            return state;
    }
};
