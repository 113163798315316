import { OpenStreetMapProvider } from 'leaflet-geosearch';
import places from './osmPlaces.json';
import venues from './venues.json';

// Return json osm without api query (because nominatim usage is limited)
export const getJsonOsmPosition = (venue) => {
    if (venue.osm) {
        return venue.osm;
    }
    return venues[venue.slug]
        ? places.filter((place) => place.place_id === venues[venue.slug])[0]
        : null;
};

// Return venue with json osm or api response
export const getOsmByVenue = (venue) => {
    const osm = getJsonOsmPosition(venue);
    if (osm) {
        return new Promise((resolve) => {
            resolve(osm);
        });
    }

    return searchNominatimOsmPosition(venue);
};

// Search osm by venue and return all osm object (with address/zip/city/country or zip/city/country)
export const searchNominatimOsmPosition = (venue) => {
    const provider = new OpenStreetMapProvider();
    const query =
        venue.zip || venue.city
            ? // try with all params
              `${venue.address || ''} ${venue.zip || ''} ${venue.city || ''} ${venue.country || ''}`
            : // try with name
              `${venue.name}`;

    return provider.search({ query }).then((list) => {
        const place = list && list[0];
        if (place) {
            // result find
            return place.raw;
        }

        let query2;
        if (venue.zip || venue.city) {
            // retry without address
            query2 = `${venue.city || venue.zip} ${venue.country || ''}`;
        } else {
            // no result
            return null;
        }

        return provider.search({ query: query2 }).then((list) => {
            const place = list[0];
            if (place) {
                // result find
                return place.raw;
            }
            // no result
            return null;
        });
    });
};
