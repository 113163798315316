import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    FormLabel,
    Grid,
    InputLabel,
    Typography,
    capitalize,
} from '@mui/material';
import { CANCELED_PREFIX, PRIVATE_PREFIX, eventIsCanceled } from '../Events.utils';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import React, { useEffect, useReducer, useState } from 'react';
import { addDays, endOfDay, startOfDay } from 'date-fns';
import {
    addOrNotUrlPrefix,
    decodeHtmlEntities,
    isUrl,
    removeHtmlTags,
    toggleValue,
} from '../../../utils';
import { green, red } from '@mui/material/colors';
import { mediasDialog, postVenueDialog } from '../../common/AltDialog/DialogsData';
import { useDispatch, useSelector } from 'react-redux';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AddCircleOutline as AddCircleOutlineIcon } from '@mui/icons-material';
import AltButton from '../../common/AltButton';
import AltCheckbox from '../../common/AltCheckbox';
import AltDateTimePicker from '../../common/AltDateTimePicker';
import AltLink from '../../common/AltLink';
import AltSelect from '../../common/AltSelect';
import AltTextField from '../../common/AltTextField';
import { Editor } from 'react-draft-wysiwyg';
import Loader from '../../common/Loader';
import { LocalizationProvider } from '@mui/x-date-pickers';
import MediaForm from '../../common/MediaForm';
import classnames from 'classnames';
import draftToHtml from 'draftjs-to-html';
import { filterOrganizersByUser } from '../../Organizers/Organizers.utils';
import fr from 'date-fns/locale/fr';
import { getCategories } from '../../Categories/CategoriesActions';
import { getOrganizers } from '../../Organizers/OrganizersActions';
import { getTags } from '../../Tags/TagsActions';
import { getVenues } from '../../Venues/VenuesActions';
import htmlToDraft from 'html-to-draftjs';
import { makeStyles } from '@mui/styles';
import { openDialog } from '../../common/AltDialog/AltDialogActions';
import { postEvent } from '../EventsActions';

const useStyles = makeStyles((theme) => ({
    image: {
        width: 190,
        height: 100,
        border: '1px solid',
        borderColor: theme.palette.grey[200],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },

    formControlChecklist: {
        display: 'block',
        width: '100%',
    },

    checklist: {
        display: 'block',
        maxHeight: 165,
        overflow: 'auto',
        margin: theme.spacing(2, 0, 0),

        '& .MuiIconButton-root:hover': {
            backgroundColor: 'transparent',
        },
    },

    checklistItem: {
        display: 'block',
    },

    checkbox: {
        paddingTop: theme.spacing(0.75),
        paddingBottom: theme.spacing(0.75),
    },

    formHelperText: {
        marginLeft: 0,
        marginRight: 0,
    },

    formControlTags: {
        margin: theme.spacing(3, 0),
    },

    loaderContainer: {
        padding: theme.spacing(2, 0),
        textAlign: 'center',
    },

    descriptionWrapper: {
        margin: theme.spacing(4, 0, '8px'),
        border: '1px solid rgba(0, 0, 0, 0.23)',

        '&:has(.DraftEditorPlaceholder-hasFocus)': {
            borderColor: 'red',
        },
    },

    descriptionWrapperFocused: {
        borderWidth: 2,
        borderColor: '#000000',
    },

    descriptionWrapperIsValid: {
        borderColor: green[600],
    },

    descriptionWrapperError: {
        borderColor: red[600],
    },

    descriptionToolbar: {
        marginBottom: 0,
        border: 0,
        borderBottom: '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius: 0,
    },

    descriptionEditor: {
        height: 200,
        border: 0,
        padding: '0 14px',

        '& .DraftEditor-root': {
            height: 182,
        },
    },
    pickersContainer: {
        marginTop: theme.spacing(2),
    },
}));

const defaultValues = {
    featured: false,
    hide_from_listings: false,
    show_map: true,
    show_map_link: true,
    sticky: false,
    status: 'publish',
};

const selectProps = {
    fullWidth: true,
    margin: 'normal',
    variant: 'outlined',
    required: true,
};

const hasVenueAddress = (venue) => !!venue.address || !!venue.zip || !!venue.city;

const getVenueOptionLabel = (venue) =>
    [venue.address, venue.zip, venue.city].filter((item) => !!item).join(', ');

const initialState = {
    errors: {
        // undefined = empty optional, null = empty required, false = valid
        // mandatories fields :
        title: null,
        description: null,
        venue: null,
        organizer: null,
        categories: null,
        tags: null,
    },
};
const reducer = (state, action) => {
    let value, error;
    switch (action.type) {
        case 'title':
            value = action.value;
            error = !(value?.length > 0);
            return {
                ...state,
                title: value,
                errors: {
                    ...state.errors,
                    title: error,
                },
            };
        case 'isPrivate':
            value = action.value;
            return {
                ...state,
                isPrivate: value,
                errors: {
                    ...state.errors,
                    isPrivate: false,
                },
            };
        case 'isCanceled':
            value = action.value;
            return {
                ...state,
                isCanceled: value,
                errors: {
                    ...state.errors,
                    isCanceled: false,
                },
            };
        case 'description':
            value = action.value;
            error = !(
                removeHtmlTags(draftToHtml(convertToRaw(value.getCurrentContent()))).length > 1
            );
            return {
                ...state,
                description: value,
                errors: {
                    ...state.errors,
                    description: error,
                },
            };
        case 'startDate':
            value = action.value;
            error = !value;
            return {
                ...state,
                startDate: value,
                errors: {
                    ...state.errors,
                    startDate: error,
                },
            };
        case 'endDate':
            value = action.value;
            error = !value;
            return {
                ...state,
                endDate: value,
                errors: {
                    ...state.errors,
                    endDate: error,
                },
            };
        case 'venue':
            value = action.value !== '' ? parseInt(action.value, 10) : '';
            error = !value || value === '' || typeof value !== 'number';
            return {
                ...state,
                venue: value,
                errors: {
                    ...state.errors,
                    venue: error,
                },
            };
        case 'organizer':
            value = action.value !== '' ? parseInt(action.value, 10) : '';
            error = !value || value === '' || typeof value !== 'number';
            return {
                ...state,
                organizer: value,
                errors: {
                    ...state.errors,
                    organizer: error,
                },
            };
        case 'website':
            value = action.value;
            error = value?.length > 0 && !isUrl(value);
            return {
                ...state,
                website: value,
                errors: {
                    ...state.errors,
                    website: error,
                },
            };
        case 'cost':
            value = action.value;
            return {
                ...state,
                cost: value,
                errors: {
                    ...state.errors,
                    cost: false,
                },
            };
        case 'categories':
            value = toggleValue(state.categories, parseInt(action.value, 10));
            error = !(value && value.length > 0);
            return {
                ...state,
                categories: value,
                errors: {
                    ...state.errors,
                    categories: error,
                },
            };
        case 'tags':
            value = toggleValue(state.tags, parseInt(action.value, 10));
            error = !(value && value.length > 0);
            return {
                ...state,
                tags: value,
                errors: {
                    ...state.errors,
                    tags: error,
                },
            };
        case 'image':
            value = action.value;
            return {
                ...state,
                image: value,
                errors: {
                    ...state.errors,
                    image: false,
                },
            };
        default:
            throw new Error('Unexpected action');
    }
};

function EventForm({ event, handleClose }) {
    const classes = useStyles();

    const {
        categoriesList,
        isFetchingCategories,
        isFetchingNewUser,
        isFetchingOrganizers,
        isFetchingPostEvent,
        isFetchingTags,
        isFetchingVenues,
        organizers,
        tagsList,
        venues,
        isLogged,
    } = useSelector((state) => ({
        categoriesList: state.categories.list,
        isFetchingCategories: state.categories.isFetchingCategories,
        organizers: filterOrganizersByUser(state.organizers.list, state.users.user),
        isFetchingOrganizers: state.organizers.isFetchingOrganizers,
        tagsList: state.tags.list,
        isFetchingTags: state.tags.isFetchingTags,
        venues: state.venues.list.sort((a, b) =>
            a.venue.toLowerCase() > b.venue.toLowerCase() ? 1 : -1,
        ),
        isFetchingVenues: state.venues.isFetchingVenues,
        isFetchingPostEvent: state.events.isFetchingPostEvent,
        isLogged: state.users.user.isLogged,
        isFetchingNewUser: state.users.isFetchingNewUser,
    }));

    const dispatch = useDispatch();
    useEffect(() => {
        if (categoriesList.length < 1 && !isFetchingCategories) {
            dispatch(getCategories());
        }
        if (organizers.length < 1 && !isFetchingOrganizers) {
            dispatch(getOrganizers());
        }
        if (tagsList.length < 1 && !isFetchingTags) {
            dispatch(getTags());
        }
        if (venues.length < 1 && !isFetchingVenues) {
            dispatch(getVenues(isLogged));
        }
    });

    const setSelectedImageUrl = (url) => {
        setForm({ type: 'image', value: url });
    };

    const openMediaDialog = () => {
        dispatch(
            openDialog({
                ...mediasDialog,
                title: "Choisir l'image de l'événement",
                component: <MediaForm setSelectedImageUrl={setSelectedImageUrl} />,
            }),
        );
    };

    const tomorrow = addDays(new Date(), 1);

    const initDescription = (description) =>
        EditorState.createWithContent(
            ContentState.createFromBlockArray(htmlToDraft(description).contentBlocks),
        );

    const init = () =>
        !event
            ? {
                  ...initialState,
                  email: '',
                  organizerName: '',
                  description: EditorState.createEmpty(),
                  isPrivate: false,
                  startDate: startOfDay(tomorrow),
                  endDate: endOfDay(tomorrow),
                  errors: {
                      ...initialState.errors,
                      startDate: false,
                      endDate: false,
                  },
              }
            : {
                  ...event,
                  title: decodeHtmlEntities(event.name)
                      .replace(CANCELED_PREFIX, '')
                      .replace(PRIVATE_PREFIX, '')
                      .trim(),
                  isCanceled: eventIsCanceled(event.name),
                  description: initDescription(event.description),
                  venue: event.venue.id,
                  organizer: event.organizer[0]?.id,
                  categories: event.categories.map((cat) => cat.id),
                  tags: event.tags.map((tag) => tag.id),
                  startDate: new Date(event.startDate),
                  endDate: new Date(event.endDate),
                  image: event.image?.url,
                  errors: {
                      title: false,
                      description: false,
                      startDate: false,
                      endDate: false,
                      venue: false,
                      categories: false,
                      tags: false,
                  },
              };

    const [form, setForm] = useReducer(reducer, initialState, init);

    const handleChangeValue = (type, e) => setForm({ type, value: e.currentTarget.value });

    /*const submitRegister = () => {
        dispatch(newUser({
            username: email,
            email,
            password: '123456!',
            firstName: '', 
            lastName: email,
        }));
    };*/

    const submitEvent = () => {
        const initValues = event?.id ? { id: event.id } : defaultValues;
        const capitalizedTitle = capitalize(title);
        const prefixedTitle = isCanceled
            ? `${CANCELED_PREFIX} ${capitalizedTitle}`
            : capitalizedTitle;

        dispatch(
            postEvent({
                ...initValues,
                title: prefixedTitle,
                featured: isPrivate, // use this field instead of wp private value
                description: draftToHtml(convertToRaw(description.getCurrentContent())),
                startDate: new Date(startDate),
                endDate: new Date(endDate),
                venue,
                organizer: [organizer],
                website: website?.length > 0 ? addOrNotUrlPrefix(website) : undefined,
                cost,
                categories,
                tags,
                image,
            }),
        );
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // submitRegister();
        submitEvent();
        handleClose();
    };

    const [isFocusedDescription, setIsFocusedDescription] = useState(false);

    const {
        email,
        organizerName,
        title,
        isPrivate,
        isCanceled,
        description,
        startDate,
        endDate,
        venue,
        organizer,
        website,
        cost,
        categories,
        tags,
        image,
        errors,
    } = form;

    const openDialogPostVenue = () => {
        dispatch(openDialog(postVenueDialog));
    };

    if (!organizer && organizers?.length === 1) {
        setForm({ type: 'organizer', value: organizers[0].id });
    }

    const errorsValues = Object.values(errors);
    const isValid = errorsValues.every((val) => val === false);
    const hasEmptyMandatoriesFields = errorsValues.some((val) => val === null);

    return (
        <form
            onSubmit={handleSubmit}
            noValidate
            autoComplete="off"
        >
            <DialogContent dividers>
                <Grid
                    container
                    justifyContent="flex-start"
                    spacing={4}
                >
                    <Grid
                        item
                        xs={12}
                        md={9}
                    >
                        {!isLogged && (
                            <>
                                <AltTextField
                                    label="Adresse e-mail (celle utilisée pour Le Crieur)"
                                    value={email || ''}
                                    onChange={(e) => handleChangeValue('email', e)}
                                    required
                                    error={errors.email}
                                    autoFocus
                                />

                                <AltTextField
                                    label="Nom de l'organisateur"
                                    value={organizerName || ''}
                                    onChange={(e) => handleChangeValue('organizerName', e)}
                                    required
                                    error={errors.organizerName}
                                    autoFocus
                                />
                            </>
                        )}

                        <AltTextField
                            label="Nom de l'événement"
                            value={title || ''}
                            onChange={(e) => handleChangeValue('title', e)}
                            required
                            error={errors.title}
                            autoFocus
                        />

                        <FormControl
                            component="fieldset"
                            error={errors.isPrivate}
                            className={classes.formControlChecklist}
                            required
                        >
                            <FormGroup className={classes.checklist}>
                                <FormControlLabel
                                    control={
                                        <AltCheckbox
                                            value={isPrivate}
                                            onChange={() =>
                                                setForm({ type: 'isPrivate', value: !isPrivate })
                                            }
                                            className={classes.checkbox}
                                            checked={isPrivate}
                                        />
                                    }
                                    label="Événement privé"
                                />
                            </FormGroup>
                            <FormHelperText className={classes.formHelperText}>
                                Les événements privés ne sont visibles que par les organisateurs.
                            </FormHelperText>
                        </FormControl>

                        {event?.id && (
                            <FormControl
                                component="fieldset"
                                error={errors.isCanceled}
                                className={classes.formControlChecklist}
                                required
                            >
                                <FormGroup className={classes.checklist}>
                                    <FormControlLabel
                                        control={
                                            <AltCheckbox
                                                value={isCanceled}
                                                onChange={() =>
                                                    setForm({
                                                        type: 'isCanceled',
                                                        value: !isCanceled,
                                                    })
                                                }
                                                className={classes.checkbox}
                                                checked={isCanceled}
                                            />
                                        }
                                        label="Événement annulé"
                                    />
                                </FormGroup>
                                <FormHelperText className={classes.formHelperText}>
                                    Les événements annulés restent visibles pour informer les
                                    visiteurs de leur annulation.
                                </FormHelperText>
                            </FormControl>
                        )}

                        <Editor
                            placeholder="Description, programme, infos... *"
                            editorState={description}
                            wrapperClassName={classnames(
                                classes.descriptionWrapper,
                                {
                                    [classes.descriptionWrapperIsValid]:
                                        errors.description === false,
                                },
                                { [classes.descriptionWrapperError]: errors.description === true },
                                { [classes.descriptionWrapperFocused]: isFocusedDescription },
                            )}
                            editorClassName={classes.descriptionEditor}
                            toolbarClassName={classes.descriptionToolbar}
                            onEditorStateChange={(value) => setForm({ type: 'description', value })}
                            localization={{ locale: 'fr' }}
                            toolbar={{
                                options: ['inline', 'list', 'link', 'image', 'emoji', 'history'],
                                inline: {
                                    options: ['bold', 'italic', 'underline'],
                                },
                                list: {
                                    options: ['unordered', 'ordered'],
                                },
                            }}
                            onFocus={() => setIsFocusedDescription(true)}
                            onBlur={() => setIsFocusedDescription(false)}
                        />

                        <Grid
                            container
                            justifyContent="flex-start"
                            spacing={3}
                            className={classes.pickersContainer}
                        >
                            <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                                adapterLocale={fr}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    md={6}
                                >
                                    <AltDateTimePicker
                                        id="startDate"
                                        label="Débute le"
                                        value={startDate}
                                        onChange={(value) => setForm({ type: 'startDate', value })}
                                        disablePast={!event?.id}
                                        minDateMessage="La date de l'événement ne doit pas être déjà passée"
                                        required
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    md={6}
                                >
                                    <AltDateTimePicker
                                        id="endDate"
                                        label="Se termine le"
                                        value={endDate}
                                        onChange={(value) => setForm({ type: 'endDate', value })}
                                        disablePast={!event?.id}
                                        minDateTime={startDate}
                                        minDateMessage="L'événement ne peut pas se terminer avant de commencer :P"
                                        required
                                    />
                                </Grid>
                            </LocalizationProvider>
                        </Grid>

                        <FormControl {...selectProps}>
                            <InputLabel
                                htmlFor="venue"
                                variant="outlined"
                            >
                                Lieu
                            </InputLabel>
                            <AltSelect
                                onChange={(e) => handleChangeValue('venue', e)}
                                inputProps={{
                                    name: 'venue',
                                    id: 'venue',
                                    disabled: isFetchingVenues,
                                }}
                                error={errors.venue}
                                label="Lieu *"
                                value={venues.length > 0 ? venue : ''}
                            >
                                <option
                                    aria-label="Aucun"
                                    value=""
                                />
                                {venues?.map((venue) => (
                                    <option
                                        key={venue.id}
                                        value={venue.id}
                                    >
                                        {decodeHtmlEntities(venue.venue)}
                                        {hasVenueAddress(venue) &&
                                            ` (${getVenueOptionLabel(venue)})`}
                                    </option>
                                ))}
                            </AltSelect>
                            <Box
                                mt={1}
                                display="flex"
                                alignItems="center"
                            >
                                <AddCircleOutlineIcon
                                    fontSize="small"
                                    color="secondary"
                                />
                                &nbsp;
                                <AltLink onClick={openDialogPostVenue}>
                                    Ajouter un nouveau lieu
                                </AltLink>
                            </Box>
                        </FormControl>

                        <FormControl {...selectProps}>
                            <InputLabel htmlFor="organizer">Organisateur</InputLabel>
                            <AltSelect
                                onChange={(e) => handleChangeValue('organizer', e)}
                                inputProps={{
                                    name: 'organizer',
                                    id: 'organizer',
                                }}
                                error={errors.organizer}
                                label="Organisateur *"
                                value={organizers.length > 0 ? organizer : ''}
                                isFetching={isFetchingOrganizers}
                                disabled={isFetchingOrganizers || organizers.length === 1}
                            >
                                {organizers.length > 1 && (
                                    <option
                                        aria-label="Aucun"
                                        value=""
                                    />
                                )}
                                {organizers?.map((organizer) => (
                                    <option
                                        key={organizer.id}
                                        value={organizer.id}
                                    >
                                        {decodeHtmlEntities(organizer.organizer)}
                                    </option>
                                ))}
                            </AltSelect>
                        </FormControl>

                        <AltTextField
                            label="Site web de l'événement"
                            value={website || ''}
                            onChange={(e) => handleChangeValue('website', e)}
                            helperText={
                                errors.website ? "L'URL doit être au format mondomaine.fr" : null
                            }
                            error={errors.website}
                        />

                        <AltTextField
                            label="Prix d'entrée"
                            value={cost || ''}
                            onChange={(e) => handleChangeValue('cost', e)}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={3}
                    >
                        <FormControl
                            component="fieldset"
                            error={errors.categories}
                            className={classes.formControlChecklist}
                            required
                        >
                            <FormLabel component="legend">Catégories</FormLabel>
                            {categoriesList && (
                                <FormGroup className={classes.checklist}>
                                    {categoriesList?.map((cat) => (
                                        <FormControlLabel
                                            key={cat.id}
                                            control={
                                                <AltCheckbox
                                                    value={cat.id}
                                                    onChange={(e) =>
                                                        handleChangeValue('categories', e)
                                                    }
                                                    className={classes.checkbox}
                                                    checked={categories?.indexOf(cat.id) > -1}
                                                />
                                            }
                                            label={cat.name}
                                            className={classes.checklistItem}
                                        />
                                    ))}
                                </FormGroup>
                            )}
                            {isFetchingCategories && (
                                <Loader
                                    size="sm"
                                    margin="dense"
                                />
                            )}
                        </FormControl>
                        <FormControl
                            component="fieldset"
                            error={errors.tags}
                            className={classnames(
                                classes.formControlChecklist,
                                classes.formControlTags,
                            )}
                            required
                        >
                            <FormLabel component="legend">Thématiques</FormLabel>
                            {tagsList && (
                                <FormGroup className={classes.checklist}>
                                    {tagsList.map((tag) => (
                                        <FormControlLabel
                                            key={tag.id}
                                            control={
                                                <AltCheckbox
                                                    value={tag.id}
                                                    onChange={(e) => handleChangeValue('tags', e)}
                                                    className={classes.checkbox}
                                                    checked={tags?.indexOf(tag.id) > -1}
                                                />
                                            }
                                            label={tag.name}
                                            className={classes.checklistItem}
                                        />
                                    ))}
                                </FormGroup>
                            )}
                            {isFetchingTags && (
                                <Loader
                                    size="sm"
                                    margin="dense"
                                />
                            )}
                        </FormControl>

                        <FormControl>
                            <FormLabel component="legend">Image de couverture</FormLabel>

                            {image && (
                                <Box
                                    className={classes.image}
                                    style={{
                                        backgroundImage: `url(${image})`,
                                    }}
                                    mt={3}
                                ></Box>
                            )}

                            <Box mt={2}>
                                {image && (
                                    <AltLink
                                        onClick={() => setSelectedImageUrl(null)}
                                        component="p"
                                        variant="body1"
                                    >
                                        Supprimer
                                    </AltLink>
                                )}
                                <AltLink
                                    onClick={openMediaDialog}
                                    component="p"
                                    variant="body1"
                                >
                                    {image ? 'Choisir une autre image' : 'Choisir une image'}
                                </AltLink>
                            </Box>
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                {hasEmptyMandatoriesFields && (
                    <Typography variant="subtitle2">
                        Tous les champs obligatoires (*) doivent être remplis
                    </Typography>
                )}
                {!isValid && !hasEmptyMandatoriesFields && (
                    <Typography
                        color="error"
                        variant="subtitle2"
                    >
                        Merci de corriger les erreurs avant de valider
                    </Typography>
                )}
                <Button
                    variant="contained"
                    onClick={handleClose}
                    className={classes.cancelButton}
                >
                    Annuler
                </Button>
                <AltButton
                    type="submit"
                    variant="contrib"
                    onClick={handleSubmit}
                    disabled={!isValid}
                    endIcon={
                        isFetchingPostEvent || isFetchingNewUser ? (
                            <Loader
                                margin="none"
                                size="xxs"
                                color="white"
                            />
                        ) : null
                    }
                >
                    Valider
                </AltButton>
            </DialogActions>
        </form>
    );
}

export default EventForm;
