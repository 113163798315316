import moment from 'moment';
import { getJsonOsmPosition, searchNominatimOsmPosition } from '../../api/nominatim/osm';

export const filterRemovePastEvents = (eventsList) =>
    eventsList?.length
        ? eventsList.filter((event) => {
              const start = moment(event.start);
              return !!start.isAfter(moment());
          })
        : eventsList;

// Return events list with osm in each event's venue object
export const addOsmToVenuesEvents = async (events) =>
    Promise.all(
        events.list.map((event) => {
            const { venue } = event;
            const jsonOsm = getJsonOsmPosition(venue);
            if (jsonOsm) {
                return {
                    ...event,
                    venue: {
                        ...venue,
                        osm: jsonOsm,
                    },
                };
            }
            return searchNominatimOsmPosition(venue).then((osm) => ({
                ...event,
                venue: {
                    ...venue,
                    osm,
                },
            }));
        }),
    );
