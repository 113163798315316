import { ArrowDropDown as ArrowDropDownIcon, Search as SearchIcon } from '@mui/icons-material';
import {
    Checkbox,
    FormControl,
    InputAdornment,
    InputLabel,
    ListItemText,
    ListSubheader,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import { useMemo, useState } from 'react';

import Loader from './Loader';
import classnames from 'classnames';
import { decodeHtmlEntities } from '../../utils';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    formControl: {},
    hasValue: {
        '& fieldset': {
            borderColor: '#000000',
        },
    },
}));

const SEARCH_HEIGHT = 64;
const ITEM_HEIGHT = 54;
const ITEM_PADDING_TOP = 0;

const MenuProps = {
    PaperProps: {
        autoFocus: false,
        style: {
            left: 0,
            maxHeight: SEARCH_HEIGHT + ITEM_HEIGHT * 6 + ITEM_PADDING_TOP,
            minWidth: 0,
            maxWidth: 320,
        },
    },
};
const getMenuProps = (width) => ({
    ...MenuProps,
    PaperProps: {
        ...MenuProps.PaperProps,
        style: {
            ...MenuProps.PaperProps.style,
            maxWidth: width,
        },
    },
});

const containsText = (text, searchText) =>
    text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

function AltSelectWithSearch({
    handleChange,
    isFetching,
    options,
    label,
    id,
    filters,
    nameKey = 'name',
}) {
    const classes = useStyles();
    const [searchText, setSearchText] = useState('');

    const displayedOptions = useMemo(
        () =>
            searchText.length > 0
                ? options.filter((option) => containsText(option[nameKey], searchText))
                : options,
        [nameKey, options, searchText],
    );

    return (
        <FormControl
            variant="outlined"
            size="small"
            fullWidth
            className={classnames(classes.formControl)}
        >
            <InputLabel id={`select-${id}-label`}>{label}</InputLabel>
            <Select
                label={label}
                labelId={`select-${id}-label`}
                id={`select-${id}-checkbox`}
                multiple
                value={filters}
                onChange={(e) => handleChange(e, id)}
                renderValue={(selected) =>
                    selected
                        .map((s) =>
                            decodeHtmlEntities(options?.find((opt) => opt.id === s)?.[nameKey]),
                        )
                        .join(', ')
                }
                MenuProps={getMenuProps(320)}
                inputProps={{ disabled: isFetching }}
                IconComponent={
                    isFetching
                        ? () => (
                              <Loader
                                  size="xxs"
                                  margin="none"
                                  isInField
                              />
                          )
                        : ArrowDropDownIcon
                }
                className={classnames({ [classes.hasValue]: filters.length > 0 })}
                onClose={() => setSearchText('')}
            >
                <ListSubheader>
                    <TextField
                        size="small"
                        autoFocus
                        placeholder="Recherche..."
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        onChange={(e) => setSearchText(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key !== 'Escape') e.stopPropagation();
                        }}
                        onClick={(e) => e.stopPropagation()}
                    />
                </ListSubheader>
                {displayedOptions.map((opt) => (
                    <MenuItem
                        key={opt.id}
                        value={opt.id}
                        disableGutters
                    >
                        <Checkbox checked={filters.includes(opt.id)} />
                        <ListItemText primary={decodeHtmlEntities(opt[nameKey])} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default AltSelectWithSearch;
