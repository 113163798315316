import { Box, ListItemIcon } from '@mui/material';
import { addOrNotUrlPrefix, removeUrlPrefix } from '../../../utils';

import HttpIcon from '@mui/icons-material/Http';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIcon from '@mui/icons-material/Phone';
import React from 'react';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    list: {
        padding: 0,
        '@media (min-width:960px)': {
            minHeight: 78,
        },
    },
    listItem: {
        padding: theme.spacing(0.5, 0),
    },
}));

function OrganizerContacts({ boxProps, displayEmpty, organizer }) {
    const classes = useStyles();

    return organizer ? (
        <Box {...boxProps}>
            <List className={classes.list}>
                {(organizer.email || displayEmpty) && (
                    <ListItem className={classes.listItem}>
                        <ListItemIcon>
                            <MailOutlineIcon fontSize="small" />
                        </ListItemIcon>
                        <Typography variant="body2">
                            {organizer.email ? (
                                <Link href={`mailto:${organizer.email}`}>{organizer.email}</Link>
                            ) : (
                                'Non renseignée'
                            )}
                        </Typography>
                    </ListItem>
                )}
                {(organizer.phone || displayEmpty) && (
                    <ListItem className={classes.listItem}>
                        <ListItemIcon>
                            <PhoneIcon fontSize="small" />
                        </ListItemIcon>
                        <Typography variant="body2">
                            {organizer.phone || 'Non renseigné'}
                        </Typography>
                    </ListItem>
                )}
                {(organizer.website || displayEmpty) && (
                    <ListItem className={classes.listItem}>
                        <ListItemIcon>
                            <HttpIcon fontSize="small" />
                        </ListItemIcon>
                        <Typography variant="body2">
                            {organizer.website ? (
                                <Link href={addOrNotUrlPrefix(organizer.website)}>
                                    {removeUrlPrefix(organizer.website)}
                                </Link>
                            ) : (
                                'Non renseigné'
                            )}
                        </Typography>
                    </ListItem>
                )}
            </List>
        </Box>
    ) : null;
}

export default OrganizerContacts;
