export const GET_VENUE = 'GET_VENUE';
export const GET_VENUE_ERROR = 'GET_VENUE_ERROR';
export const GET_VENUE_SUCCESS = 'GET_VENUE_SUCCESS';
export const GET_VENUES = 'GET_VENUES';
export const GET_VENUES_SUCCESS = 'GET_VENUES_SUCCESS';
export const GET_VENUES_ERROR = 'GET_VENUES_ERROR';
export const POST_VENUE = 'POST_VENUE';
export const POST_VENUE_SUCCESS = 'POST_VENUE_SUCCESS';
export const POST_VENUE_ERROR = 'POST_VENUE_ERROR';
export const DELETE_VENUE = 'DELETE_VENUE';
export const DELETE_VENUE_SUCCESS = 'DELETE_VENUE_SUCCESS';
export const DELETE_VENUE_ERROR = 'DELETE_VENUE_ERROR';

export const getVenue = (id, isLogged) => ({
    type: GET_VENUE,
    id,
    isLogged,
});

export const getVenueSuccess = (venue) => ({
    type: GET_VENUE_SUCCESS,
    venue,
});

export const getVenueError = (err) => ({
    type: GET_VENUE_ERROR,
    err,
});

export const getVenues = (isLogged) => ({
    type: GET_VENUES,
    isLogged,
});

export const getVenuesSuccess = (venues) => ({
    type: GET_VENUES_SUCCESS,
    venues,
});

export const getVenuesError = (err) => ({
    type: GET_VENUES_ERROR,
    err,
});

export const postVenue = (venue, callback) => ({
    type: POST_VENUE,
    venue,
    callback,
});

export const postVenueSuccess = (venue) => ({
    type: POST_VENUE_SUCCESS,
    venue,
});

export const postVenueError = (err) => ({
    type: POST_VENUE_ERROR,
    err,
});

export const deleteVenue = (id) => ({
    type: DELETE_VENUE,
    id,
});

export const deleteVenueSuccess = (venue) => ({
    type: DELETE_VENUE_SUCCESS,
    venue,
});

export const deleteVenueError = (err) => ({
    type: DELETE_VENUE_ERROR,
    err,
});
