import {
    DELETE_EVENT,
    GET_EVENT,
    GET_EVENTS,
    POST_EVENT,
    deleteEventError,
    deleteEventSuccess,
    getEventError,
    getEventSuccess,
    getEventsError,
    getEventsSuccess,
    postEventError,
    postEventSuccess,
} from './components/Events/EventsActions';
import {
    DELETE_MEDIA,
    GET_MEDIAS,
    GET_MORE_MEDIAS,
    UPDATE_MEDIA,
    UPLOAD_IMAGE,
    deleteMediaError,
    deleteMediaSuccess,
    getMediasError,
    getMediasSuccess,
    getMoreMediasError,
    getMoreMediasSuccess,
    updateMediaError,
    updateMediaSuccess,
    uploadMediaError,
    uploadMediaSuccess,
} from './components/Medias/MediasActions';
import {
    DELETE_VENUE,
    GET_VENUE,
    GET_VENUES,
    POST_VENUE,
    deleteVenueError,
    deleteVenueSuccess,
    getVenueError,
    getVenueSuccess,
    getVenuesError,
    getVenuesSuccess,
    postVenueError,
    postVenueSuccess,
} from './components/Venues/VenuesActions';
import {
    GET_CATEGORIES,
    getCategoriesError,
    getCategoriesSuccess,
} from './components/Categories/CategoriesActions';
import {
    GET_ORGANIZER,
    GET_ORGANIZERS,
    POST_ORGANIZER,
    getOrganizerError,
    getOrganizerSuccess,
    getOrganizersError,
    getOrganizersSuccess,
    postOrganizerError,
    postOrganizerSuccess,
} from './components/Organizers/OrganizersActions';
import { GET_TAGS, getTagsError, getTagsSuccess } from './components/Tags/TagsActions';
import {
    GET_USER,
    GET_USERS,
    NEW_USER,
    getUserError,
    getUserSuccess,
    getUsersError,
    getUsersSuccess,
    newUserError,
    newUserSuccess,
    resetUser,
    updateUser,
} from './components/User/UserActions';
import {
    LOGIN,
    LOGOUT,
    RESET_PASSWORD,
    SET_PASSWORD,
    loginError,
    loginSuccess,
    resetPasswordError,
    resetPasswordSuccess,
    resetToken,
    setPasswordError,
    setPasswordSuccess,
} from './providers/AuthenticationProvider/AuthenticationProviderActions';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { wpJwt, wpTribe } from './api/';

import { addNotification } from './components/common/AltNotifications/AltNotificationsActions';
import { closeDialog } from './components/common/AltDialog/AltDialogActions';
import { updateCookiesUser } from './providers/AuthenticationProvider/AuthenticationProvider';
import wp from './api/wordpress/wp';
import wpBdpwr from './api/wordpress/wpBdpwr';

function* getEvent(action) {
    try {
        const event = yield call(wpTribe.getEvent, action.id, action.isLogged);
        yield put(getEventSuccess(event));
    } catch (err) {
        yield put(getEventError(err));
        yield put(addNotification('getEventError'));
    }
}

function* getEvents(action) {
    try {
        const events = yield call(
            wpTribe.getEvents,
            action.isLogged,
            action.page,
            action.limit,
            action.startDate,
            action.endDate,
            action.status,
        );
        yield put(getEventsSuccess(events));
    } catch (err) {
        yield put(getEventsError(err));
        yield put(addNotification('getEventsError'));
    }
}

function* postEvent(action) {
    const isUpdate = !!action.event.id;
    try {
        const {
            event: { excerpt, image, website },
        } = action;
        const mappedEvent = {
            ...action.event,
            excerpt: excerpt?.length > 0 ? excerpt : undefined, // fix empty string not valid
            website: website?.length > 0 ? website : undefined, // fix empty string not valid
            image: image ? image : undefined, // fix 'false' value not valid
        };
        const event = yield call(wpTribe.postEvent, mappedEvent);
        yield put(postEventSuccess(event));
        const notifSuccess = isUpdate ? 'updateEventSuccess' : 'createEventSuccess';
        yield put(addNotification(notifSuccess));
    } catch (err) {
        yield put(postEventError(err));
        const notifError = isUpdate ? 'updateEventError' : 'createEventError';
        yield put(addNotification(notifError));
    }
}

function* deleteEvent(action) {
    try {
        const event = yield call(wpTribe.deleteEvent, action.id);
        yield put(deleteEventSuccess(event));
        yield put(addNotification('deleteEventSuccess'));
    } catch (err) {
        yield put(deleteEventError(err));
        yield put(addNotification('deleteEventError'));
    }
}

function* getVenue(action) {
    try {
        const venue = yield call(wpTribe.getVenue, action.id, action.isLogged);
        yield put(getVenueSuccess(venue));
    } catch (err) {
        yield put(getVenueError(err));
        yield put(addNotification('getVenueError'));
    }
}

function* getVenues(action) {
    try {
        const venues = yield call(wpTribe.getVenues, action.isLogged);
        yield put(getVenuesSuccess(venues));
    } catch (err) {
        yield put(getVenuesError(err));
        yield put(addNotification('getVenuesError'));
    }
}

function* postVenue(action) {
    const isUpdate = !!action.venue.id;
    try {
        const venue = yield call(wpTribe.postVenue, action.venue);
        yield put(postVenueSuccess(venue));
        yield put(closeDialog('postVenue'));
        const notifSuccess = isUpdate ? 'updateVenueSuccess' : 'createVenueSuccess';
        yield put(addNotification(notifSuccess));
        if (action.callback) {
            yield put(action.callback());
        }
    } catch (err) {
        yield put(postVenueError(err));
        const notifError = isUpdate ? 'updateVenueError' : 'createVenueError';
        yield put(addNotification(notifError));
    }
}

function* deleteVenue(action) {
    try {
        const venue = yield call(wpTribe.deleteVenue, action.id);
        yield put(deleteVenueSuccess(venue));
        yield put(addNotification('deleteVenueSuccess'));
    } catch (err) {
        yield put(deleteVenueError(err));
        yield put(addNotification('deleteVenueError'));
    }
}

function* getOrganizer(action) {
    try {
        const organizer = yield call(wpTribe.getOrganizer, action.id);
        yield put(getOrganizerSuccess(organizer));
    } catch (err) {
        yield put(getOrganizerError(err));
        yield put(addNotification('getOrganizerError'));
    }
}

function* getOrganizers(_action) {
    try {
        const organizers = yield call(wpTribe.getOrganizers);
        yield put(getOrganizersSuccess(organizers));
    } catch (err) {
        yield put(getOrganizersError(err));
        yield put(addNotification('getOrganizersError'));
    }
}

function* postOrganizer(action) {
    const isUpdate = !!action.organizer.organizer.id;
    try {
        const organizer = yield call(wpTribe.postOrganizer, action.organizer.organizer);
        const user = yield call(wp.postUser, action.organizer.user);
        yield put(postOrganizerSuccess(organizer));
        yield put(updateUser(user));
        yield put(closeDialog('postOrganizer'));
        const notifSuccess = isUpdate ? 'updateOrganizerSuccess' : 'createOrganizerSuccess';
        yield put(addNotification(notifSuccess));
        if (action.callback) {
            yield put(action.callback());
        }
        updateCookiesUser(user);
    } catch (err) {
        yield put(postOrganizerError(err));
        const notifError = isUpdate ? 'updateOrganizerError' : 'createOrganizerError';
        yield put(addNotification(notifError));
    }
}

function* getCategories(_action) {
    try {
        const categories = yield call(wpTribe.getCategories);
        yield put(getCategoriesSuccess(categories));
    } catch (err) {
        yield put(getCategoriesError(err));
        yield put(addNotification('getCategoriesError'));
    }
}

function* getTags(_action) {
    try {
        const tags = yield call(wpTribe.getTags);
        yield put(getTagsSuccess(tags));
    } catch (err) {
        yield put(getTagsError(err));
        yield put(addNotification('getTagsError'));
    }
}

function* newUser(action) {
    try {
        const user = yield call(wp.newUser, action.user);
        yield put(newUserSuccess(user));
        yield put(addNotification('newUserSuccess'));
    } catch (err) {
        yield put(newUserError(err));
        switch (err.code) {
            case 'existing_user_email':
                return yield put(addNotification('newUserErrorExistingEmail'));
            case 'existing_user_login':
                return yield put(addNotification('newUserErrorExistingLogin'));
            default:
                return yield put(addNotification('newUserError'));
        }
    }
}

function* getUser(_action) {
    try {
        const user = yield call(wp.getUser);
        yield put(getUserSuccess(user));
    } catch (err) {
        yield put(getUserError(err));
        yield put(addNotification('getUserError'));
    }
}

function* getUsers(_action) {
    try {
        const users = yield call(wp.getUsers);
        yield put(getUsersSuccess(users));
    } catch (err) {
        yield put(getUsersError(err));
        yield put(addNotification('getUsersError'));
    }
}

function* login(action) {
    try {
        const authUser = yield call(wpJwt.login, action.username, action.password);
        yield put(loginSuccess(authUser));
        const wpUser = yield call(wp.getUser, authUser.id);
        const user = {
            ...authUser,
            ...wpUser,
        };
        yield put(getUserSuccess(user));
        yield put(closeDialog('login'));
        yield put(addNotification('loginSuccess'));
    } catch (err) {
        yield put(loginError(err));
        if (err.data?.status === 403) {
            yield put(addNotification('loginError403'));
        } else {
            yield put(addNotification('loginError'));
        }
    }
}

function* logout() {
    yield put(resetUser());
    yield put(resetToken());
    yield put(addNotification('logoutSuccess'));
}

function* resetPassword(action) {
    try {
        const data = yield call(wpBdpwr.resetPassword, action.email);
        yield put(resetPasswordSuccess(data));
        yield put(addNotification('resetPasswordSuccess'));
    } catch (err) {
        yield put(resetPasswordError(err));
        if (err.code === 'bad_email') {
            yield put(addNotification('resetPasswordErrorBadEmail'));
        } else {
            yield put(addNotification('resetPasswordError'));
        }
    }
}

function* setPassword(action) {
    try {
        const user = yield call(wpBdpwr.setPassword, action.data);
        yield put(setPasswordSuccess(user));
        yield put(addNotification('setPasswordSuccess'));
        yield put(closeDialog('forgotPassword'));
    } catch (err) {
        yield put(setPasswordError(err));
        yield put(addNotification('setPasswordError'));
    }
}

function* getMedias(action) {
    try {
        const medias = yield call(
            wp.getMedias,
            action.userId,
            action.page,
            action.perPage,
            action.mediaType,
        );
        yield put(getMediasSuccess(medias));
    } catch (err) {
        yield put(getMediasError(err));
    }
}

function* getMoreMedias(action) {
    try {
        const medias = yield call(
            wp.getMedias,
            action.userId,
            action.page,
            action.perPage,
            action.mediaType,
        );
        yield put(getMoreMediasSuccess(medias));
    } catch (err) {
        yield put(getMoreMediasError(err));
    }
}

function* uploadMedia(action) {
    try {
        const file = yield call(wp.uploadMedia, action.formData);
        yield put(uploadMediaSuccess(file));
    } catch (err) {
        yield put(uploadMediaError(err));
        yield put(addNotification('uploadMediaError'));
    }
}

function* updateMedia(action) {
    try {
        const media = yield call(wp.updateMedia, action.media);
        yield put(updateMediaSuccess(media));
    } catch (err) {
        yield put(updateMediaError(err));
        yield put(addNotification('updateMediaError'));
    }
}

function* deleteMedia(action) {
    try {
        const id = yield call(wp.deleteMedia, action.id);
        yield put(deleteMediaSuccess(id));
    } catch (err) {
        yield put(deleteMediaError(err));
        yield put(addNotification('deleteMediaError'));
    }
}

export default function* rootSaga() {
    yield all([
        takeLatest(GET_EVENT, getEvent),
        takeLatest(GET_EVENTS, getEvents),
        takeLatest(GET_VENUE, getVenue),
        takeLatest(GET_VENUES, getVenues),
        takeLatest(GET_ORGANIZER, getOrganizer),
        takeLatest(GET_ORGANIZERS, getOrganizers),
        takeLatest(POST_EVENT, postEvent),
        takeLatest(UPLOAD_IMAGE, uploadMedia),
        takeLatest(POST_VENUE, postVenue),
        takeLatest(POST_ORGANIZER, postOrganizer),
        takeLatest(DELETE_EVENT, deleteEvent),
        takeLatest(DELETE_VENUE, deleteVenue),
        takeLatest(GET_CATEGORIES, getCategories),
        takeLatest(GET_TAGS, getTags),
        takeLatest(NEW_USER, newUser),
        takeLatest(GET_USER, getUser),
        takeLatest(GET_USERS, getUsers),
        takeLatest(LOGIN, login),
        takeLatest(LOGOUT, logout),
        takeLatest(RESET_PASSWORD, resetPassword),
        takeLatest(SET_PASSWORD, setPassword),
        takeLatest(GET_MEDIAS, getMedias),
        takeLatest(GET_MORE_MEDIAS, getMoreMedias),
        takeLatest(UPDATE_MEDIA, updateMedia),
        takeLatest(DELETE_MEDIA, deleteMedia),
    ]);
}
