import React from 'react';
import { Typography } from '@mui/material';
import WriteHtml from '../../../common/WriteHtml';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    description: {
        marginTop: theme.spacing(3),
    },
}));

function EventDescription(props) {
    const classes = useStyles();
    const { description } = props;

    return description ? (
        <Typography
            variant="body2"
            className={classes.description}
            component="div"
        >
            <WriteHtml component="div">{description}</WriteHtml>
        </Typography>
    ) : null;
}

export default EventDescription;
