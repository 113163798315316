import {
    Dialog,
    DialogTitle,
    Fade,
    IconButton,
    Slide,
    Typography,
    useMediaQuery,
} from '@mui/material';

import { Close as CloseIcon } from '@mui/icons-material';
import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '1em',
    },
    titleIcon: {
        marginRight: theme.spacing(2),
    },
    content: {
        overflowX: 'hidden',
    },
}));

function AltDialog({ dialogs, handleClose }) {
    const classes = useStyles();
    const isMediumOrMore = useMediaQuery((theme) => theme.breakpoints.up('md'));

    // fix : Tab key close dialog + lose focus when press D key
    const stopPropagationForTabAndD = (event) => {
        if (event.key === 'Tab' || event.key === 'd') {
            event.stopPropagation();
        }
    };

    return (
        <>
            {dialogs.map((dialog) => (
                <Dialog
                    key={dialog.id}
                    open={true}
                    aria-labelledby="dialog-button"
                    fullScreen={!isMediumOrMore}
                    fullWidth
                    onKeyDown={stopPropagationForTabAndD}
                    TransitionComponent={
                        !isMediumOrMore ? MobileDialogTransition : DefaultDialogTransition
                    }
                    maxWidth="sm"
                    {...dialog.dialogProps}
                >
                    {dialog.title && (
                        <DialogTitle className={classes.title}>
                            {dialog.icon &&
                                React.cloneElement(dialog.icon, { className: classes.titleIcon })}
                            <Typography
                                variant="h5"
                                component="p"
                            >
                                {dialog.title}
                            </Typography>

                            {!dialog.hideCloseButton && (
                                <IconButton
                                    aria-label="Fermer"
                                    className={classes.closeButton}
                                    onClick={() => handleClose(dialog.id)}
                                    size="large"
                                >
                                    <CloseIcon />
                                </IconButton>
                            )}
                        </DialogTitle>
                    )}

                    {React.cloneElement(dialog.component, {
                        callback: dialog.callback,
                        handleClose: () => handleClose(dialog.id),
                    })}
                </Dialog>
            ))}
        </>
    );
}

export default AltDialog;

const MobileDialogTransition = React.forwardRef(function Transition(props, ref) {
    return (
        <Slide
            direction="up"
            ref={ref}
            {...props}
        />
    );
});

const DefaultDialogTransition = React.forwardRef(function Transition(props, ref) {
    return (
        <Fade
            ref={ref}
            {...props}
        />
    );
});
