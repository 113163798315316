import Helmet from 'react-helmet';
import React from 'react';
import { decodeHtmlEntities } from '../../utils';

const META_TAGS = {
    default: {
        title: 'Agenda alternatif du Périgord vert',
        description:
            'Agenda alternatif du Périgord vert (Dordogne) : agenda des événements associatifs, culturels, artistiques et festifs',
        keywords:
            'agenda dordogne, événements périgord, agenda culturel alternatif, événements asociatifs',
        image: '',
    },
    agenda: {
        title: 'Agenda alternatif du Périgord vert',
        description:
            'Agenda alternatif du Périgord vert (Dordogne) : agenda des événements associatifs, culturels, artistiques et festifs',
        keywords:
            'agenda dordogne, événements périgord, agenda culturel alternatif, événements asociatifs',
        image: '',
    },
    calendar: {
        title: 'Calendrier des événements alternatifs du Périgord vert',
        description:
            'Agenda alternatif du Périgord vert (Dordogne) : agenda des événements associatifs, culturels, artistiques et festifs',
        keywords:
            'agenda dordogne, événements périgord, agenda culturel alternatif, événements asociatifs',
        image: '',
    },
    map: {
        title: 'Carte des événements alternatifs du Périgord vert',
        description: 'Carte des événements et des lieux alternatifs du Périgord vert',
        keywords:
            'agenda dordogne, événements périgord, agenda culturel alternatif, événements asociatifs',
        image: '',
    },
    venues: {
        title: 'Liste des lieux alternatifs du Périgord vert',
        description:
            "Liste des lieux alternatifs ajoutés sur CanalPV, l'agenda alternatif du Périgord vert",
        keywords:
            'agenda dordogne, événements périgord, agenda culturel alternatif, événements asociatifs',
        image: '',
    },
    organizers: {
        title: 'Liste des organisateurs',
        description:
            "Liste des organisateurs inscrits sur CanalPV, l'agenda alternatif du Périgord vert",
        keywords:
            'agenda dordogne, événements périgord, agenda culturel alternatif, événements asociatifs',
        image: '',
    },
    myEvents: {
        title: 'Mes événements',
    },
    myVenues: {
        title: 'Mes lieux',
    },
};

function AltMetaTags({ metaTagsId, title, description, keywords, image }) {
    const tags = metaTagsId ? META_TAGS[metaTagsId] : { title, description, keywords, image };

    const titleWithSufix = tags.title ? `${decodeHtmlEntities(tags.title)} - CanalPV` : null;

    return (
        <Helmet>
            {titleWithSufix && <title>{titleWithSufix}</title>}
            {tags.description && (
                <meta
                    name="description"
                    content={tags.description}
                />
            )}
            {tags.keywords && (
                <meta
                    name="keywords"
                    content={tags.keywords}
                />
            )}
            {titleWithSufix && (
                <meta
                    property="og:title"
                    content={titleWithSufix}
                />
            )}
            {tags.image && (
                <meta
                    property="og:image"
                    content={tags.image}
                />
            )}
        </Helmet>
    );
}

export default AltMetaTags;
