import AltLink from './AltLink';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import React from 'react';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    toolbar: {
        flexDirection: 'row-reverse',
    },
    divider: {
        margin: theme.spacing(3, 0, 2),
    },
    component: {
        padding: theme.spacing(0, 2),
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return (
        <Slide
            direction="up"
            ref={ref}
            {...props}
        />
    );
});

function MobileDialogListItem(props) {
    const { el, handleClose } = props;

    const handleClick = (el) => {
        if (!el.noClose) {
            handleClose();
        }
        if (el.onClick) {
            el.onClick();
        }
    };

    return (
        <ListItem
            button={el.isButton}
            onClick={() => handleClick(el)}
        >
            {el.icon && (
                <ListItemIcon>
                    {React.cloneElement(el.icon, {
                        fontSize: 'small',
                        color: el.isLink || el.isButton ? 'secondary' : 'inherit',
                    })}
                </ListItemIcon>
            )}
            <ListItemText
                inset={!el.icon}
                primary={el.primaryLabel}
                secondary={el.secondaryLabel}
                primaryTypographyProps={{
                    color: el.isLink || el.isButton ? 'secondary' : 'inherit',
                    variant: 'h3',
                    fontWeight: 500,
                }}
            />
        </ListItem>
    );
}

export default function MobileDialog(props) {
    const classes = useStyles();
    const { children, component, lists } = props;
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            {React.cloneElement(children, {
                onClick: handleClickOpen,
            })}

            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar className={classes.appBar}>
                    <Toolbar className={classes.toolbar}>
                        <IconButton
                            color="inherit"
                            onClick={handleClose}
                            aria-label="Fermer"
                            size="large"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <List>
                    {lists.map((list, i) => (
                        <div key={`list-${i}`}>
                            {list.map((el, j) => {
                                if (!el.isDivider) {
                                    return !el.hideItem ? (
                                        el.isLink && el.to ? (
                                            <AltLink
                                                to={el.to}
                                                key={`item-${j}`}
                                            >
                                                <MobileDialogListItem
                                                    el={el}
                                                    handleClose={handleClose}
                                                />
                                            </AltLink>
                                        ) : (
                                            <MobileDialogListItem
                                                key={`item-${j}`}
                                                el={el}
                                                handleClose={handleClose}
                                            />
                                        )
                                    ) : null;
                                }
                                return el.isDivider ? (
                                    <Divider
                                        key={`item-${j}`}
                                        component="li"
                                        className={classes.divider}
                                    />
                                ) : (
                                    ''
                                );
                            })}
                            {i < lists.length - 1 && (
                                <Divider
                                    component="li"
                                    className={classes.divider}
                                />
                            )}
                        </div>
                    ))}
                </List>
                {component && (
                    <Box
                        component="section"
                        className={classes.component}
                    >
                        {React.cloneElement(component, {})}
                    </Box>
                )}
            </Dialog>
        </>
    );
}
