export const GET_ORGANIZER = 'GET_ORGANIZER';
export const GET_ORGANIZER_ERROR = 'GET_ORGANIZER_ERROR';
export const GET_ORGANIZER_SUCCESS = 'GET_ORGANIZER_SUCCESS';
export const GET_ORGANIZERS = 'GET_ORGANIZERS';
export const GET_ORGANIZERS_SUCCESS = 'GET_ORGANIZERS_SUCCESS';
export const GET_ORGANIZERS_ERROR = 'GET_ORGANIZERS_ERROR';
export const POST_ORGANIZER = 'POST_ORGANIZER';
export const POST_ORGANIZER_SUCCESS = 'POST_ORGANIZER_SUCCESS';
export const POST_ORGANIZER_ERROR = 'POST_ORGANIZER_ERROR';

export const getOrganizer = (id) => ({
    type: GET_ORGANIZER,
    id,
});

export const getOrganizerSuccess = (organizer) => ({
    type: GET_ORGANIZER_SUCCESS,
    organizer,
});

export const getOrganizerError = (err) => ({
    type: GET_ORGANIZER_ERROR,
    err,
});

export const getOrganizers = () => ({
    type: GET_ORGANIZERS,
});

export const getOrganizersSuccess = (organizers) => ({
    type: GET_ORGANIZERS_SUCCESS,
    organizers,
});

export const getOrganizersError = (err) => ({
    type: GET_ORGANIZERS_ERROR,
    err,
});

export const postOrganizer = (organizer, callback) => ({
    type: POST_ORGANIZER,
    organizer,
    callback,
});

export const postOrganizerSuccess = (organizer) => ({
    type: POST_ORGANIZER_SUCCESS,
    organizer,
});

export const postOrganizerError = (err) => ({
    type: POST_ORGANIZER_ERROR,
    err,
});
