import { WP_TRIBE_API_BASE_URL } from '../../api/wordpress/wpTribeEvents';
import { WP_TRIBE_EXPORT_ICAL_PARAMS } from '../../constants';
import moment from 'moment';

/*
---------- getEventExportIcalUrl ----------
*/
export function getEventExportIcalUrl(slug) {
    return `${WP_TRIBE_API_BASE_URL}${slug}/?${WP_TRIBE_EXPORT_ICAL_PARAMS}`;
}

/*
---------- getCostLabel ----------
*/

export function getCostLabel(cost, symbol, position) {
    if (!symbol) return `${cost}€`;
    return !position || position === 'postfix' ? `${cost} ${symbol}` : `${symbol} ${cost}`;
}

/*
---------- formatEventDatesToLabel ----------
*/

export function formatEventDatesToLabel(start, end, allday) {
    if (!start) return null;

    const isOneDay = !end || moment(start).format(DATE_FORMAT) === moment(end).format(DATE_FORMAT);

    let startTimeFormat = TIME_START_FORMAT_LABEL,
        endTimeFormat = TIME_END_FORMAT_LABEL,
        endTimeSeparator = ', ';

    // all day event : don't display time, use label
    if (allday) {
        startTimeFormat = ALL_DAY_FORMAT_LABEL; // '[toute la journée]'
        endTimeFormat = !isOneDay ? ALL_DAY_FORMAT_LABEL : ''; // hide second all day label for oneDay event (not good : 'samedi 22 février 2020, toute la journée toute la journée')
        endTimeSeparator = DISPLAY_ALL_DAY_LABEL ? endTimeSeparator : ''; // hide comma when all day label is not displaying (not good : 'samedi 22 février 2020,')
    }

    const startLabelFormat = isOneDay
        ? // one day event : hide prefix ('du') + display year
          `${DATE_FORMAT} ${YEAR_FORMAT}${endTimeSeparator}${startTimeFormat}` // '[samedi 22 février] [2020], [à 19h30 / toute la journée]'
        : // multiple days event : add prefix ('du') + hide year in start format (when start and end year are the same)
          `[${DATE_START_PREFIX}] ${DATE_FORMAT}${endTimeSeparator}${endTimeFormat}`; //'[du] [samedi 22 février][, ][à 19h30 / toute la journée]'

    const endLabelFormat = !isOneDay
        ? `[${DATE_END_PREFIX}] ${DATE_FORMAT} ${YEAR_FORMAT}${endTimeSeparator}${endTimeFormat}` // '[au] [dimanche 23 février] [2020][, /][à 02h00 / toute la journée]'
        : `${endTimeFormat}`; // ' [à 23h00 /]'

    const startLabel = moment(start).format(startLabelFormat);
    const endLabel = end ? moment(end).format(endLabelFormat) : '';

    return `${startLabel}${endLabel ? ' ' : ''}${endLabel}`;
}

/* Date conf */
const DISPLAY_ALL_DAY_LABEL = false;

/* Date wording */
const ALL_DAY = 'toute la journée';
const TIME_START_PREFIX = 'de';
const TIME_END_PREFIX = 'à';
const TIME_SEPARATOR = 'h';
const DATE_START_PREFIX = 'du';
const DATE_END_PREFIX = 'au';

/* Date formats */
const DATE_FORMAT = 'dddd DD MMMM'; // 'samedi 22 février'
const YEAR_FORMAT = 'YYYY'; // '2020'
const TIME_FORMAT = `HH[${TIME_SEPARATOR}]mm`; // '19h30'

/* Date formats with wording */
const TIME_START_FORMAT_LABEL = `[${TIME_START_PREFIX}] ${TIME_FORMAT}`; // 'de 19h30'
const TIME_END_FORMAT_LABEL = `[${TIME_END_PREFIX}] ${TIME_FORMAT}`; // 'à 02h00'
const ALL_DAY_FORMAT_LABEL = DISPLAY_ALL_DAY_LABEL ? `[${ALL_DAY}]` : ''; // 'toute la journée'

/*
---------- Filters ----------
*/

const filterByCategories = (events, categories) =>
    events.filter((ev) =>
        categories.length > 0
            ? ev.categories.filter((cat) => categories.indexOf(cat.id) > -1).length > 0
            : true,
    );

const filterByTags = (events, tags) =>
    events.filter((ev) =>
        tags.length > 0 ? ev.tags.filter((tag) => tags.indexOf(tag.id) > -1).length > 0 : true,
    );

const filterByOrganizers = (events, organizers) =>
    events.filter((ev) => (organizers.length > 0 ? organizers.includes(ev.organizer[0].id) : true));

const filterByVenues = (events, venues) =>
    events.filter((ev) => (venues.length > 0 ? venues.indexOf(ev.venue.id) > -1 : true));

export const filterEvents = (events, filters) => {
    let filteredEvents = events;
    if (filters.categories.length > 0) {
        filteredEvents = [...filterByCategories(filteredEvents, filters.categories)];
    }
    if (filters.tags.length > 0) {
        filteredEvents = [...filterByTags(filteredEvents, filters.tags)];
    }
    if (filters.organizers.length > 0) {
        filteredEvents = [...filterByOrganizers(filteredEvents, filters.organizers)];
    }
    if (filters.venues.length > 0) {
        filteredEvents = [...filterByVenues(filteredEvents, filters.venues)];
    }
    return filteredEvents;
};

/*
---------- Others ----------
*/

export const CANCELED_PREFIX = '[Annulé]';

export const eventIsCanceled = (title) => title.includes(CANCELED_PREFIX);

export const PRIVATE_PREFIX = '(Privé) ';
