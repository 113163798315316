import AltList from '../../common/AltList';
import EventCategories from './EventCategories/EventCategories';
import EventCost from './EventCost/EventCost';
import EventDate from './EventDate/EventDate';
import EventDescription from './EventDescription/EventDescription';
import EventOrganizer from './EventOrganizer/EventOrganizer';
import EventTags from './EventTags/EventTags';
import EventVenue from './EventVenue/EventVenue';
import EventWebsite from './EventWebsite/EventWebsite';
import React from 'react';
import { useMediaQuery } from '@mui/material';

const EventInfos = ({
    event,
    event: {
        allDay,
        categories,
        cost,
        costDetails,
        description,
        end,
        organizer,
        start,
        tags,
        venue,
        website,
    },
    dense,
    divider,
    hideCategories,
    hideLocation,
    hideOrganizer,
    hideWebsite,
    showDescription,
    showPrice,
    showTags,
}) => {
    const isMediumOrMore = useMediaQuery((theme) => theme.breakpoints.up('md'));

    return event ? (
        <>
            <AltList
                dense={dense}
                divider={divider}
            >
                <EventDate
                    allday={allDay}
                    start={start}
                    end={end}
                />

                {!hideLocation && <EventVenue venue={venue} />}

                {!hideOrganizer && (
                    <EventOrganizer
                        organizer={organizer[0]}
                        showLabel={isMediumOrMore}
                        withLink
                    />
                )}

                {showPrice && (
                    <EventCost
                        cost={cost}
                        costDetails={costDetails}
                    />
                )}

                {!hideCategories && <EventCategories categories={categories} />}

                {showTags && <EventTags tags={tags} />}

                {!hideWebsite && <EventWebsite website={website} />}
            </AltList>

            {showDescription && <EventDescription description={description} />}
        </>
    ) : null;
};

export default EventInfos;
