import { makeStyles, withStyles } from '@mui/styles';

import React from 'react';
import { TextField } from '@mui/material';
import { green } from '@mui/material/colors';

const useStyles = makeStyles((_theme) => ({
    optionalTextField: {
        '& input:valid ~ fieldset, & .Mui-focused input:valid[aria-invalid=false] ~ fieldset, & .MuiOutlinedInput-root:hover input:valid[aria-invalid=false] ~ fieldset':
            {
                borderColor: green[600],
            },
    },
}));

const textFieldProps = {
    fullWidth: true,
    margin: 'normal',
    variant: 'outlined',
};

const ValidationTextField = withStyles({
    root: {
        '& input:valid ~ fieldset, & .Mui-focused input[aria-invalid="false"] ~ fieldset, & .MuiOutlinedInput-root:hover input[aria-invalid="false"] ~ fieldset':
            {
                borderColor: green[600],
            },
        '& textarea:valid ~ fieldset, & .Mui-focused textarea:valid ~ fieldset, & .MuiOutlinedInput-root:hover textarea:valid ~ fieldset':
            {
                borderColor: green[600],
            },
    },
})(TextField);

function AltTextField({ required, ...rest }) {
    const classes = useStyles();

    return required ? (
        <ValidationTextField
            {...textFieldProps}
            {...rest}
            required
        />
    ) : (
        <TextField
            {...textFieldProps}
            {...rest}
            className={rest.value?.length > 0 && !rest.error ? classes.optionalTextField : ''}
        />
    );
}

export default AltTextField;
