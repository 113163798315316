import { DateTimePicker } from '@mui/x-date-pickers';
import React from 'react';
import { green } from '@mui/material/colors';
import { withStyles } from '@mui/styles';

const datetimeProps = {
    ampm: false,
    disableToolbar: true,
    format: 'EEEE d MMMM yyyy, à H:mm',
    inputVariant: 'outlined',
    margin: 'normal',
    minutesStep: 5,
    variant: 'inline',
    slotProps: {
        textField: {
            fullWidth: true,
        },
    },
};

const ValidationDateTimePicker = withStyles({
    root: {
        '& input:valid ~ fieldset, & .MuiOutlinedInput-root.Mui-focused input:valid[aria-invalid=false] ~ fieldset, & .MuiOutlinedInput-root:hover input:valid[aria-invalid=false] ~ fieldset':
            {
                borderColor: green[600],
            },
    },
})(DateTimePicker);

function AltDateTimePicker(props) {
    return (
        <ValidationDateTimePicker
            {...datetimeProps}
            {...props}
        />
    );
}

export default AltDateTimePicker;
