export const GET_MEDIAS = 'GET_MEDIAS';
export const GET_MEDIAS_SUCCESS = 'GET_MEDIAS_SUCCESS';
export const GET_MEDIAS_ERROR = 'GET_MEDIAS_ERROR';
export const GET_MORE_MEDIAS = 'GET_MORE_MEDIAS';
export const GET_MORE_MEDIAS_SUCCESS = 'GET_MORE_MEDIAS_SUCCESS';
export const GET_MORE_MEDIAS_ERROR = 'GET_MORE_MEDIAS_ERROR';
export const UPLOAD_IMAGE = 'UPLOAD_IMAGE';
export const UPLOAD_IMAGE_ERROR = 'UPLOAD_IMAGE_ERROR';
export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS';
export const UPDATE_MEDIA = 'UPDATE_MEDIA';
export const UPDATE_MEDIA_ERROR = 'UPDATE_MEDIA_ERROR';
export const UPDATE_MEDIA_SUCCESS = 'UPDATE_MEDIA_SUCCESS';
export const DELETE_MEDIA = 'DELETE_MEDIA';
export const DELETE_MEDIA_ERROR = 'DELETE_MEDIA_ERROR';
export const DELETE_MEDIA_SUCCESS = 'DELETE_MEDIA_SUCCESS';

export const getMedias = (userId, page, perPage, mediaType) => ({
    type: GET_MEDIAS,
    userId,
    page,
    perPage,
    mediaType,
});

export const getMediasSuccess = (medias) => ({
    type: GET_MEDIAS_SUCCESS,
    medias,
});

export const getMediasError = (err) => ({
    type: GET_MEDIAS_ERROR,
    err,
});

export const getMoreMedias = (userId, page, perPage, mediaType) => ({
    type: GET_MORE_MEDIAS,
    userId,
    page,
    perPage,
    mediaType,
});

export const getMoreMediasSuccess = (medias) => ({
    type: GET_MORE_MEDIAS_SUCCESS,
    medias,
});

export const getMoreMediasError = (err) => ({
    type: GET_MORE_MEDIAS_ERROR,
    err,
});

export const uploadMedia = (formData) => ({
    type: UPLOAD_IMAGE,
    formData,
});

export const uploadMediaSuccess = (file) => ({
    type: UPLOAD_IMAGE_SUCCESS,
    file,
});

export const uploadMediaError = (err) => ({
    type: UPLOAD_IMAGE_ERROR,
    err,
});

export const updateMedia = (media) => ({
    type: UPDATE_MEDIA,
    media,
});

export const updateMediaSuccess = (media) => ({
    type: UPDATE_MEDIA_SUCCESS,
    media,
});

export const updateMediaError = (err) => ({
    type: UPDATE_MEDIA_ERROR,
    err,
});

export const deleteMedia = (id) => ({
    type: DELETE_MEDIA,
    id,
});

export const deleteMediaSuccess = (id) => ({
    type: DELETE_MEDIA_SUCCESS,
    id,
});

export const deleteMediaError = (err) => ({
    type: DELETE_MEDIA_ERROR,
    err,
});
