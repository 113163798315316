import { green, orange, red } from '@mui/material/colors';

import { Button } from '@mui/material';
import React from 'react';
import classnames from 'classnames';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    contribButton: {
        backgroundColor: theme.palette.contrib.main,
        color: theme.palette.contrib.contrastText,
        '&:hover': {
            backgroundColor: theme.palette.contrib.dark,
        },
    },
    confirmButton: {
        borderColor: green[800],
        color: green[800],
        '&:hover': {
            borderColor: green[800],
            backgroundColor: green[800],
            color: '#ffffff',
        },
    },
    warningButton: {
        borderColor: orange[800],
        color: orange[800],
        '&:hover': {
            backgroundColor: orange[800],
            color: '#ffffff',
        },
    },
    deleteButton: {
        borderColor: red[800],
        color: red[800],
        '&:hover': {
            backgroundColor: red[800],
            color: '#ffffff',
        },
    },
    buttonLowerCase: {
        textTransform: 'none',
    },
    buttonMarginDense: {
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    buttonMarginNormal: {
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    buttonMarginLarge: {
        marginBottom: theme.spacing(3),
        marginRight: theme.spacing(3),
    },
}));

function AltButton({ children, lowerCase, margin = 'none', variant = 'default', ...rest }) {
    const classes = useStyles();

    const buttonVariant =
        variant === 'confirm' || variant === 'delete' || variant === 'warning'
            ? 'outlined'
            : 'contained';

    return (
        <Button
            variant={buttonVariant}
            color="secondary"
            className={classnames(
                { [classes.confirmButton]: variant === 'confirm' },
                { [classes.contribButton]: variant === 'contrib' },
                { [classes.deleteButton]: variant === 'delete' },
                { [classes.warningButton]: variant === 'warning' },
                { [classes.buttonLowerCase]: !!lowerCase },
                { [classes.buttonMarginDense]: margin === 'dense' },
                { [classes.buttonMarginNormal]: margin === 'normal' },
                { [classes.buttonMarginLarge]: margin === 'large' },
            )}
            {...rest}
        >
            {children}
        </Button>
    );
}

export default AltButton;
