import { Calendar, momentLocalizer } from 'react-big-calendar';

import AltCalendarDialog from '../../AltCalendar/AltCalendarDialog/AltCalendarDialog';
import { Box } from '@mui/material';
import EventInfos from '../../Events/EventInfos/EventInfos';
import Loader from '../../common/Loader';
import { ROUTE_PATH_DAYS_EVENTS } from '../../../AppRoutes';
import React from 'react';
import { makeStyles } from '@mui/styles';
import moment from 'moment';

const localizer = momentLocalizer(moment);

var frMessages = {
    date: 'date',
    time: 'heure',
    event: 'événement',
    allDay: 'journée entière',
    week: 'semaine',
    work_week: 'semaine ouvrée',
    day: 'jour',
    month: 'mois',
    previous: '<<',
    next: '>>',
    yesterday: 'hier',
    tomorrow: 'demain',
    today: 'Ce mois-ci',
    agenda: 'agenda',
    noEventsInRange: 'Aucun événement pour cette période',
    showMore: (total) => '+' + total + ' de plus',
};

// widget specific
const formats = {
    weekdayFormat: 'dd',
};

// generic
const useStyles = makeStyles((theme) => ({
    fetchingCalendar: {
        opacity: 0.4,
    },
    loader: {
        position: 'absolute',
        top: 'calc(50% - 20px)',
        left: 'calc(50% - 20px)',
    },
    rbcDay: {
        backgroundColor: theme.palette.background.paper,
    },
    rbcEvent: {
        background: theme.palette.secondary.main,
        fontSize: '0',
        borderRadius: 0,

        '&:hover': {
            background: theme.palette.secondary.dark,
        },
        '&:focus': {
            background: theme.palette.secondary.dark,
        },
        '&:active': {
            outline: 0,
        },
    },
}));

function AltCalendar(props) {
    const classes = useStyles();
    const { events, isLoading } = props;

    const [isOpened, setIsOpened] = React.useState(false);
    const [eventData, setEventData] = React.useState(null);

    const handleClickOpen = (eventData) => {
        if (!eventData) {
            return null;
        }
        setEventData(eventData);
        setIsOpened(true);
    };

    const handleClose = () => {
        setIsOpened(false);
        setEventData(null);
    };

    const handleClickDay = (date) =>
        (window.location.href = `/#${ROUTE_PATH_DAYS_EVENTS}/${moment(date).format('YYYY-MM-DD')}`);

    return (
        <Box
            component="section"
            className="widget-calendar"
        >
            <Calendar
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 360 }}
                messages={frMessages}
                culture="fr"
                showMultiDayTimes
                onSelectEvent={(eventData) => handleClickOpen(eventData)}
                views={['month']}
                formats={formats}
                className={isLoading ? classes.fetchingCalendar : ''}
                dayPropGetter={(_day) => ({ className: classes.rbcDay })}
                eventPropGetter={(_event, _start, _end, _isSelected) => ({
                    className: classes.rbcEvent,
                })}
                slotPropGetter={(_event) => ({ className: classes.rbcSlot })}
                onSelectSlot={(date) => handleClickDay(date)}
                onDrillDown={(date) => handleClickDay(date)}
                selectable
            />

            {isLoading && (
                <Box className={classes.loader}>
                    <Loader margin="none" />
                </Box>
            )}

            {eventData && (
                <AltCalendarDialog
                    event={eventData}
                    handleClose={handleClose}
                    isOpened={isOpened}
                >
                    <EventInfos event={eventData} />
                </AltCalendarDialog>
            )}
        </Box>
    );
}

export default AltCalendar;
