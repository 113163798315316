import 'moment/locale/fr';

import { PRIVATE_PREFIX } from '../../components/Events/Events.utils';
import { WORDPRESS_URL } from '../../constants';
import { WP_API_BASE_URL } from './wp';
import axios from '../axiosConf';
import { getCookiesUser } from '../../providers/AuthenticationProvider/AuthenticationProvider';
import { getJsonOsmPosition } from '../nominatim/osm';
import { getVenuesSuccess } from '../../components/Venues/VenuesActions';
import moment from 'moment';

const WP_TRIBE_API_BASE_PATH = 'tribe/events/v1/';
const WP_TRIBE_API_EVENTS = 'events';
const WP_TRIBE_API_VENUES = 'venues';
const WP_TRIBE_API_ORGANIZERS = 'organizers';
const WP_TRIBE_API_CATEGORIES = 'categories';
const WP_TRIBE_API_TAGS = 'tags';
const WP_TRIBE_API_FORMAT_DATE = 'YYYY-MM-DD HH:mm:ss';

export const WP_TRIBE_API_BASE_URL = `${WP_API_BASE_URL}${WP_TRIBE_API_BASE_PATH}`;

const WpTribeAPI = axios.create({
    baseURL: `${WP_TRIBE_API_BASE_URL}`,
});

WpTribeAPI.interceptors.request.use((config) => {
    if (!config.headers.Authorization) {
        const { token } = getCookiesUser();
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
    }
    return config;
});

/* ---------- Events ---------- */

const getEvent = (id, isLogged = false) => {
    return typeof id === 'number'
        ? WpTribeAPI.get(`${WP_TRIBE_API_EVENTS}/${id}`).then((response) =>
              !response.data.featured || isLogged
                  ? mapEvent(response.data) // featured = isPrivate
                  : null,
          )
        : getEventBySlug(id);
};

const getEventBySlug = (slug, isLogged = false) =>
    WpTribeAPI.get(`${WP_TRIBE_API_EVENTS}/by-slug/${slug}`).then((response) =>
        !response.data.featured || isLogged
            ? mapEvent(response.data) // featured = isPrivate
            : null,
    );

const EVENTS_DEFAULT_LIMIT = 1000;
const EVENTS_DEFAULT_STATUS = 'publish';
const EVENTS_DEFAULT_YEARS = 3;

const getEvents = (
    isLogged = false,
    page = 1,
    limit = EVENTS_DEFAULT_LIMIT,
    startDate,
    endDate,
    status = EVENTS_DEFAULT_STATUS,
) => {
    const s =
        startDate || moment().add(-EVENTS_DEFAULT_YEARS, 'years').format(WP_TRIBE_API_FORMAT_DATE);
    const e =
        endDate || moment().add(EVENTS_DEFAULT_YEARS, 'years').format(WP_TRIBE_API_FORMAT_DATE);

    const filterPrivatesEvents = isLogged ? '' : '&featured=false';

    const params = `page=${page}&per_page=${limit}&start_date=${s}&end_date=${e}&status=${status}${filterPrivatesEvents}`;

    return WpTribeAPI.get(`${WP_TRIBE_API_EVENTS}?${params}`).then((response) => {
        const mappedEvents = mapEvents(response.data.events);
        getVenuesSuccess(mappedEvents.venues);
        return mappedEvents.list;
    });
};

const mapEvents = (events) => {
    const mappedEvents = [];
    const venues = [];
    events.forEach((event) => {
        mappedEvents.push(mapEvent(event));
        venues.push(event.venue);
    });
    return {
        list: mappedEvents,
        venues,
    };
};

const mapEvent = (event) => {
    const privatePrefix = event.featured ? PRIVATE_PREFIX : '';
    return {
        ...event,
        venue: mapVenue(event.venue),
        website: event.website.indexOf(WORDPRESS_URL) === -1 ? event.website : undefined, // remove default website
        isPrivate: event.featured,
        // react-big-calendar properties
        title: `${privatePrefix}${event.title} - ${event.venue.city}`,
        name: `${privatePrefix}${event.title}`, // rename property
        start: moment(event.startDate).toDate(),
        end: moment(event.endDate).toDate(),
    };
};

const postEvent = (event) => {
    return WpTribeAPI.post(`${WP_TRIBE_API_EVENTS}/${event.id || ''}`, event).then((response) =>
        mapEvent(response.data),
    );
};

const deleteEvent = (id) => {
    return WpTribeAPI.delete(`${WP_TRIBE_API_EVENTS}/${id}`).then((response) => response.data);
};

/* ---------- Venues ---------- */

const getVenue = (id, isLogged = false) =>
    WpTribeAPI.get(`${WP_TRIBE_API_VENUES}/${id}`).then((response) =>
        response.data.showMap || isLogged
            ? mapVenue(response.data) // showMap = isPublic / !isPrivate
            : null,
    );

const getVenues = (isLogged) => {
    const defaultParams = 'page=1&per_page=1000';
    return WpTribeAPI.get(`${WP_TRIBE_API_VENUES}?${defaultParams}`).then((response) =>
        mapVenues(
            isLogged
                ? response.data.venues
                : response.data.venues.filter((venue) => venue.showMap === true),
        ),
    );
};

const mapVenues = (venues) => {
    const arr = [];
    venues.forEach((venue) => {
        arr.push(mapVenue(venue));
    });
    return arr;
};

const mapVenue = (venue) => {
    const privatePrefix = !venue.showMap ? PRIVATE_PREFIX : '';
    return {
        ...venue,
        venue: `${privatePrefix}${venue.venue}`,
        isPrivate: !venue.showMap,
        osm: getJsonOsmPosition(venue),
    };
};

const postVenue = (venue) => {
    return WpTribeAPI.post(`${WP_TRIBE_API_VENUES}/${venue.id || ''}`, venue).then((response) =>
        mapVenue(response.data),
    );
};

const deleteVenue = (id) => {
    return WpTribeAPI.delete(`${WP_TRIBE_API_VENUES}/${id}`).then((response) => response.data);
};

/* ---------- Organizers ---------- */

const getOrganizer = (id) =>
    WpTribeAPI.get(`${WP_TRIBE_API_ORGANIZERS}/${id}`).then((response) => response.data);

const getOrganizers = () => {
    const defaultParams = 'page=1&per_page=1000';
    return WpTribeAPI.get(`${WP_TRIBE_API_ORGANIZERS}?${defaultParams}`).then(
        (response) => response.data.organizers,
    );
};

const postOrganizer = (organizer) => {
    return WpTribeAPI.post(`${WP_TRIBE_API_ORGANIZERS}/${organizer.id || ''}`, organizer).then(
        (response) => response.data,
    );
};

/* ---------- Categories ---------- */

const getCategories = () => {
    const defaultParams = 'page=1&per_page=1000&hide_empty=false';
    return WpTribeAPI.get(`${WP_TRIBE_API_CATEGORIES}?${defaultParams}`).then(
        (response) => response.data.categories,
    );
};

/* ---------- Tags ---------- */

const getTags = () => {
    const defaultParams = 'page=1&per_page=1000&hide_empty=false';
    return WpTribeAPI.get(`${WP_TRIBE_API_TAGS}?${defaultParams}`).then(
        (response) => response.data.tags,
    );
};

export default {
    getEvent,
    getEvents,
    postEvent,
    deleteEvent,
    getVenue,
    getVenues,
    postVenue,
    deleteVenue,
    getOrganizer,
    getOrganizers,
    postOrganizer,
    getCategories,
    getTags,
};
