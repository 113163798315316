import AltWidget from '../../common/AltWidget';
import { Box } from '@mui/material';
import EventButtonExport from '../EventButtons/EventButtonExport';
import React from 'react';
import { Share as ShareIcon } from '@mui/icons-material/';

const EventUser = ({ event: { slug } = {} }) => {
    // const classes = useStyles();
    // const location = useLocation();

    return slug ? (
        <AltWidget
            icon={
                <ShareIcon
                    color="action"
                    fontSize="small"
                />
            }
            title="Partager ou exporter l'événement"
        >
            <Box mt={2}>
                {/* NOT WORKING : use server side rendering
                <AltLink href={`https://www.facebook.com/sharer/sharer.php?u=${APP_URL}/#${location.pathname}`} target={URL_TARGET_BLANK}>
                    <Button variant="outlined" color="secondary" size="small" startIcon={<FacebookIcon />}>
                        Partager sur facebook
                    </Button>
                </AltLink>
                <br />*/}
                <EventButtonExport slug={slug} />
            </Box>
        </AltWidget>
    ) : null;
};

export default EventUser;
