import {
    GET_USER,
    GET_USERS,
    GET_USERS_ERROR,
    GET_USERS_SUCCESS,
    GET_USER_ERROR,
    GET_USER_SUCCESS,
    NEW_USER,
    NEW_USER_ERROR,
    NEW_USER_SUCCESS,
    RESET_USER,
    SET_USER,
    UPDATE_USER,
} from './UserActions';

const initialState = {
    errors: [],
    isFetchingNewUser: false,
    isFetchingPostUser: false,
    isFetchingUser: false,
    isFetchingUsers: false,
    user: {
        isLogged: false,
        id: null,
        name: null,
        email: null,
        logo: null,
        roles: [],
    },
    list: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case NEW_USER:
            return {
                ...state,
                isFetchingNewUser: true,
            };
        case NEW_USER_SUCCESS:
            return {
                ...state,
                list: [...state.list, action.user],
                isFetchingNewUser: false,
            };
        case NEW_USER_ERROR:
            return {
                ...state,
                isFetchingNewUser: false,
            };
        case GET_USER:
            return {
                ...state,
                isFetchingUser: true,
            };
        case GET_USER_SUCCESS:
            return {
                ...state,
                user: {
                    name: action.user.name,
                    email: action.user.email,
                    id: action.user.id,
                    logo: action.user.logo,
                    roles: action.user.roles,
                    isLogged: true,
                },
                isFetchingUser: false,
            };
        case GET_USER_ERROR:
            return {
                ...state,
                isFetchingUser: false,
            };
        case GET_USERS:
            return {
                ...state,
                isFetchingUsers: true,
            };
        case GET_USERS_SUCCESS:
            return {
                ...state,
                list: action.users,
                isFetchingUsers: false,
            };
        case GET_USERS_ERROR:
            return {
                ...state,
                isFetchingUsers: false,
            };
        case SET_USER:
            return {
                ...state,
                user: {
                    name: action.user.name,
                    email: action.user.email,
                    id: action.user.id,
                    logo: action.user.logo,
                    roles: action.user.roles,
                    isLogged: true,
                },
            };

        case RESET_USER:
            return initialState;

        case UPDATE_USER:
            return {
                ...state,
                user: {
                    ...state.user,
                    name: action.user.name,
                    email: action.user.email,
                    logo: action.user.logo,
                },
            };

        default:
            return state;
    }
};
