import { DialogActions, DialogContent, TextField, Typography } from '@mui/material/';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AltButton from '../../common/AltButton';
import Loader from '../../common/Loader';
import { forgotPasswordDialog } from '../../common/AltDialog/DialogsData';
import { login } from '../../../providers/AuthenticationProvider/AuthenticationProviderActions';
import { makeStyles } from '@mui/styles';
import { openDialog } from '../../common/AltDialog/AltDialogActions';

const styles = (_theme) => ({
    content: {
        overflowX: 'hidden',
    },
    forgotPasswordLink: {
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
});

const useStyles = makeStyles(styles);

function UserLoginForm({ handleClickToggle }) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isValidUsername, setIsValidUsername] = useState(false);
    const [isValidPassword, setIsValidPassword] = useState(false);
    const [isValidForm, setIsValidForm] = useState(false);

    const { isFetching } = useSelector((state) => ({
        isFetching: state.auth.isFetching,
    }));

    const handleChangeUsername = (event) => {
        const { value } = event.target;
        const isValid = value.length > 0;
        setUsername(value);
        setIsValidUsername(isValid);
        validateForm(isValid, isValidPassword);
    };

    const handleChangePassword = (event) => {
        const { value } = event.target || event.currentTarget;
        const isValid = value.length > 0;
        setPassword(value);
        setIsValidPassword(isValid);
        validateForm(isValidUsername, isValid);
    };

    const validateForm = (isValidUsername, isValidPassword) => {
        const isValid = isValidUsername && isValidPassword;
        setIsValidForm(isValid);
        return isValid;
    };

    const handleSubmit = useCallback(() => {
        if (validateForm(username, password)) {
            dispatch(login(username, password));
            if (handleClickToggle) handleClickToggle();
        } else {
            console.log('error');
        }
    }, [handleClickToggle, password, dispatch, username]);

    const handleClickForgotPassword = () => {
        dispatch(openDialog(forgotPasswordDialog));
    };

    useEffect(() => {
        const listener = (event) => {
            if (event.code === 'Enter' || event.code === 'NumpadEnter') {
                handleSubmit();
            }
        };
        document.addEventListener('keydown', listener);
        return () => {
            document.removeEventListener('keydown', listener);
        };
    }, [handleSubmit]);

    return (
        <>
            <DialogContent
                dividers
                className={classes.content}
            >
                <form
                    className={classes.form}
                    noValidate
                    autoComplete="off"
                >
                    <TextField
                        label="Identifiant ou adresse e-mail"
                        value={username}
                        onChange={handleChangeUsername}
                        fullWidth
                        type="email"
                        margin="normal"
                        autoComplete="username"
                        variant="outlined"
                    />
                    <TextField
                        label="Mot de passe"
                        value={password}
                        onChange={handleChangePassword}
                        fullWidth
                        margin="normal"
                        type="password"
                        autoComplete="current-password"
                        variant="outlined"
                    />
                </form>

                <Typography
                    color="secondary"
                    onClick={handleClickForgotPassword}
                    className={classes.forgotPasswordLink}
                >
                    Mot de passe oublié ?
                </Typography>
            </DialogContent>

            <DialogActions>
                <AltButton
                    variant="contrib"
                    size="large"
                    onClick={handleSubmit}
                    disabled={!isValidForm}
                    type="submit"
                    endIcon={
                        isFetching ? (
                            <Loader
                                size="xxs"
                                margin="none"
                                color="white"
                            />
                        ) : null
                    }
                >
                    Valider
                </AltButton>
            </DialogActions>
        </>
    );
}

export default UserLoginForm;
