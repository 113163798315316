import { Box, Grow, MenuItem, MenuList, Paper, Popper } from '@mui/material/';
import React, { cloneElement, useEffect, useRef, useState } from 'react';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    divider: {
        '&.MuiMenuItem-root': {
            margin: theme.spacing(1.5, 0),
            padding: 0,
            borderBottom: '1px solid #ccc',
        },
    },
}));

function ToggleMenu({ children, className, list, align }) {
    const classes = useStyles();

    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleListKeyDown = (event) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    };

    const handleClick = (callback) => {
        handleToggle();
        if (callback) {
            callback();
        }
    };

    return (
        <Box
            display="flex"
            className={className}
        >
            <div>
                {cloneElement(children, {
                    onClick: handleToggle,
                    ref: anchorRef,
                    'aria-controls': open ? 'menu-list-grow' : undefined,
                    'aria-haspopup': 'true',
                })}
                <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                marginTop: '8px',
                                transformOrigin:
                                    placement === 'bottom' ? `${align} top` : `${align} bottom`,
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList
                                        autoFocusItem={open}
                                        id="menu-list-grow"
                                        onKeyDown={handleListKeyDown}
                                    >
                                        {list.map(
                                            (el, i) =>
                                                !el.hide && (
                                                    <MenuItem
                                                        key={`el-${i}`}
                                                        onClick={() =>
                                                            !el.divider
                                                                ? handleClick(el.onClick)
                                                                : null
                                                        }
                                                        className={
                                                            el.divider ? classes.divider : ''
                                                        }
                                                        disabled={el.divider}
                                                    >
                                                        {el.children}
                                                    </MenuItem>
                                                ),
                                        )}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        </Box>
    );
}

export default ToggleMenu;
