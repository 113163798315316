import { Box, Card, CardContent, Grid, Paper, Typography, useMediaQuery } from '@mui/material';

import AltAgendaEvents from '../../AltAgenda/AltAgendaEvents';
import AltButton from '../../common/AltButton';
import { Edit as EditIcon } from '@mui/icons-material';
import Loader from '../../common/Loader';
import OrganizerContacts from '../OrganizerContacts/OrganizerContacts';
import OrganizerLogo from '../../common/OrganizerLogo';
import React from 'react';
import VenuesList from '../../Venues/VenuesList/VenuesList';
import { WP_ROLES_ADMIN } from '../../../constants';
import WriteHtml from '../../common/WriteHtml';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginBottom: theme.spacing(3),
    },
}));

function Organizer({
    events,
    openDialogOrganizer,
    openDialogPostVenue,
    organizer,
    venues,
    isFetchingEvents,
    isFetchingVenues,
    isLoading,
    user,
    users,
}) {
    const classes = useStyles();
    const isLargeOrMore = useMediaQuery((theme) => theme.breakpoints.up('lg'));

    return (
        <>
            {!isLoading && organizer ? (
                <>
                    <Box
                        display={isLargeOrMore ? 'flex' : 'block'}
                        alignItems="center"
                        justifyContent="space-between"
                        mb={2}
                    >
                        <Box
                            display="flex"
                            alignItems="center"
                        >
                            <OrganizerLogo
                                organizer={organizer}
                                users={users}
                                size={75}
                            />
                            <Typography
                                component="h2"
                                variant="h2"
                                color="primary"
                                gutterBottom={!isLargeOrMore}
                                noWrap
                            >
                                <WriteHtml>{organizer.organizer}</WriteHtml>
                            </Typography>
                        </Box>

                        {(organizer.author === user?.id ||
                            user?.roles?.includes(WP_ROLES_ADMIN)) && (
                            <AltButton
                                variant="contrib"
                                onClick={openDialogOrganizer}
                                startIcon={<EditIcon />}
                                lowerCase
                            >
                                Modifier mon profil
                            </AltButton>
                        )}
                    </Box>

                    <Grid
                        container
                        spacing={4}
                    >
                        <Grid
                            item
                            xs={12}
                            md={7}
                        >
                            {organizer.description && (
                                <Paper className={classes.paper}>
                                    <Card
                                        elevation={0}
                                        square
                                    >
                                        <CardContent>
                                            <Typography
                                                variant="body2"
                                                component="div"
                                            >
                                                <WriteHtml component="div">
                                                    {organizer.description}
                                                </WriteHtml>
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Paper>
                            )}

                            <Paper className={classes.paper}>
                                <Card
                                    elevation={0}
                                    square
                                >
                                    <CardContent>
                                        <Typography variant="h3">Coordonnées</Typography>

                                        <OrganizerContacts
                                            organizer={organizer}
                                            displayEmpty
                                            boxProps={{ mt: 2 }}
                                        />
                                    </CardContent>
                                </Card>
                            </Paper>

                            <Paper className={classes.paper}>
                                <Card
                                    elevation={0}
                                    square
                                >
                                    <CardContent>
                                        <Typography variant="h3">
                                            Prochains événements organisés
                                        </Typography>

                                        {events.length > 0 ? (
                                            <AltAgendaEvents
                                                events={events}
                                                isLoading={isFetchingEvents}
                                                detailsDense
                                            />
                                        ) : (
                                            <Box mt={3}>Aucun événement à venir</Box>
                                        )}
                                    </CardContent>
                                </Card>
                            </Paper>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            md={5}
                        >
                            <Paper className={classes.paper}>
                                <Card
                                    elevation={0}
                                    square
                                >
                                    <CardContent>
                                        <Typography
                                            variant="h3"
                                            gutterBottom
                                        >
                                            Lieux ajoutés
                                        </Typography>
                                        <VenuesList
                                            venues={venues}
                                            organizers={[organizer]}
                                            events={events}
                                            isLoading={isFetchingVenues}
                                            isFetchingEvents={isFetchingEvents || isLoading}
                                            user={user}
                                            openDialogPostVenue={openDialogPostVenue}
                                            hideOrganizer
                                        />
                                    </CardContent>
                                </Card>
                            </Paper>
                        </Grid>
                    </Grid>
                </>
            ) : (
                <Loader />
            )}
        </>
    );
}

export default Organizer;
