import AltLink from '../../../common/AltLink';
import AltListItem from '../../../common/AltListItem';
import { Http as HttpIcon } from '@mui/icons-material';
import React from 'react';
import { URL_TARGET_BLANK } from '../../../../constants';

const EventWebsite = ({ website, ...rest }) =>
    website ? (
        <AltListItem
            {...rest}
            icon={<HttpIcon />}
        >
            <AltLink
                href={website}
                title="Visiter la page web de l'événement"
                target={URL_TARGET_BLANK}
            >
                {website}
            </AltLink>
        </AltListItem>
    ) : null;

export default EventWebsite;
