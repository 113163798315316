import { Link, Typography } from '@mui/material/';
import { URL_TARGET_BLANK, URL_TARGET_BLANK_REL, URL_TARGET_DEFAULT } from '../../constants';
import { addOrNotUrlPrefix, removeUrlPrefix } from '../../utils';

import React from 'react';
import { Link as ReactRouterDomLink } from 'react-router-dom';
import { isString } from 'lodash';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    link: {
        cursor: 'pointer',
        textDecoration: 'none',

        '&:hover': {
            textDecoration: 'underline',
        },
    },
}));

function AltLink({
    align,
    ariaLabel,
    children,
    color = 'secondary',
    component = 'span',
    gutterBottom = false,
    href,
    onClick,
    target = URL_TARGET_DEFAULT,
    title,
    to,
    variant = 'body2',
}) {
    const classes = useStyles();

    // add link styles element if children is a string
    const el = isString(children) ? (
        <Typography
            component={component}
            variant={variant}
            color={color}
            align={align}
            gutterBottom={gutterBottom}
            className={classes.link}
        >
            {removeUrlPrefix(children)}
        </Typography>
    ) : (
        children
    );

    // internal link
    if (to) {
        const intLinkProps = {
            to,
            title,
        };
        return (
            <ReactRouterDomLink
                {...intLinkProps}
                aria-label={ariaLabel}
            >
                {el}
            </ReactRouterDomLink>
        );
    }

    // external link
    if (isString(href)) {
        const url = addOrNotUrlPrefix(href);

        // blank link security
        const rel = target === URL_TARGET_BLANK ? URL_TARGET_BLANK_REL : '';

        const extLinkProps = {
            href: url,
            rel,
            title,
        };

        return <Link {...extLinkProps}>{el}</Link>;
    }

    // button link
    if (onClick) {
        return React.cloneElement(el, { onClick });
    }

    // no url
    console.log('[Erreur] AltLink : aucune url passée en props (to ou href)');
    return null;
}

export default AltLink;
