import AltButtonDropdown from '../../common/AltButtonDropdown';
import { ExitToApp as ExitToAppIcon } from '@mui/icons-material/';
import React from 'react';
import { URL_TARGET_BLANK } from '../../../constants';
import { getEventExportIcalUrl } from '../Events.utils';

function EventButtonExport({ slug }) {
    if (!slug) {
        return null;
    }

    const links = [
        {
            href: getEventExportIcalUrl(slug),
            target: URL_TARGET_BLANK,
            label: 'Exporter vers iCal',
        },
    ];

    return (
        <AltButtonDropdown
            color="secondary"
            icon={<ExitToAppIcon />}
            label="Exporter"
            links={links}
        />
    );
}

export default EventButtonExport;
