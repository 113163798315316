import { ListItem, ListItemIcon, Typography } from '@mui/material/';

import React from 'react';

function AltListItem(props) {
    const { children, dense, divider, icon, noWrap = true, variant } = props;
    return (
        <ListItem
            disableGutters={true}
            divider={divider}
            dense={dense}
        >
            {icon && <ListItemIcon>{icon}</ListItemIcon>}
            {typeof children === 'string' ? (
                <Typography
                    variant={variant || 'body2'}
                    noWrap={noWrap}
                >
                    {children}
                </Typography>
            ) : (
                children
            )}
        </ListItem>
    );
}

export default AltListItem;
