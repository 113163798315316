export const GET_EVENT = 'GET_EVENT';
export const GET_EVENT_SUCCESS = 'GET_EVENT_SUCCESS';
export const GET_EVENT_ERROR = 'GET_EVENT_ERROR';
export const GET_EVENTS = 'GET_EVENTS';
export const GET_EVENTS_SUCCESS = 'GET_EVENTS_SUCCESS';
export const GET_EVENTS_ERROR = 'GET_EVENTS_ERROR';
export const POST_EVENT = 'POST_EVENT';
export const POST_EVENT_SUCCESS = 'POST_EVENT_SUCCESS';
export const POST_EVENT_ERROR = 'POST_EVENT_ERROR';
export const DELETE_EVENT = 'DELETE_EVENT';
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS';
export const DELETE_EVENT_ERROR = 'DELETE_EVENT_ERROR';
export const UPDATE_FILTERS = 'UPDATE_FILTERS';
export const RESET_FILTERS = 'RESET_FILTERS';

export const getEvent = (id, isLogged) => ({
    type: GET_EVENT,
    id,
    isLogged,
});
export const getEventSuccess = (event) => ({
    type: GET_EVENT_SUCCESS,
    event,
});
export const getEventError = (err) => ({
    type: GET_EVENT_ERROR,
    err,
});

export const getEvents = (isLogged, page, limit, startDate, endDate, status) => ({
    type: GET_EVENTS,
    isLogged,
    page,
    limit,
    startDate,
    endDate,
    status,
});

export const getEventsSuccess = (events) => ({
    type: GET_EVENTS_SUCCESS,
    events,
});

export const getEventsError = (err) => ({
    type: GET_EVENTS_ERROR,
    err,
});

export const postEvent = (event) => ({
    type: POST_EVENT,
    event,
});

export const postEventSuccess = (event) => ({
    type: POST_EVENT_SUCCESS,
    event,
});

export const postEventError = (err) => ({
    type: POST_EVENT_ERROR,
    err,
});

export const deleteEvent = (id) => ({
    type: DELETE_EVENT,
    id,
});

export const deleteEventSuccess = (event) => ({
    type: DELETE_EVENT_SUCCESS,
    event,
});

export const deleteEventError = (err) => ({
    type: DELETE_EVENT_ERROR,
    err,
});

export const updateFilters = (filter) => ({
    type: UPDATE_FILTERS,
    filter,
});

export const resetFilters = () => ({
    type: RESET_FILTERS,
});
