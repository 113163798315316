import { Outlet, Route, Routes } from 'react-router-dom';

import AltAgenda from './components/AltAgenda/AltAgendaContainer';
import AltCalendar from './components/AltCalendar/AltCalendarContainer';
import AltEventsMap from './components/AltEventsMap/AltEventsMapContainer';
import DaysEvents from './components/Events/DaysEvents/DaysEventsContainer';
import Event from './components/Events/Event/EventContainer';
import EventForm from './components/Events/EventForm/EventForm';
import MyEvents from './components/Events/MyEvents/MyEventsContainer';
import MyVenues from './components/Venues/MyVenues/MyVenuesContainer';
import Organizer from './components/Organizers/Organizer/OrganizerContainer';
import OrganizersList from './components/Organizers/OrganizersList/OrganizersListContainer';
import React from 'react';
import Venue from './components/Venues/Venue/VenueContainer';
import VenuesList from './components/Venues/VenuesList/VenuesListContainer';
import { getCookiesUser } from './providers/AuthenticationProvider/AuthenticationProvider';

export const ROUTE_PATH_AGENDA = '/agenda';
export const ROUTE_PATH_PAST_AGENDA = '/agenda-evenements-passes';
export const ROUTE_PATH_CALENDAR = '/calendrier';
export const ROUTE_PATH_MAP = '/carte';
export const ROUTE_PATH_EVENT = '/evenement';
export const ROUTE_PATH_EVENT_BY_SLUG = '/ev';
export const ROUTE_PATH_DAYS_EVENTS = '/evenements-du-jour';
export const ROUTE_PATH_MY_EVENTS = '/mes-evenements';
export const ROUTE_PATH_MY_PAST_EVENTS = '/mes-evenements-passes';
export const ROUTE_PATH_ADD_EVENT = '/ajouter-evenement';
export const ROUTE_PATH_MY_VENUES = '/mes-lieux';
export const ROUTE_PATH_ORGANIZERS = '/organisateurs';
export const ROUTE_PATH_ORGANIZER = '/organisateur';
export const ROUTE_PATH_VENUES = '/lieux';
export const ROUTE_PATH_VENUE = '/lieu';

function AppRoutes() {
    const isLogged = !!getCookiesUser().token;

    return (
        <Routes>
            <Route
                exact
                path="/"
                element={<AltAgenda />}
            />
            <Route
                path={`${ROUTE_PATH_AGENDA}`}
                element={<AltAgenda />}
            />
            <Route
                path={`${ROUTE_PATH_PAST_AGENDA}`}
                element={<AltAgenda isPastEvents />}
            />
            <Route
                path={`${ROUTE_PATH_CALENDAR}`}
                element={<AltCalendar />}
            />
            <Route
                path={`${ROUTE_PATH_MAP}`}
                element={<AltEventsMap />}
            />
            <Route
                path={`${ROUTE_PATH_EVENT}/:id`}
                element={<Event />}
            />
            <Route
                path={`${ROUTE_PATH_EVENT_BY_SLUG}/:slug`}
                element={<Event />}
            />
            <Route
                path={`${ROUTE_PATH_DAYS_EVENTS}/:date`}
                element={<DaysEvents />}
            />
            <Route
                path={`${ROUTE_PATH_MY_EVENTS}`}
                element={<MyEvents />}
                render={isLogged ? <MyEvents /> : <Outlet />}
            />
            <Route
                path={`${ROUTE_PATH_MY_PAST_EVENTS}`}
                element={<MyEvents isPastEvents />}
                render={isLogged ? <MyEvents isPastEvents /> : <Outlet />}
            />
            <Route
                path={ROUTE_PATH_ADD_EVENT}
                element={<EventForm />}
            />
            <Route
                path={`${ROUTE_PATH_MY_VENUES}`}
                element={<MyVenues />}
                render={isLogged ? <MyVenues /> : <Outlet />}
            />
            <Route
                path={`${ROUTE_PATH_ORGANIZERS}`}
                element={<OrganizersList />}
            />
            <Route
                path={`${ROUTE_PATH_ORGANIZER}/:id`}
                element={<Organizer />}
            />
            <Route
                path={`${ROUTE_PATH_VENUES}`}
                element={<VenuesList />}
            />
            <Route
                path={`${ROUTE_PATH_VENUE}/:id`}
                element={<Venue />}
            />
        </Routes>
    );
}

export default AppRoutes;
