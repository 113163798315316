import { Checkbox } from '@mui/material';
import React from 'react';
import { green } from '@mui/material/colors';
import { withStyles } from '@mui/styles';

const ValidationCheckbox = withStyles({
    root: {
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})((props) => (
    <Checkbox
        color="default"
        {...props}
    />
));

function AltCheckbox(props) {
    return <ValidationCheckbox {...props} />;
}

export default AltCheckbox;
