import { Avatar, Chip } from '@mui/material/';
import {
    Build as BuildIcon,
    Flag as FlagIcon,
    FolderOpen as FolderOpenIcon,
    LocalBar as LocalBarIcon,
    LocalCafe as LocalCafeIcon,
    LocalShipping as LocalShippingIcon,
    MusicNote as MusicNoteIcon,
    Palette as PaletteIcon,
    PanTool as PanToolIcon,
    Person as PersonIcon,
    Speaker as SpeakerIcon,
} from '@mui/icons-material/';

import AltListItem from '../../../common/AltListItem';
import React from 'react';
import { grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    chip: {
        marginRight: theme.spacing(0.5),
        '& svg': {
            fontSize: '1.2rem',
        },
    },
}));

const EventCategories = ({ categories = [], ...rest }) => {
    const classes = useStyles();

    return categories.length > 0 ? (
        <AltListItem
            {...rest}
            icon={<FolderOpenIcon fontSize="small" />}
        >
            {categories.map((cat, i) => (
                <Chip
                    key={`cat-${i}`}
                    label={cat.name}
                    className={classes.chip}
                    variant="outlined"
                    avatar={
                        <Avatar sx={{ bgcolor: grey[300] }}>
                            {React.createElement(
                                CATEGORY_ICON[cat.slug] || CATEGORY_ICON['default'],
                            )}
                        </Avatar>
                    }
                />
            ))}
        </AltListItem>
    ) : null;
};

export default EventCategories;

const CATEGORY_ICON = {
    art: PaletteIcon,
    atelier: PanToolIcon,
    benevole: PersonIcon,
    'cafe-associatif': LocalCafeIcon,
    chantier: BuildIcon,
    concert: MusicNoteIcon,
    festival: SpeakerIcon,
    'fete-locale': LocalBarIcon,
    lutte: FlagIcon,
    materiel: LocalShippingIcon,
    default: FolderOpenIcon,
};
