import { List } from '@mui/material/';
import React from 'react';

function AltList({ children, dense, divider, disablePadding }) {
    return (
        <List disablePadding={disablePadding}>
            {React.Children.map(children, (item) =>
                React.isValidElement(item)
                    ? React.cloneElement(item, {
                          dense: !!dense,
                          divider: !!divider,
                      })
                    : null,
            )}
        </List>
    );
}

export default AltList;
