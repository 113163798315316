import { Button, DialogActions, DialogContent, Typography, capitalize } from '@mui/material/';
import React, { useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AltButton from '../../common/AltButton';
import AltTextField from '../../common/AltTextField';
import Loader from '../../common/Loader';
import { isEmail } from '../../../utils';
import { newUser } from '../UserActions';

const initialState = {
    errors: {
        // undefined = empty optional, null = empty required, false = valid
        // mandatories fields :
        name: null,
        password: null,
        email: null,
    },
};

const reducer = (state, action) => {
    let value, error;
    switch (action.type) {
        case 'name':
            value = action.value;
            error = !(value?.length > 0);
            return {
                ...state,
                name: value,
                errors: {
                    ...state.errors,
                    name: error,
                },
            };
        case 'email':
            value = action.value;
            error = !(value?.length > 0 && isEmail(value));
            return {
                ...state,
                email: value,
                errors: {
                    ...state.errors,
                    email: error,
                },
            };
        case 'password':
            value = action.value;
            error = !(value?.length > 7);
            return {
                ...state,
                password: value,
                errors: {
                    ...state.errors,
                    password: error,
                },
            };
        default:
            throw new Error('Unexpected action');
    }
};

function NewUserForm({ handleClose }) {
    const dispatch = useDispatch();

    const [form, setForm] = useReducer(reducer, initialState);

    const { isFetchingNewUser } = useSelector((state) => ({
        isFetchingNewUser: state.users.isFetchingNewUser,
    }));

    const handleChangeValue = (type, e) => setForm({ type, value: e.currentTarget.value });

    const { name, email, password, errors } = form;

    const handleSubmit = (e) => {
        e.preventDefault();
        const capitalizedName = capitalize(name);
        dispatch(
            newUser({
                username: capitalizedName, // public name
                email,
                password,
                // erase wordpress form values (not used in this app)
                firstName: '',
                lastName: capitalizedName,
            }),
        );
    };

    const errorsValues = Object.values(errors);
    const isValid = errorsValues.every((val) => val === false);
    const hasEmptyMandatoriesFields = errorsValues.some((val) => val === null);

    return (
        <>
            <DialogContent dividers>
                <form
                    noValidate
                    autoComplete="off"
                >
                    <AltTextField
                        label="Nom de l'organisation"
                        value={name || ''}
                        onChange={(e) => handleChangeValue('name', e)}
                        required
                        error={errors.name}
                        autoFocus
                    />

                    <AltTextField
                        label="Adresse e-mail privée"
                        value={email || ''}
                        onChange={(e) => handleChangeValue('email', e)}
                        type="email"
                        required
                        error={errors.email}
                        helperText={
                            errors.email
                                ? "L'adresse e-mail n'est pas au bon format."
                                : 'Cette adresse est utilisée pour te connecter et ne sera pas affichée sur le site.'
                        }
                    />

                    <AltTextField
                        label="Mot de passe"
                        value={password || ''}
                        onChange={(e) => handleChangeValue('password', e)}
                        type="password"
                        required
                        error={errors.password}
                        helperText={
                            errors.password ? "Le mot de passe n'est pas au bon format." : ''
                        }
                    />
                </form>
            </DialogContent>

            <DialogActions>
                {hasEmptyMandatoriesFields && (
                    <Typography variant="subtitle2">
                        Tous les champs obligatoires (*) doivent être remplis
                    </Typography>
                )}
                {!isValid && !hasEmptyMandatoriesFields && (
                    <Typography
                        color="error"
                        variant="subtitle2"
                    >
                        Merci de corriger les erreurs avant de valider
                    </Typography>
                )}
                <Button
                    variant="contained"
                    onClick={handleClose}
                >
                    Annuler
                </Button>

                <AltButton
                    type="submit"
                    variant="contrib"
                    onClick={handleSubmit}
                    disabled={!isValid}
                    endIcon={
                        isFetchingNewUser ? (
                            <Loader
                                margin="none"
                                size="xxs"
                                color="white"
                            />
                        ) : null
                    }
                >
                    Valider
                </AltButton>
            </DialogActions>
        </>
    );
}

export default NewUserForm;
