import {
    ViewList as AgendaIcon,
    Today as CalendarIcon,
    ArrowDropDown as DropDownIcon,
    Group as GroupIcon,
    Map as MapIcon,
    Place as PlaceIcon,
} from '@mui/icons-material/';
import { Box, Button, Divider, IconButton, Tooltip } from '@mui/material/';
import {
    ROUTE_PATH_AGENDA,
    ROUTE_PATH_CALENDAR,
    ROUTE_PATH_MAP,
    ROUTE_PATH_MY_EVENTS,
    ROUTE_PATH_MY_VENUES,
    ROUTE_PATH_ORGANIZERS,
    ROUTE_PATH_VENUES,
} from '../../AppRoutes';

import { Link } from 'react-router-dom';
import OrganizerLogo from '../common/OrganizerLogo';
import React from 'react';
import ToggleMenu from '../common/ToggleMenu';
import { WP_ROLES_ADMIN } from '../../constants';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    divider: {
        backgroundColor: theme.palette.primary.contrastText,
        opacity: 0.25,
        marginLeft: theme.spacing(1.5),
    },
    loggedButton: {
        padding: theme.spacing(0, 2),
        height: 38,
        lineHeight: 1.2,
        '&:hover': {
            backgroundColor: theme.palette.contrib.main,
            color: theme.palette.contrib.contrastText,
        },
    },
    loggedIcon: {
        backgroundColor: theme.palette.contrib.main,
        width: 38,
        height: 38,
    },
    submenu: {
        '& a': {
            color: '#000000',
        },
    },
}));

function AltAppBarMenuMedium({
    openDialogLogin,
    handleLogout,
    openDialogPostEvent,
    openDialogPostVenue,
    openDialogOrganizer,
    openDialogMedias,
    openDialogNewUser,
    location: { pathname },
    user,
}) {
    const classes = useStyles();

    return (
        <>
            <Box display="flex">
                <AltAppBarButtonPrimary
                    isHome
                    label="Agenda"
                    pathname={pathname}
                    startIcon={<AgendaIcon />}
                    to={ROUTE_PATH_AGENDA}
                    tooltip="Prochains événements"
                    ariaLabel="Consulter l'agenda pour découvrir les prochains événements"
                />

                <AltAppBarButtonPrimary
                    label="Calendrier"
                    pathname={pathname}
                    startIcon={<CalendarIcon />}
                    to={ROUTE_PATH_CALENDAR}
                    tooltip="Événements du mois"
                    ariaLabel="Ouvrir le calendrier mensuel des événements"
                />

                <AltAppBarButtonPrimary
                    label="Carte"
                    pathname={pathname}
                    startIcon={<MapIcon />}
                    to={ROUTE_PATH_MAP}
                    tooltip="Vue du Périgord vert"
                    ariaLabel="Visualiser les événements sur une carte du Périgord"
                />

                <Divider
                    orientation="vertical"
                    flexItem
                    className={classes.divider}
                />

                <AltAppBarButtonPrimary
                    label="Lieux"
                    pathname={pathname}
                    startIcon={<PlaceIcon />}
                    to={ROUTE_PATH_VENUES}
                    tooltip="Liste des lieux"
                    ariaLabel="Liste des lieux"
                />

                <AltAppBarButtonPrimary
                    label="Organisateurs"
                    pathname={pathname}
                    startIcon={<GroupIcon />}
                    to={ROUTE_PATH_ORGANIZERS}
                    tooltip="Liste des organisateurs"
                    ariaLabel="Liste des organisateurs"
                />

                <Divider
                    orientation="vertical"
                    flexItem
                    className={classes.divider}
                />
            </Box>

            {!user.isLogged && (
                <IconButton
                    color="primary"
                    onClick={openDialogLogin}
                    size="large"
                >
                    <GroupIcon />
                </IconButton>
            )}

            {user.isLogged && (
                <>
                    <ToggleMenu
                        className={classes.submenu}
                        list={[
                            {
                                children: 'Ajouter un utilisateur',
                                onClick: openDialogNewUser,
                                hide: !user.roles?.includes(WP_ROLES_ADMIN),
                            },
                            { children: 'Créer un événement', onClick: openDialogPostEvent },
                            { children: 'Ajouter un lieu', onClick: openDialogPostVenue },
                            { divider: true },
                            { children: 'Mon profil', onClick: openDialogOrganizer },
                            {
                                children: (
                                    <Link
                                        to={ROUTE_PATH_MY_EVENTS}
                                        aria-label="Lister mes événements à venir"
                                    >
                                        Mes événements
                                    </Link>
                                ),
                            },
                            {
                                children: (
                                    <Link
                                        to={ROUTE_PATH_MY_VENUES}
                                        aria-label="Lister mes lieux"
                                    >
                                        Mes lieux
                                    </Link>
                                ),
                            },
                            { children: 'Mes images', onClick: openDialogMedias },
                            { divider: true },
                            { children: 'Déconnexion', onClick: handleLogout },
                        ]}
                    >
                        <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            className={classes.loggedButton}
                            startIcon={
                                <OrganizerLogo
                                    user={user}
                                    size={34}
                                    spacing={1}
                                    isContrib
                                />
                            }
                            endIcon={
                                <DropDownIcon
                                    fontSize="small"
                                    className={classes.iconRight}
                                />
                            }
                        >
                            {user.name}
                        </Button>
                    </ToggleMenu>
                </>
            )}
        </>
    );
}

export default AltAppBarMenuMedium;

const AltAppBarButtonPrimary = ({
    ariaLabel,
    isHome,
    label,
    pathname,
    to,
    tooltip,
    startIcon,
    endIcon,
}) => {
    const isDisabled = pathname === to || (isHome && pathname === '/');

    const button = (
        <Button
            disabled={isDisabled}
            variant="text"
            size="large"
            color="primary"
            startIcon={startIcon}
            endIcon={endIcon}
        >
            {label}
        </Button>
    );

    return isDisabled ? (
        button
    ) : (
        <Link
            to={to}
            aria-label={ariaLabel}
        >
            <Tooltip
                title={tooltip}
                arrow
                disableTouchListener
            >
                {button}
            </Tooltip>
        </Link>
    );
};

/*
const AltAppBarButtonSecondary = ({ label, hideLabel, startIcon, endIcon }) =>
    <Button
        variant="contained"
        color="secondary"
        startIcon={startIcon}
        endIcon={endIcon}
    >
        {!hideLabel && <>{label}</>}
    </Button>
*/
