import { Avatar } from '@mui/material';
import { Group as GroupIcon } from '@mui/icons-material';
import React from 'react';
import classnames from 'classnames';
import { grey } from '@mui/material/colors';
import { isImageUrl } from '../../utils';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    logo: ({ size, spacing }) => ({
        marginRight: theme.spacing(spacing),
        height: size,
        width: size,
        outline: '1px solid',
        outlineColor: grey[400],
    }),
    contrib: {
        backgroundColor: theme.palette.contrib.main,
        outline: '0 !important',
    },
}));

const getLogoByOrganizer = (organizer, users) => {
    if (!organizer || !users) {
        return null;
    }
    const author = users.find((user) => user.id === organizer.author);
    return author?.logo && isImageUrl(author?.logo) ? author.logo : null;
};

function OrganizerLogo({ isContrib, organizer, size = 38, spacing = 2, user, users }) {
    const classes = useStyles({ size, spacing });

    const logo = user?.logo || getLogoByOrganizer(organizer, users);

    return (
        <Avatar
            src={logo}
            className={classnames(classes.logo, { [classes.contrib]: isContrib })}
        >
            {!logo && <GroupIcon />}
        </Avatar>
    );
}

export default OrganizerLogo;
