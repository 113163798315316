import { Box, List, ListItem } from '@mui/material';

import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    ul: {
        margin: theme.spacing(2),
        padding: theme.spacing(0, 2),
        listStyle: 'disc outside',
        '& > li': {
            display: 'list-item',
        },
    },
}));

export function AltUl({ children, dense = true }) {
    const classes = useStyles();
    return (
        <List
            dense={dense}
            className={classes.ul}
        >
            {children}
        </List>
    );
}

export function AltLi({ children }) {
    return <ListItem disableGutters>{children}</ListItem>;
}

const TYPOGRAPHY_COMPONENT_DEFAULT = 'span';
const TYPOGRAPHY_VARIANT_DEFAULT = 'body2';

function AltText(props) {
    const classes = useStyles();
    const {
        children,
        component = TYPOGRAPHY_COMPONENT_DEFAULT,
        variant = TYPOGRAPHY_VARIANT_DEFAULT,
    } = props;
    const childProps = { ...props, children: undefined, component, variant };
    return (
        <Box
            {...childProps}
            className={classes[component] || ''}
        >
            {children}
        </Box>
    );
}

export function AltStrong({ children, variant = TYPOGRAPHY_VARIANT_DEFAULT }) {
    return (
        <AltText
            component="strong"
            fontWeight={500}
            variant={variant}
        >
            {children}
        </AltText>
    );
}

export function AltEm({ children, variant = TYPOGRAPHY_VARIANT_DEFAULT }) {
    return (
        <AltText
            component="em"
            fontStyle="italic"
            variant={variant}
        >
            {children}
        </AltText>
    );
}
