import 'moment/locale/fr';

import AltAgendaEvents, { FutureEventsLink, PastEventsLink } from './AltAgendaEvents';
import { LAYOUT_GRID_ASIDE_MD, LAYOUT_GRID_MAIN_MD, LAYOUT_GRID_SPACING } from '../../constants';

import AltAgendaAside from './AltAgendaAsideContainer';
import { Box } from '@mui/material';
import EventsFilters from '../Events/EventsFilters/EventsFiltersContainer';
import Grid from '@mui/material/Grid';
import React from 'react';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const useStyles = makeStyles((theme) => ({
    agendaContainer: {
        // remove when responsive agenda is available
        [theme.breakpoints.down('xl') && theme.breakpoints.up('md')]: {
            maxWidth: 802.5,
            margin: '0 auto',
        },
    },
}));

function AltAgenda({
    detailsDense,
    events,
    isFiltered,
    isLoading,
    isMyEvents,
    isPastEvents,
    handleResetFilters,
    title,
}) {
    const classes = useStyles();

    // TO DO : use 'md' breakpoint when responsive agenda is available
    const isLargeOrMore = useMediaQuery((theme) => theme.breakpoints.up('lg'));

    return (
        <Grid
            container
            spacing={LAYOUT_GRID_SPACING}
        >
            <Grid
                item
                xs={12}
                lg={LAYOUT_GRID_MAIN_MD}
                className={classes.agendaContainer}
            >
                {title && (
                    <Box
                        display={isLargeOrMore ? 'flex' : 'block'}
                        justifyContent="space-between"
                        alignItems="flex-end"
                        mb={3}
                    >
                        <Typography
                            variant="h2"
                            component="h2"
                            gutterBottom={!isLargeOrMore}
                        >
                            {title}
                            {isPastEvents ? ' passés' : ' à venir'}
                        </Typography>

                        {isPastEvents ? (
                            <FutureEventsLink isMyEvents={isMyEvents} />
                        ) : (
                            <PastEventsLink isMyEvents={isMyEvents} />
                        )}
                    </Box>
                )}

                <EventsFilters hideOrganizers={isMyEvents} />

                <AltAgendaEvents
                    events={events}
                    isLoading={isLoading}
                    isMyEvents={isMyEvents}
                    isFiltered={isFiltered}
                    isPastEvents={isPastEvents}
                    handleResetFilters={handleResetFilters}
                    detailsDense={detailsDense}
                />
            </Grid>

            {isLargeOrMore && (
                <Grid
                    item
                    lg={LAYOUT_GRID_ASIDE_MD}
                >
                    <AltAgendaAside />
                </Grid>
            )}
        </Grid>
    );
}

export default AltAgenda;
