import {
    LAYOUT_APPBAR_HEIGHT,
    LAYOUT_CONTAINER_WIDTH,
    LAYOUT_SPACING,
    THEME_COLOR_CONTRIB,
    THEME_COLOR_PRIMARY,
    THEME_COLOR_SECONDARY,
} from '../../constants';

import { createTheme } from '@mui/material';

/* ---------- SPACING ---------- */

const spacing = LAYOUT_SPACING;

/* ---------- SHAPE ---------- */

const shape = {
    borderRadius: 0,
};

/* ---------- MIXINS ---------- */

/*
DONT'T WORKING

const mixins = {
  toolbar: {
    minHeight: 56, // 56

    '@media (min-width:0px) and (orientation: landscape)': {
      minHeight: 48, // 48
    },

    '@media (min-width:600px)': {
      minHeight: 64, // 64
    },
  },
};


/* ---------- COLORS ---------- */

const palette = {
    // type: 'light',

    common: {
        // black: '#000000',
        // white: '#ffffff',
    },

    primary: {
        // light: '#b9d11f', // ?
        main: THEME_COLOR_PRIMARY || '#a0b51b',
        // contrastText: '#fff',
    },

    secondary: {
        main: THEME_COLOR_SECONDARY || '#996633',
        contrastText: '#fff', // ?
    },

    // Custom var
    contrib: {
        main: THEME_COLOR_CONTRIB,
        dark: '#004197', // todo: get dynamic dark color
        contrastText: '#fff',
    },

    grey: {
        // 50: "#fafafa",
        // 100: "#f5f5f5",
        // 200: "#eeeeee",
        // 300: "#e0e0e0",
        // 400: "#bdbdbd",
        // 500: "#9e9e9e",
        // 600: "#757575",
        // 700: "#616161",
        // 800: "#424242",
        // 900: "#212121",
        // A100: "#d5d5d5",
        // A200: "#aaaaaa",
        // A400: "#303030",
        // A700: "#616161",
    },

    text: {
        // primary: 'rgba(0, 0, 0, 0.87)'
        // secondary: 'rgba(0, 0, 0, 0.54)'
        // disabled: 'rgba(0, 0, 0, 0.38)'
    },

    background: {
        default: 'rgba(245, 242, 240, 1)', // '#fafafa'
        //paper: '#fff',
    },

    info: {
        main: '#666666',
        light: '#efefef',
        dark: '#444444',
    },

    // success: {
    // light: "#81c784"
    // main: "#4caf50"
    // dark: "#388e3c"
    // contrastText: "rgba(0, 0, 0, 0.87)"
    // },

    // warning: {},
    // error: {},

    action: {
        // active: 'rgba(0, 0, 0, 0.54)',
        hover: 'rgba(0, 0, 0, 0.06)', //'rgba(0, 0, 0, 0.04)',
        hoverOpacity: 0.06, // 0.04
        // selected: 'rgba(0, 0, 0, 0.08)',
        // selectedOpacity: 0.08,
        // disabled: 'rgba(0, 0, 0, 0.26)',
        disabledBackground: 'rgba(0, 0, 0, 0.1)', // 'rgba(0, 0, 0, 0.12)'
        // disabledOpacity: 0.38,
        // focus: 'rgba(0, 0, 0, 0.12)',
        // focusOpacity: 0.12,
        // activatedOpacity: 0.12,
        tooltip: 'rgba(0, 0, 0, 0.7)', // Custom var
    },
};

/* ---------- BREAKPOINTS ---------- */

const breakpoints = {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
    },
};

// Create temporary theme for use variables in components overrides
let theme = createTheme({
    spacing,
    shape,
    //mixins,
    palette,
    breakpoints,
});

/* ---------- TYPOGRAPHY ---------- */

const typography = {
    // fontFamily: `"Roboto", "Helvetica", "Arial", "sans-serif"`,
    // fontSize: 14,
    // fontWeightLight: 300,
    // fontWeightMedium: 400,
    // fontWeightRegular: 500,
    /* title: {
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: ""Roboto", "Helvetica", "Arial", sans-serif",
    lineHeight: "1.16667em",
    fontSize: "1.3125rem",
    fontWeight: 500,
  },*/
    /*subheading: {
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: ""Roboto", "Helvetica", "Arial", sans-serif",
    lineHeight: "1.5em",
    fontSize: "1rem",
    fontWeight: 400;
  }, */
    /* button: {
    textTransform: "uppercase",
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
    fontSize: "0.875rem",
    fontWeight: 500,
  }, */

    h1: {
        fontSize: '1rem', // '6rem'
        fontWeight: 300, // 300
        fontFamily: 'inherit', // Roboto
    },
    h2: {
        fontSize: theme.typography.pxToRem(20), // '3.75rem'
        fontWeight: 600, // 300
        fontFamily: 'inherit',
        [theme.breakpoints.up('md')]: {
            fontSize: theme.typography.pxToRem(24), // '3.75rem'
        },
    },
    h3: {
        fontSize: theme.typography.pxToRem(18), // '3rem
        fontWeight: 600, // 400
        fontFamily: 'inherit',
        [theme.breakpoints.up('md')]: {
            fontSize: theme.typography.pxToRem(20), // '3rem'
        },
    },
    h4: {
        fontSize: theme.typography.pxToRem(17), // '2.125rem'
        fontWeight: 600, // 400
        fontFamily: 'inherit',
        [theme.breakpoints.up('md')]: {
            fontSize: '1.2rem', // '2.125rem'
        },
    },
    h5: {
        fontSize: '1rem', // '1.334'
        fontWeight: 500, // 400,
        fontFamily: 'inherit',
        [theme.breakpoints.up('md')]: {
            fontSize: '1.05rem', // '1.334'
            fontWeight: 600, // 400,
        },
    },
    h6: {
        fontSize: '1rem', // '1.25rem'
        //fontWeight: 500,
        fontFamily: 'inherit',
    },
    subtitle1: {
        fontSize: '1rem',
        color: palette.text.secondary,
        fontWeight: 500,
    },
    // subtitle2: {},
    // body1: {},
    // body2: {},
    // button: {},
    // caption: {},
    // overline: {},
    /* display1: {
    color: "rgba(0, 0, 0, 0.54)",
    fontFamily: ""Roboto", "Helvetica", "Arial", sans-serif",
    lineHeight: "1.20588em",
    fontSize: "2.125rem",
    fontWeight: 400,
  }, */
    // display2: {},
    // display3: {},
    // display4: {},
};

/* ---------- SHADOWS ---------- */

const shadows = [
    'none',
    'rgba(0, 0, 0, 0.1) 0px 1px 2px 0px',
    'rgba(0, 0, 0, 0.12) 0px 1px 2px 0px',
    'rgba(0, 0, 0, 0.14) 0px 1px 2px 0px',
    'rgba(0, 0, 0, 0.16) 0px 1px 2px 0px',
    '0px 2px 1px -1px rgba(0,0,0,0.3),0px 1px 1px 0px rgba(0,0,0,0.15),0px 1px 3px 0px rgba(0,0,0,0.22)',
    '0px 2px 1px -1px rgba(0,0,0,0.3),0px 1px 1px 0px rgba(0,0,0,0.15),0px 1px 3px 0px rgba(0,0,0,0.24)',
    '0px 2px 1px -1px rgba(0,0,0,0.3),0px 1px 1px 0px rgba(0,0,0,0.15),0px 1px 3px 0px rgba(0,0,0,0.26)',
    '0px 2px 1px -1px rgba(0,0,0,0.3),0px 1px 1px 0px rgba(0,0,0,0.15),0px 1px 3px 0px rgba(0,0,0,0.28)',
    '0px 2px 1px -1px rgba(0,0,0,0.3),0px 1px 1px 0px rgba(0,0,0,0.15),0px 1px 3px 0px rgba(0,0,0,0.30)',
    '0px 2px 1px -1px rgba(0,0,0,0.3),0px 1px 1px 0px rgba(0,0,0,0.15),0px 1px 3px 0px rgba(0,0,0,0.32)',
    '0px 2px 1px -1px rgba(0,0,0,0.3),0px 1px 1px 0px rgba(0,0,0,0.15),0px 1px 3px 0px rgba(0,0,0,0.34)',
    '0px 2px 1px -1px rgba(0,0,0,0.3),0px 1px 1px 0px rgba(0,0,0,0.15),0px 1px 3px 0px rgba(0,0,0,0.36)',
    '0px 2px 1px -1px rgba(0,0,0,0.3),0px 1px 1px 0px rgba(0,0,0,0.15),0px 1px 3px 0px rgba(0,0,0,0.38)',
    '0px 2px 1px -1px rgba(0,0,0,0.3),0px 1px 1px 0px rgba(0,0,0,0.15),0px 1px 3px 0px rgba(0,0,0,0.40)',
    '0px 2px 1px -1px rgba(0,0,0,0.3),0px 1px 1px 0px rgba(0,0,0,0.15),0px 1px 3px 0px rgba(0,0,0,0.42)',
    '0px 2px 1px -1px rgba(0,0,0,0.3),0px 1px 1px 0px rgba(0,0,0,0.15),0px 1px 3px 0px rgba(0,0,0,0.44)',
    '0px 2px 1px -1px rgba(0,0,0,0.3),0px 1px 1px 0px rgba(0,0,0,0.15),0px 1px 3px 0px rgba(0,0,0,0.46)',
    '0px 2px 1px -1px rgba(0,0,0,0.3),0px 1px 1px 0px rgba(0,0,0,0.15),0px 1px 3px 0px rgba(0,0,0,0.48)',
    '0px 2px 1px -1px rgba(0,0,0,0.3),0px 1px 1px 0px rgba(0,0,0,0.15),0px 1px 3px 0px rgba(0,0,0,0.50)',
    '0px 2px 1px -1px rgba(0,0,0,0.3),0px 1px 1px 0px rgba(0,0,0,0.15),0px 1px 3px 0px rgba(0,0,0,0.52)',
    '0px 2px 1px -1px rgba(0,0,0,0.3),0px 1px 1px 0px rgba(0,0,0,0.15),0px 1px 3px 0px rgba(0,0,0,0.54)',
    '0px 2px 1px -1px rgba(0,0,0,0.3),0px 1px 1px 0px rgba(0,0,0,0.15),0px 1px 3px 0px rgba(0,0,0,0.56)',
    '0px 2px 1px -1px rgba(0,0,0,0.3),0px 1px 1px 0px rgba(0,0,0,0.15),0px 1px 3px 0px rgba(0,0,0,0.58)',
    '0px 2px 1px -1px rgba(0,0,0,0.3),0px 1px 1px 0px rgba(0,0,0,0.15),0px 1px 3px 0px rgba(0,0,0,0.20)',
];

// Update temporary theme for use variables in components overrides
theme = createTheme({
    ...theme,
    typography,
    shadows,
});

/* ---------- COMPONENTS ---------- */

const components = {
    MuiAppBar: {
        styleOverrides: {
            root: {
                '& .MuiButton-root': {
                    marginLeft: theme.spacing(1.5),
                    textTransform: 'none',
                },

                '& .MuiButton-contained': {
                    marginLeft: theme.spacing(2),
                },

                '& .MuiIconButton-root': {
                    marginLeft: theme.spacing(2),
                },

                '& .MuiButton-text .MuiButton-startIcon': {
                    marginRight: theme.spacing(2),
                },

                '& .MuiMenuItem-root': {
                    margin: theme.spacing(0, 1.5),
                },
            },
            colorPrimary: {
                '& .MuiButton-text.Mui-disabled': {
                    backgroundColor: 'rgba(255, 255, 255, .18)',
                    color: theme.palette.grey[300],
                },
                '& .MuiButton-textPrimary': {
                    color: theme.palette.primary.contrastText,
                },
                '& .MuiButton-textPrimary:hover': {
                    backgroundColor: 'rgba(255, 255, 255, .25)',
                },

                '& .MuiIconButton-colorPrimary': {
                    backgroundColor: 'rgba(255, 255, 255, .3)',
                    color: theme.palette.primary.contrastText,
                    '&:hover': {
                        backgroundColor: theme.palette.contrib.main,
                    },
                },
            },
        },
    },
    MuiAlert: {
        styleOverrides: {
            message: {
                display: 'flex',
                padding: 0,
                flexDirection: 'column',
                justifyContent: 'center',
            },
            outlinedInfo: {
                backgroundColor: theme.palette.info.light,
                borderColor: '#ccc',
                color: theme.palette.info.main,
            },
        },
    },
    MuiAlertTitle: {
        styleOverrides: {
            root: {
                fontSize: theme.typography.pxToRem(15),
            },
        },
    },
    MuiBackdrop: {
        styleOverrides: {
            root: {
                backgroundColor: 'rgba(0, 0, 0, .75)',
            },
        },
    },
    MuiButton: {
        styleOverrides: {
            root: {
                '&:hover': {
                    textDecoration: 'none',
                },
            },
            sizeLarge: {
                padding: theme.spacing(1, 3),
                fontWeight: 500,
            },
        },
    },
    MuiContainer: {
        styleOverrides: {
            root: {
                margin: '0 auto',
            },
            fixed: {
                '@media (min-width: 960px)': {
                    maxWidth: LAYOUT_CONTAINER_WIDTH,
                },
            },
        },
    },
    MuiCardHeader: {
        styleOverrides: {
            root: {
                padding: theme.spacing(3.5, 3, 3),
            },
        },
    },
    MuiCardContent: {
        styleOverrides: {
            root: {
                padding: theme.spacing(3.5, 3, 0),
            },
        },
    },
    MuiIconButton: {
        styleOverrides: {
            root: {
                padding: 7,
            },
            colorPrimary: {
                color: theme.palette.primary.contrastText,
            },
        },
    },
    MuiLink: {
        styleOverrides: {
            root: {
                color: theme.palette.secondary.main,
                textDecoration: 'none',
                '&:hover': {
                    textDecoration: 'underline',
                },
            },
        },
    },
    MuiListItemIcon: {
        styleOverrides: {
            root: {
                minWidth: 40,
            },
        },
    },
    MuiToolbar: {
        styleOverrides: {
            regular: {
                //minHeight: 56, // 56

                '@media (min-width:0px) and (orientation: landscape)': {
                    //minHeight: 48, // 48
                },

                '@media (min-width:600px)': {
                    minHeight: LAYOUT_APPBAR_HEIGHT, // 58
                },
            },
        },
    },
    MuiTooltip: {
        styleOverrides: {
            arrow: {
                color: theme.palette.action.tooltip,
            },
            tooltip: {
                maxWidth: 200,
                padding: theme.spacing(1.25, 2, 1.5),
                fontSize: theme.typography.pxToRem(13),
                fontWeight: 400,
                backgroundColor: theme.palette.action.tooltip,
            },
            tooltipPlacementBottom: {
                position: 'relative',
                top: 3,
            },
        },
    },
    MuiPopover: {
        styleOverrides: {
            root: {
                '& .MuiPopover-paper': {
                    marginTop: 1,
                },
                '& .MuiList-padding': {
                    paddingTop: 0,
                },
                '& .MuiListSubheader-sticky': {
                    padding: theme.spacing(2.5, 2, 0),
                    backgroundColor: '#fff',
                },
                '& .MuiMenuItem-root': {
                    paddingRight: theme.spacing(2),
                    '& .MuiTypography-root': {
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                    },
                },
            },
        },
    },
    MuiAccordionSummary: {
        styleOverrides: {
            content: {
                margin: '4.5px 0 !important',
                '& .MuiTypography-root': {
                    fontSize: theme.typography.pxToRem(14),
                },
            },
        },
    },
};

const baseTheme = {
    ...theme,
    components,
};

export default baseTheme;
