import 'react-big-calendar/lib/sass/styles.scss';

import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Fade,
    IconButton,
    Slide,
    Typography,
} from '@mui/material/';
import { makeStyles, withStyles } from '@mui/styles';

import AltLink from '../../common/AltLink';
import CloseIcon from '@mui/icons-material/Close';
import EventHeaderDate from '../../Events/EventHeaderDate/EventHeaderDate';
import { ROUTE_PATH_EVENT } from '../../../AppRoutes';
import React from 'react';
import { decodeUnicodeEntities } from '../../../utils';
import useMediaQuery from '@mui/material/useMediaQuery';

const styles = (theme) => ({
    root: {
        display: 'flex',
        margin: 0,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    content: {
        overflowX: 'hidden',
    },
    imgContainer: {
        width: '607px',
        maxWidth: '100%',
        height: '262px',
        overflow: 'hidden',
        position: 'relative',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        borderTop: `1px solid ${theme.palette.action.focus}`,
    },
    img: {
        width: '100%',
        height: 'auto',
    },
    hgroup: {
        marginLeft: theme.spacing(3),
    },
    nameLink: {
        color: theme.palette.secondary.main,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
});

const MobileDialogTransition = React.forwardRef(function Transition(props, ref) {
    return (
        <Slide
            direction="up"
            ref={ref}
            {...props}
        />
    );
});

const DefaultDialogTransition = React.forwardRef(function Transition(props, ref) {
    return (
        <Fade
            ref={ref}
            {...props}
        />
    );
});

const DialogHeader = withStyles(styles)((props) => {
    const { children, classes, onClose, start, subtitle, ...other } = props;
    return (
        <DialogTitle
            className={classes.root}
            {...other}
        >
            <EventHeaderDate start={start} />

            <Box
                component="hgroup"
                className={classes.hgroup}
            >
                {children}
                {subtitle && (
                    <Typography
                        variant="subtitle1"
                        color="textSecondary"
                    >
                        {subtitle}
                    </Typography>
                )}
            </Box>

            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                    size="large"
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
});

const useStyles = makeStyles(styles);

function AltCalendarDialog(props) {
    const classes = useStyles();
    const { children, event, handleClose, isOpened } = props;

    const isMediumOrMore = useMediaQuery((theme) => theme.breakpoints.up('md'));

    return children && event ? (
        <Dialog
            open={isOpened}
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            fullScreen={!isMediumOrMore}
            TransitionComponent={!isMediumOrMore ? MobileDialogTransition : DefaultDialogTransition}
        >
            <DialogHeader
                onClose={handleClose}
                id="customized-dialog-title"
                start={event.start}
                subtitle={event.venue.city}
            >
                <AltLink to={`${ROUTE_PATH_EVENT}/${event.id}`}>
                    <Typography
                        component="h2"
                        variant="h2"
                        className={classes.nameLink}
                    >
                        {decodeUnicodeEntities(event.name)}
                    </Typography>
                </AltLink>
            </DialogHeader>

            {event.image && event.image.url && (
                <div
                    className={classes.imgContainer}
                    style={{ backgroundImage: `url(${event.image.url})` }}
                />
            )}

            <DialogContent
                dividers
                className={classes.content}
            >
                {children}
            </DialogContent>

            <DialogActions>
                <AltLink
                    to={`${ROUTE_PATH_EVENT}/${event.id}`}
                    title={event.title}
                >
                    <Button
                        color="secondary"
                        size="large"
                    >
                        En savoir plus
                    </Button>
                </AltLink>
            </DialogActions>
        </Dialog>
    ) : null;
}

export default AltCalendarDialog;
