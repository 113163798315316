import {
    Button,
    DialogActions,
    DialogContent,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Grid,
    Typography,
    capitalize,
} from '@mui/material';
import React, { useReducer } from 'react';
import {
    addOrNotUrlPrefix,
    decodeHtmlEntities,
    isFrenchZip,
    isPhoneNumber,
    isUrl,
    removeHtmlTags,
} from '../../../utils';
import { useDispatch, useSelector } from 'react-redux';

import AltButton from '../../common/AltButton';
import AltCheckbox from '../../common/AltCheckbox';
import AltTextField from '../../common/AltTextField';
import Loader from '../../common/Loader';
import { PRIVATE_PREFIX } from '../../Events/Events.utils';
import { makeStyles } from '@mui/styles';
import { postVenue } from '../VenuesActions';

const useStyles = makeStyles((theme) => ({
    loaderContainer: {
        padding: theme.spacing(2, 0),
        textAlign: 'center',
    },
    formHelperText: {
        marginLeft: 0,
        marginRight: 0,
    },
}));

const defaultValues = {
    status: 'publish',
    country: 'France',
};

const initialState = {
    errors: {
        // undefined = empty optional, null = empty required, false = valid
        // mandatories fields :
        title: null,
        description: null,
        address: null,
        city: null,
        zip: null,
    },
};

const reducer = (state, action) => {
    let value, error;
    switch (action.type) {
        case 'title':
            value = action.value;
            error = !(value?.length > 0);
            return {
                ...state,
                title: value,
                errors: {
                    ...state.errors,
                    title: error,
                },
            };
        case 'isPrivate':
            value = action.value;
            return {
                ...state,
                isPrivate: value,
                errors: {
                    ...state.errors,
                    isPrivate: false,
                },
            };
        case 'description':
            value = action.value;
            error = !(value?.length > 0);
            return {
                ...state,
                description: value,
                errors: {
                    ...state.errors,
                    description: error,
                },
            };
        case 'address':
            value = action.value;
            error = !(value?.length > 0);
            return {
                ...state,
                address: value,
                errors: {
                    ...state.errors,
                    address: error,
                },
            };
        case 'city':
            value = action.value;
            error = !(value?.length > 0);
            return {
                ...state,
                city: value,
                errors: {
                    ...state.errors,
                    city: error,
                },
            };
        case 'zip':
            value = action.value;
            error = !isFrenchZip(value);
            return {
                ...state,
                zip: value,
                errors: {
                    ...state.errors,
                    zip: error,
                },
            };
        case 'phone':
            value = action.value;
            error = value?.length > 0 && !isPhoneNumber(value, 10);
            return {
                ...state,
                phone: value,
                errors: {
                    ...state.errors,
                    phone: error,
                },
            };
        case 'website':
            value = action.value;
            error = value?.length > 0 && !isUrl(value);
            return {
                ...state,
                website: value,
                errors: {
                    ...state.errors,
                    website: error,
                },
            };
        default:
            throw new Error('Unexpected action');
    }
};

function VenueForm({ callback, venue, handleClose }) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { isFetchingPostVenue } = useSelector((state) => ({
        isFetchingPostVenue: state.venues.isFetchingPostVenue,
    }));

    const init = () =>
        !venue
            ? {
                  ...initialState,
                  isPrivate: false,
                  errors: {
                      ...initialState.errors,
                  },
              }
            : {
                  ...venue,
                  title: decodeHtmlEntities(venue.venue).replace(PRIVATE_PREFIX, ''),
                  description: decodeHtmlEntities(removeHtmlTags(venue.description)),
                  errors: {
                      title: false,
                      description: false,
                      address: false,
                      city: false,
                      zip: false,
                  },
              };

    const [form, setForm] = useReducer(reducer, initialState, init);

    const handleChangeValue = (type, e) => setForm({ type, value: e.currentTarget.value });

    const handleSubmit = (e) => {
        e.preventDefault();
        const initValues = venue?.id ? { id: venue.id } : defaultValues;
        dispatch(
            postVenue(
                {
                    ...initValues,
                    venue: capitalize(title),
                    showMap: isPrivate ? 'false' : 'true', // use this field instead of wp private value
                    description: `<div>${description}</div>`,
                    address,
                    city: capitalize(city),
                    zip,
                    phone,
                    website: website?.length > 0 ? addOrNotUrlPrefix(website) : undefined,
                },
                callback,
            ),
        );
    };

    const { title, isPrivate, description, address, city, zip, phone, website, errors } = form;

    const errorsValues = Object.values(errors);
    const isValid = errorsValues.every((val) => val === false);
    const hasEmptyMandatoriesFields = errorsValues.some((val) => val === null);

    return (
        <form
            onSubmit={handleSubmit}
            noValidate
            autoComplete="off"
        >
            <DialogContent dividers>
                <AltTextField
                    label="Nom du lieu"
                    value={title || ''}
                    onChange={(e) => handleChangeValue('title', e)}
                    required
                    error={errors.title}
                    autoFocus
                />

                <FormControl
                    component="fieldset"
                    error={errors.isPrivate}
                    className={classes.formControlChecklist}
                    required
                >
                    <FormGroup className={classes.checklist}>
                        <FormControlLabel
                            control={
                                <AltCheckbox
                                    value={isPrivate}
                                    onChange={() =>
                                        setForm({ type: 'isPrivate', value: !isPrivate })
                                    }
                                    checked={isPrivate}
                                />
                            }
                            label="Lieu privé"
                        />
                    </FormGroup>
                    <FormHelperText className={classes.formHelperText}>
                        Les lieux privés ne sont pas visibles dans la liste des lieux.
                    </FormHelperText>
                </FormControl>

                <AltTextField
                    multiline
                    label="Description, activités..."
                    minRows={10}
                    value={description || ''}
                    onChange={(e) => handleChangeValue('description', e)}
                    required
                    error={errors.description}
                />

                <AltTextField
                    label="Adresse"
                    value={address || ''}
                    onChange={(e) => handleChangeValue('address', e)}
                    required
                    error={errors.address}
                />

                <Grid
                    container
                    justifyContent="flex-start"
                    spacing={4}
                >
                    <Grid
                        item
                        xs={12}
                        md={4}
                    >
                        <AltTextField
                            label="Code postal"
                            value={zip || ''}
                            onChange={(e) => handleChangeValue('zip', e)}
                            required
                            helperText={
                                errors.zip ? 'Le code postal doit contenir 5 chiffres' : null
                            }
                            error={errors.zip}
                            inputProps={{ size: 5 }}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={8}
                    >
                        <AltTextField
                            label="Ville"
                            value={city || ''}
                            onChange={(e) => handleChangeValue('city', e)}
                            required
                            error={errors.city}
                            inputProps={{ minLength: 2 }}
                        />
                    </Grid>
                </Grid>

                <AltTextField
                    label="Téléphone"
                    value={phone || ''}
                    onChange={(e) => handleChangeValue('phone', e)}
                    helperText={
                        errors.phone ? "Le numéro de téléphone n'est pas au bon format" : null
                    }
                    error={errors.phone}
                    type="tel"
                    inputProps={{ minLength: 3 }}
                />

                <AltTextField
                    label="Site web du lieu"
                    value={website || ''}
                    onChange={(e) => handleChangeValue('website', e)}
                    helperText={errors.website ? "L'URL doit être au format mondomaine.fr" : null}
                    error={errors.website}
                />
            </DialogContent>

            <DialogActions>
                {hasEmptyMandatoriesFields && (
                    <Typography variant="subtitle2">
                        Tous les champs obligatoires (*) doivent être remplis
                    </Typography>
                )}
                {!isValid && !hasEmptyMandatoriesFields && (
                    <Typography
                        color="error"
                        variant="subtitle2"
                    >
                        Merci de corriger les erreurs avant de valider
                    </Typography>
                )}
                <Button
                    variant="contained"
                    onClick={handleClose}
                    className={classes.cancelButton}
                >
                    Annuler
                </Button>
                <AltButton
                    type="submit"
                    variant="contrib"
                    onClick={handleSubmit}
                    disabled={!isValid}
                    endIcon={
                        isFetchingPostVenue ? (
                            <Loader
                                margin="none"
                                size="xxs"
                                color="white"
                            />
                        ) : null
                    }
                >
                    Valider
                </AltButton>
            </DialogActions>
        </form>
    );
}

export default VenueForm;
