import { URL_TARGET_BLANK, URL_TARGET_BLANK_REL } from '../../../constants';
import { addOrNotUrlPrefix, removeUrlPrefix } from '../../../utils';

import { Box } from '@mui/material';
import HttpIcon from '@mui/icons-material/Http';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { MailOutline as MailOutlineIcon } from '@mui/icons-material';
import PhoneIcon from '@mui/icons-material/Phone';
import React from 'react';
import Typography from '@mui/material/Typography';
import classnames from 'classnames';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    list: {
        padding: 0,
    },
    listDisplayEmpty: {
        '@media (min-width:960px)': {
            minHeight: 78,
        },
    },
    listItem: {
        padding: theme.spacing(0.5, 0),
    },
}));

function VenueContacts({
    boxProps,
    displayEmptyBlock,
    displayEmptyContacts,
    displayEmptyFields,
    venue,
}) {
    const classes = useStyles();

    if (!venue || (!displayEmptyContacts && !venue.email && !venue.phone && !venue.website)) {
        return null;
    }

    return (
        <Box {...boxProps}>
            <List
                className={classnames(classes.list, {
                    [classes.listDisplayEmpty]: displayEmptyBlock,
                })}
            >
                {(venue.email || displayEmptyFields) && (
                    <ListItem className={classes.listItem}>
                        <ListItemIcon>
                            <MailOutlineIcon fontSize="small" />
                        </ListItemIcon>
                        <Typography variant="body2">
                            {venue.email ? (
                                <Link href={`mailto:${venue.email}`}>{venue.email}</Link>
                            ) : (
                                'Non renseignée'
                            )}
                        </Typography>
                    </ListItem>
                )}
                {(venue.phone || displayEmptyFields) && (
                    <ListItem className={classes.listItem}>
                        <ListItemIcon>
                            <PhoneIcon fontSize="small" />
                        </ListItemIcon>
                        <Typography variant="body2">{venue.phone || 'Non renseigné'}</Typography>
                    </ListItem>
                )}
                {(venue.website || displayEmptyFields) && (
                    <ListItem className={classes.listItem}>
                        <ListItemIcon>
                            <HttpIcon fontSize="small" />
                        </ListItemIcon>
                        <Typography variant="body2">
                            {venue.website ? (
                                <Link
                                    href={addOrNotUrlPrefix(venue.website)}
                                    title="Visiter le site web de ce lieu"
                                    target={URL_TARGET_BLANK}
                                    rel={URL_TARGET_BLANK_REL}
                                >
                                    {removeUrlPrefix(venue.website)}
                                </Link>
                            ) : (
                                'Non renseigné'
                            )}
                        </Typography>
                    </ListItem>
                )}
            </List>
        </Box>
    );
}

export default VenueContacts;
