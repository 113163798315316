import { Alert, AlertTitle } from '@mui/material';
import { AltEm, AltLi, AltStrong, AltUl } from './AltEditorial';
import { Box, DialogContent, Typography, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';

import { APP_MAIL_INSCRIPTION } from '../../constants';
import AltLink from './AltLink';
import { ArrowRight as ArrowRightIcon } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { openDialog } from './AltDialog/AltDialogActions';
import { useDispatch } from 'react-redux';
import { userSubscribeDialog } from '../common/AltDialog/DialogsData';

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: theme.spacing(3), // todo : use variable > default Grid spacing
    },
    icon: (props) => ({
        marginLeft: props.isLargeOrMore ? 6 : -6,
        color: theme.palette.contrib.main,
    }),
    dialogLink: {
        background: 'none',
        border: 0,
        cursor: 'pointer',
        padding: 0,
        margin: 0,
        color: theme.palette.contrib.main,
        '-webkit-appearance': 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    ul: {
        margin: theme.spacing(2),
        padding: theme.spacing(0, 2),
        listStyle: 'disc outside',
        '& > li': {
            display: 'list-item',
        },
    },
}));

function UserAlertSubscribe() {
    const isMediumOrMore = useMediaQuery((theme) => theme.breakpoints.up('md'));
    const isLargeOrMore = useMediaQuery((theme) => theme.breakpoints.up('lg'));
    const classes = useStyles({ isLargeOrMore });
    const dispatch = useDispatch();
    const [open, toggleOpen] = useState(true);

    const openDialogUserSubscribe = () => {
        dispatch(openDialog(userSubscribeDialog));
    };

    return open ? (
        <Alert
            severity="info"
            variant="outlined"
            onClose={() => toggleOpen(false)}
            className={classes.root}
            closeText="Fermer"
        >
            <Box display={isLargeOrMore ? 'flex' : 'block'}>
                <AlertTitle gutterBottom={false}>
                    {isMediumOrMore ? 'Bienvenue ! ' : ''}
                    Vous organisez des événements
                    {isMediumOrMore ? ' dans le Périgord vert ?' : ' ?'}
                </AlertTitle>

                <Box
                    display="flex"
                    onClick={openDialogUserSubscribe}
                >
                    <ArrowRightIcon
                        fontSize="small"
                        className={classes.icon}
                    />
                    <Typography
                        component="button"
                        variant="body2"
                        className={classes.dialogLink}
                    >
                        Partagez-les sur <AltEm>CanalPV</AltEm>
                        {isMediumOrMore ? ' en créant un compte organisateur' : ''}
                    </Typography>
                </Box>
            </Box>
        </Alert>
    ) : null;
}

export const UserSubscribeDialogContent = () => {
    return (
        <DialogContent>
            <Box mb={3}>
                <Typography
                    component="div"
                    variant="body2"
                >
                    Merci de nous envoyer un e-mail à l'adresse{' '}
                    <AltLink
                        href={`mailto: ${APP_MAIL_INSCRIPTION}`}
                        title="Nous contacter pour demander la création d'un compte organisateur"
                    >
                        {APP_MAIL_INSCRIPTION}
                    </AltLink>{' '}
                    en précisant les informations suivantes :
                    <AltUl>
                        <AltLi>
                            <AltStrong>Nom de l'association</AltStrong> (ou du collectif)
                        </AltLi>
                        <AltLi>
                            <AltStrong>Adresse e-mail</AltStrong> (utilisée comme identifiant pour
                            se connecter)
                        </AltLi>
                        <AltLi>
                            <AltStrong>Description</AltStrong>
                            <br />
                            <AltEm>activités, raison sociale...</AltEm>
                        </AltLi>
                        <AltLi>
                            <AltStrong>Coordonnées</AltStrong> (qui apparaîtront sur le site)
                            <br />
                            <AltEm>e-mail, téléphone, site web, facebook...</AltEm>
                        </AltLi>
                    </AltUl>
                    Nous te répondrons au plus vite :)
                </Typography>
            </Box>
        </DialogContent>
    );
};

export default UserAlertSubscribe;
