import { Button, DialogActions, DialogContent, TextField, Typography } from '@mui/material/';
import React, { useState } from 'react';
import {
    resetPassword,
    setPassword,
} from '../../../providers/AuthenticationProvider/AuthenticationProviderActions';
import { useDispatch, useSelector } from 'react-redux';

import AltButton from '../../common/AltButton';
import Loader from '../../common/Loader';
import { closeDialog } from '../../common/AltDialog/AltDialogActions';
import { isEmail } from '../../../utils';

function UserLoginForm() {
    const dispatch = useDispatch();

    const [email, setEmail] = useState('');
    const [isValidEmail, setIsValidEmail] = useState();

    const [code, setCode] = useState('');
    const [isValidCode, setIsValidCode] = useState();

    const [newPassword, setNewPassword] = useState('');
    const [isValidNewPassword, setIsValidNewPassword] = useState();

    const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
    const [isValidNewPasswordConfirm, setIsValidNewPasswordConfirm] = useState();

    const { isFetchingResetPassword, isFetchingSetPassword, isSent } = useSelector((state) => ({
        isFetchingResetPassword: state.auth.isFetchingResetPassword,
        isFetchingSetPassword: state.auth.isFetchingSetPassword,
        isSent: state.auth.isSent,
    }));

    const handleClose = () => {
        dispatch(closeDialog('forgotPassword'));
    };

    const handleChangeEmail = (event) => {
        const emailValue = event.target.value;
        setEmail(emailValue);
        setIsValidEmail(isEmail(emailValue));
    };
    const handleSubmitReset = () => {
        dispatch(resetPassword(email));
    };

    const handleChangeCode = (event) => {
        const codeValue = event.target.value;
        setCode(codeValue);
        setIsValidCode(codeValue.length === 4 && !isNaN(codeValue));
    };
    const handleChangeNewPassword = (event) => {
        const passwordValue = event.target.value;
        setNewPassword(passwordValue);
        setIsValidNewPassword(passwordValue.length > 5);
    };
    const handleChangeNewPasswordConfirm = (event) => {
        const passwordValue = event.target.value;
        setNewPasswordConfirm(passwordValue);
        setIsValidNewPasswordConfirm(passwordValue === newPassword);
    };
    const handleSubmitSetPassword = () => {
        dispatch(setPassword({ code, email, password: newPassword }));
    };

    return !isSent ? (
        <>
            <DialogContent dividers>
                <form
                    noValidate
                    autoComplete="off"
                >
                    <Typography>
                        Saisi ton adresse e-mail pour recevoir un code de validation avant de
                        modifier le mot de passe :
                    </Typography>
                    <TextField
                        variant="outlined"
                        label="Adresse e-mail"
                        value={email}
                        onChange={handleChangeEmail}
                        fullWidth
                        type="email"
                        margin="normal"
                        autoComplete="email"
                        error={isValidEmail === false}
                        helperText={
                            isValidEmail === false
                                ? "L'adresse e-mail n'est pas au bon format."
                                : ''
                        }
                    />
                </form>
            </DialogContent>

            <DialogActions>
                <Button
                    variant="contained"
                    onClick={handleClose}
                >
                    Annuler
                </Button>
                <AltButton
                    variant="contrib"
                    onClick={handleSubmitReset}
                    endIcon={
                        isFetchingResetPassword ? (
                            <Loader
                                size="xxs"
                                margin="none"
                                color="white"
                            />
                        ) : null
                    }
                    disabled={!isValidEmail}
                >
                    Envoyer l'email
                </AltButton>
            </DialogActions>
        </>
    ) : (
        <>
            <DialogContent dividers>
                <form
                    noValidate
                    autoComplete="off"
                >
                    <Typography>
                        Saisi le code reçu par e-mail pour valider la réinitialisation :
                    </Typography>
                    <TextField
                        variant="outlined"
                        label="Code de confirmation"
                        value={code}
                        onChange={handleChangeCode}
                        fullWidth
                        margin="normal"
                        error={isValidCode === false}
                        helperText={
                            isValidCode === false ? 'Le code doit contenir 4 chiffres.' : ''
                        }
                    />
                    <TextField
                        variant="outlined"
                        label="Nouveau mot de passe"
                        value={newPassword}
                        onChange={handleChangeNewPassword}
                        fullWidth
                        type="password"
                        margin="normal"
                        error={isValidNewPassword === false}
                        helperText={'Le mot de passe doit contenir au moins 6 caractères.'}
                    />
                    <TextField
                        variant="outlined"
                        label="Confirmation du nouveau mot de passe"
                        value={newPasswordConfirm}
                        onChange={handleChangeNewPasswordConfirm}
                        fullWidth
                        type="password"
                        margin="normal"
                        error={isValidNewPasswordConfirm === false}
                        helperText={
                            isValidNewPasswordConfirm === false
                                ? "Le mot de passe n'est pas identique à celui saisi au dessus."
                                : ''
                        }
                    />
                </form>
            </DialogContent>

            <DialogActions>
                <Button
                    variant="contained"
                    onClick={handleClose}
                >
                    Annuler
                </Button>
                <AltButton
                    variant="contrib"
                    onClick={handleSubmitSetPassword}
                    endIcon={
                        isFetchingSetPassword ? (
                            <Loader
                                size="xxs"
                                margin="none"
                                color="white"
                            />
                        ) : null
                    }
                    disabled={
                        !isValidCode ||
                        !isValidEmail ||
                        !isValidNewPassword ||
                        !isValidNewPasswordConfirm
                    }
                >
                    Valider
                </AltButton>
            </DialogActions>
        </>
    );
}

export default UserLoginForm;
