import { Box, Paper, Typography } from '@mui/material';

import AltSelectWithSearch from '../../common/AltSelectWithSearch';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(2),
    },
    box: {
        width: '100%',
        maxWidth: 1200,
        '@media (min-width:960px)': {
            display: 'flex',
            gap: theme.spacing(2),
        },

        '& > div': {
            margin: theme.spacing(0, 0, 1),
            '@media (min-width:960px)': {
                flexGrow: 1,
                margin: 0,
            },
        },
    },
    title: {
        lineHeight: '40px',
        marginRight: theme.spacing(3),
    },
    resetFilters: {
        lineHeight: '40px',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
}));

function EventsFilters({
    handleChange,
    handleResetFilters,
    filters,
    fullWidth,
    isFetchingCategories,
    isFetchingOrganizers,
    isFetchingTags,
    isFetchingVenues,
    categories,
    organizers,
    tags,
    venues,
    hideOrganizers,
}) {
    const classes = useStyles();

    const getFilterLabel = (label, filters) => {
        const len = filters.length;
        return `${label} ${len > 0 ? `(${len})` : ''}`;
    };

    const filterCategoriesLabel = getFilterLabel('Catégories', filters.categories);
    const filterTagsLabel = getFilterLabel('Thématiques', filters.tags);
    const filterOrganizersLabel = getFilterLabel('Organisateurs', filters.organizers);
    const filterVenuesLabel = getFilterLabel('Lieux', filters.venues);

    return (
        <Paper className={classes.container}>
            <Box className={classes.box}>
                <Typography className={classes.title}>Filtrer</Typography>

                <AltSelectWithSearch
                    id="categories"
                    label={filterCategoriesLabel}
                    options={categories}
                    fullWidth={fullWidth}
                    filters={filters.categories}
                    isFetching={isFetchingCategories}
                    handleChange={handleChange}
                />

                <AltSelectWithSearch
                    id="tags"
                    label={filterTagsLabel}
                    options={tags}
                    fullWidth={fullWidth}
                    filters={filters.tags}
                    isFetching={isFetchingTags}
                    handleChange={handleChange}
                />

                <AltSelectWithSearch
                    id="venues"
                    label={filterVenuesLabel}
                    options={venues}
                    fullWidth={fullWidth}
                    filters={filters.venues}
                    isFetching={isFetchingVenues}
                    handleChange={handleChange}
                    nameKey="venue"
                />

                {!hideOrganizers && (
                    <AltSelectWithSearch
                        id="organizers"
                        label={filterOrganizersLabel}
                        options={organizers}
                        fullWidth={fullWidth}
                        filters={filters.organizers}
                        isFetching={isFetchingOrganizers}
                        handleChange={handleChange}
                        nameKey="organizer"
                    />
                )}

                {fullWidth && (
                    <Typography
                        color="secondary"
                        className={classes.resetFilters}
                        onClick={handleResetFilters}
                    >
                        Réinitialiser
                    </Typography>
                )}
            </Box>
        </Paper>
    );
}

export default EventsFilters;
