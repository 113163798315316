import { Box, Card, CardContent, Grid, Paper, Typography, useMediaQuery } from '@mui/material';

import AltAgendaEvents from '../../AltAgenda/AltAgendaEvents';
import AltButton from '../../common/AltButton';
import AltLink from '../../common/AltLink';
import { Edit as EditIcon } from '@mui/icons-material';
import OrganizerContacts from '../../Organizers/OrganizerContacts/OrganizerContacts';
import { ROUTE_PATH_ORGANIZER } from '../../../AppRoutes';
import React from 'react';
import VenueAddress from '../VenueAddress/VenueAddress';
import VenueContacts from '../VenueContacts/VenueContacts';
import VenueMap from '../VenueMap';
import { WP_ROLES_ADMIN } from '../../../constants';
import WriteHtml from '../../common/WriteHtml';
import { decodeHtmlEntities } from '../../../utils';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginBottom: theme.spacing(3),
    },
    deletedVenueTitle: {
        marginTop: theme.spacing(20),
    },
}));

function Venue({
    events,
    openDialogPostVenue,
    organizer,
    venue,
    isFetchingEvents,
    isLoading,
    user,
}) {
    const classes = useStyles();
    const isLargeOrMore = useMediaQuery((theme) => theme.breakpoints.up('lg'));

    return (
        <>
            {!isLoading && venue ? (
                <>
                    <Box
                        display={isLargeOrMore ? 'flex' : 'block'}
                        alignItems="center"
                        justifyContent="space-between"
                        mb={2}
                    >
                        <Typography
                            component="h2"
                            variant="h2"
                            color="primary"
                            gutterBottom={!isLargeOrMore}
                            noWrap
                        >
                            <WriteHtml>{venue.venue}</WriteHtml>
                        </Typography>

                        {(venue.author === user?.id || user?.roles?.includes(WP_ROLES_ADMIN)) && (
                            <AltButton
                                variant="contrib"
                                onClick={openDialogPostVenue}
                                startIcon={<EditIcon />}
                                lowerCase
                            >
                                Modifier le lieu
                            </AltButton>
                        )}
                    </Box>

                    <Grid
                        container
                        spacing={4}
                    >
                        <Grid
                            item
                            xs={12}
                            md={7}
                        >
                            {venue.description && (
                                <Paper className={classes.paper}>
                                    <Card
                                        elevation={0}
                                        square
                                    >
                                        <CardContent>
                                            <Typography
                                                variant="body2"
                                                component="div"
                                            >
                                                <WriteHtml component="div">
                                                    {venue.description}
                                                </WriteHtml>
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Paper>
                            )}

                            <Paper className={classes.paper}>
                                <Card
                                    className={classes.cardWithDivider}
                                    elevation={0}
                                    square
                                >
                                    <CardContent>
                                        <Typography variant="h3">Localisation</Typography>

                                        <VenueAddress
                                            venue={venue}
                                            displayEmpty
                                            boxProps={{ mt: 2, mb: venue.osm ? 2 : 0 }}
                                        />

                                        <VenueMap osm={venue.osm} />
                                    </CardContent>
                                </Card>
                            </Paper>

                            <Paper className={classes.paper}>
                                <Card
                                    elevation={0}
                                    square
                                >
                                    <CardContent>
                                        <Typography variant="h3">Coordonnées</Typography>

                                        <VenueContacts
                                            venue={venue}
                                            displayEmptyBlock
                                            displayEmptyFields
                                            displayEmptyContacts
                                            boxProps={{ mt: 2 }}
                                        />
                                    </CardContent>
                                </Card>
                            </Paper>

                            <Paper className={classes.paper}>
                                <Card
                                    elevation={0}
                                    square
                                >
                                    <CardContent>
                                        <Typography variant="h3">
                                            Prochains événements dans ce lieu
                                        </Typography>

                                        {events.length > 0 ? (
                                            <AltAgendaEvents
                                                events={events}
                                                isLoading={isFetchingEvents}
                                                detailsDense
                                            />
                                        ) : (
                                            <Box mt={3}>Aucun événement à venir</Box>
                                        )}
                                    </CardContent>
                                </Card>
                            </Paper>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            md={5}
                        >
                            {organizer && (
                                <Paper>
                                    <Card
                                        elevation={0}
                                        square
                                    >
                                        <CardContent>
                                            <Typography
                                                variant="h3"
                                                gutterBottom
                                            >
                                                Lieu ajouté par
                                            </Typography>

                                            <Box
                                                mt={1}
                                                mb={2}
                                            >
                                                <AltLink
                                                    to={`${ROUTE_PATH_ORGANIZER}/${organizer.id}`}
                                                    component="h3"
                                                    variant="h3"
                                                    title={`En savoir plus sur l'organisateur ${organizer.organizer}`}
                                                >
                                                    {decodeHtmlEntities(organizer.organizer)}
                                                </AltLink>
                                            </Box>

                                            {organizer.description && (
                                                <Box mb={2}>
                                                    <Typography variant="body2">
                                                        <WriteHtml>
                                                            {organizer.description}
                                                        </WriteHtml>
                                                    </Typography>
                                                </Box>
                                            )}

                                            <OrganizerContacts organizer={organizer} />
                                        </CardContent>
                                    </Card>
                                </Paper>
                            )}
                        </Grid>
                    </Grid>
                </>
            ) : (
                <>
                    <Typography
                        variant="h3"
                        component="h3"
                        align="center"
                        className={classes.deletedVenueTitle}
                        gutterBottom
                    >
                        Ce lieu n'existe pas ou a été supprimé
                    </Typography>
                    <AltLink
                        to="/"
                        aria-label="Retourner sur la page d'accueil"
                        component="div"
                        variant="body1"
                        align="center"
                    >
                        Retourner sur la page d'accueil
                    </AltLink>
                </>
            )}
        </>
    );
}

export default Venue;
