import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AltPage from '../../common/AltPage';
import DaysEvents from './DaysEvents';
import { getEvents } from '../EventsActions';
import moment from 'moment';
import { useParams } from 'react-router-dom';

function DaysEventsContainer() {
    const params = useParams();
    const dispatch = useDispatch();

    const currentDate = params.date;

    const { events, isFetchingEvents, isFetchingPostEvent, user } = useSelector((state) => ({
        events: state.events.list,
        isFetchingEvents: state.events.isFetchingEvents,
        isFetchingPostEvent: state.events.isFetchingPostEvent,
        user: state.users.user,
    }));

    useEffect(() => {
        dispatch(getEvents(user.isLogged));
    }, [dispatch, user]);

    const dayEvents = events.filter((ev) => {
        const evStartDate = moment(ev.startDate).format('YYYY-MM-DD');
        const evEndDate = moment(ev.endDate).format('YYYY-MM-DD');
        return (
            evStartDate === currentDate ||
            evEndDate === currentDate ||
            (moment(evStartDate).isBefore(currentDate) && moment(evEndDate).isAfter(currentDate))
        );
    });

    return (
        <AltPage
            user={user}
            meta={
                currentDate ? { title: `Événements du ${currentDate}` } : { metaTagsId: 'agenda' }
            }
        >
            <DaysEvents
                events={dayEvents}
                currentDate={currentDate}
                isLoading={isFetchingEvents || isFetchingPostEvent}
            />
        </AltPage>
    );
}

export default DaysEventsContainer;
