import { Box } from '@mui/material';
import React from 'react';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    listItem: {
        padding: theme.spacing(0.5, 0),
    },
}));

function VenueAddress({ boxProps, displayEmpty, venue }) {
    const classes = useStyles();

    return venue ? (
        <Box {...boxProps}>
            {(venue.address || displayEmpty) && (
                <Typography
                    variant="caption"
                    component="span"
                    className={classes.street}
                >
                    {venue.address || <span>&nbsp;</span>}
                </Typography>
            )}
            {(venue.city || displayEmpty) && (
                <Typography variant="body1">
                    {venue.city || <span>&nbsp;</span>}
                    {venue.zip && <span>&nbsp;({venue.zip})</span>}
                </Typography>
            )}
            {(venue.province || venue.stateprovince || displayEmpty) && (
                <Typography variant="caption">
                    <span>{venue.province || venue.stateprovince}</span>
                </Typography>
            )}
            {(venue.country || displayEmpty) && (
                <Typography variant="caption">
                    {(venue.province || venue.stateprovince) && <span>, </span>}
                    {venue.country || <span>&nbsp;</span>}
                </Typography>
            )}
        </Box>
    ) : null;
}

export default VenueAddress;
