import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    FormControl,
    FormLabel,
    Grid,
    ImageList,
    ImageListItem,
    TextField,
    Typography,
    useMediaQuery,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { decodeHtmlEntities, removeHtmlTags } from '../../utils';
import {
    deleteMedia,
    getMedias,
    getMoreMedias,
    updateMedia,
    uploadMedia,
} from '../Medias/MediasActions';
import { useDispatch, useSelector } from 'react-redux';

import AltButton from './AltButton';
import AltLink from './AltLink';
import Loader from './Loader';
import { WP_ROLES_ADMIN } from '../../constants';
import classnames from 'classnames';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { useReducer } from 'react';

const useStyles = makeStyles((theme) => ({
    loadingTile: {
        '& .MuiImageListItem-tile': {
            boxSizing: 'border-box',
            paddingTop: 45,
            backgroundColor: '#eeeeee',
        },
    },
    image: {
        cursor: 'pointer',
        border: '3px solid transparent',
        '&:hover': {
            borderColor: '#cccccc',
        },
        '& .MuiImageListItem-tile': {
            backgroundColor: '#eeeeee',
            border: '1px solid',
            borderColor: '#cccccc',
        },
        '& .MuiImageListItem-imgFullHeight': {
            height: 'auto',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%) translateX(-50%)',
        },
    },
    selectedImage: {
        cursor: 'default',
        border: '3px solid',
        borderColor: theme.palette.contrib.main,
        '&:hover': {
            borderColor: theme.palette.contrib.main,
        },
    },
    selectedImageThumb: {
        maxWidth: '100%',
        maxHeight: 150,
    },
    aside: {
        backgroundColor: '#eeeeee',
        margin: theme.spacing(1.5, 0),
    },
}));

const textFieldProps = {
    variant: 'outlined',
    margin: 'dense',
    size: 'small',
    fullWidth: true,
};

const initialState = {
    title: '',
    altText: '',
    caption: '',
    description: '',
};

const reducer = (state, action) => {
    const { value } = action;
    switch (action.type) {
        case 'init':
            return {
                id: value.id,
                title: value.title.raw || value.title.rendered || '',
                altText: value.altText,
                caption: value.caption.raw || removeHtmlTags(value.caption.rendered)?.trim() || '',
                description:
                    value.description.raw ||
                    removeHtmlTags(value.description.rendered)?.trim() ||
                    '',
            };

        case 'title':
            return {
                ...state,
                title: value,
            };
        case 'altText':
            return {
                ...state,
                altText: value,
            };
        case 'caption':
            return {
                ...state,
                caption: value,
            };
        case 'description':
            return {
                ...state,
                description: value,
            };
        default:
            throw new Error('Unexpected action');
    }
};

const MEDIAS_PER_PAGE = 96;

function MediaForm({ handleClose, setSelectedImageUrl, isThumb }) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const isMediumOrMore = useMediaQuery((theme) => theme.breakpoints.up('md'));
    const isLargeOrMore = useMediaQuery((theme) => theme.breakpoints.up('lg'));

    let gridListConfig = {
        cols: 3,
        rowHeight: 102,
    };
    if (isMediumOrMore) {
        gridListConfig = {
            cols: 5,
            rowHeight: 135,
        };
    }
    if (isLargeOrMore) {
        gridListConfig = {
            cols: 6,
            rowHeight: 141,
        };
    }

    const [form, setForm] = useReducer(reducer, initialState);
    const [currentPage, setCurrentPage] = useState(1);
    const [isInit, setIsInit] = useState();

    const {
        medias,
        isFetchingMedias,
        isFetchingUploadImage,
        isFetchingMoreMedias,
        isFetchingUpdateMedia,
        isFetchingDeleteMedia,
        user,
    } = useSelector((state) => ({
        medias: state.medias.list,
        isFetchingMedias: state.medias.isFetchingMedias,
        isFetchingUploadImage: state.medias.isFetchingUploadImage,
        isFetchingMoreMedias: state.medias.isFetchingMoreMedias,
        isFetchingUpdateMedia: state.medias.isFetchingUpdateMedia,
        isFetchingDeleteMedia: state.medias.isFetchingDeleteMedia,
        user: state.users.user,
    }));

    const userId = user.roles?.includes(WP_ROLES_ADMIN) ? null : user.id;

    useEffect(() => {
        if (!isInit) {
            dispatch(getMedias(userId, currentPage, MEDIAS_PER_PAGE, 'image'));
            setIsInit(true);
        }
    }, [dispatch, isInit, setIsInit, userId, currentPage]);

    const handleSetSelectedImage = (media) => {
        setForm({ type: 'init', value: media });
        if (setSelectedImageUrl) {
            const url = isThumb ? media.mediaDetails.sizes.thumbnail.sourceUrl : media.sourceUrl;
            setSelectedImageUrl(url);
        }
    };

    const handleChange = (type, e) => {
        setForm({ type, value: e.currentTarget.value });
    };

    const handleSaveMedia = () => {
        dispatch(updateMedia(form));
    };

    const handleDeleteMedia = (id) => {
        dispatch(deleteMedia(id));

        if (id === form.id) {
            setForm({ type: 'id', value: null });
            setSelectedImageUrl(null);
        }
    };

    const handleUploadImage = (e) => {
        dispatch(uploadMedia(e.target.files[0]));
    };

    const handleDisplayMore = () => {
        const page = currentPage + 1;
        setCurrentPage(page);
        dispatch(getMoreMedias(userId, page, MEDIAS_PER_PAGE, 'image'));
    };

    const selectedImage = medias && form.id ? medias.find((media) => media.id === form.id) : null;

    const getThumbnail = (media) =>
        media.mediaDetails.sizes.thumbnail &&
        media.mediaDetails.width > media.mediaDetails.sizes.thumbnail.width &&
        media.mediaDetails.height > media.mediaDetails.sizes.thumbnail.height
            ? media.mediaDetails.sizes.thumbnail.sourceUrl
            : media.sourceUrl;

    const { id, title, altText, caption, description } = form;

    return (
        <>
            <DialogContent dividers>
                <Grid
                    container
                    spacing={4}
                >
                    <Grid
                        item
                        xs={12}
                        md={9}
                    >
                        <Box
                            mt={3}
                            mb={5}
                        >
                            <FormControl component="fieldset">
                                <FormLabel>
                                    Importer une nouvelle image depuis mes documents
                                </FormLabel>

                                <TextField
                                    name="imageToUpload"
                                    type="file"
                                    onChange={handleUploadImage}
                                    fullWidth
                                    disabled={isFetchingUploadImage}
                                />
                            </FormControl>
                        </Box>

                        <Box mb={3}>
                            <Typography
                                variant="h4"
                                gutterBottom
                            >
                                Mes images
                            </Typography>
                            {!isFetchingMedias && !isFetchingDeleteMedia ? (
                                <>
                                    <ImageList
                                        rowHeight={gridListConfig.rowHeight}
                                        className={classes.gridList}
                                        cols={gridListConfig.cols}
                                        spacing={10}
                                    >
                                        {isFetchingUploadImage && (
                                            <ImageListItem
                                                key="loading"
                                                cols={1}
                                                style={{ height: 'auto' }}
                                                className={classnames(
                                                    classes.loadingTile,
                                                    classes.image,
                                                )}
                                            >
                                                <Loader
                                                    margin="none"
                                                    size="sm"
                                                />
                                            </ImageListItem>
                                        )}

                                        {medias.map((media) => (
                                            <ImageListItem
                                                key={media.id}
                                                cols={1}
                                                onClick={() => handleSetSelectedImage(media)}
                                                className={classnames(classes.image, {
                                                    [classes.selectedImage]: id === media.id,
                                                })}
                                            >
                                                <img
                                                    src={getThumbnail(media)}
                                                    alt={media.altText}
                                                    width="150px"
                                                    height="150px"
                                                />
                                            </ImageListItem>
                                        ))}
                                    </ImageList>

                                    {medias.length === 0 && !isFetchingUploadImage && (
                                        <Box mt={3}>
                                            <Typography variant="body1">
                                                Aucune image n'a été postée par ton organisation.
                                            </Typography>
                                        </Box>
                                    )}

                                    {(medias.length === currentPage * MEDIAS_PER_PAGE ||
                                        isFetchingMoreMedias) && (
                                        <Box
                                            align="center"
                                            mt={3}
                                        >
                                            <Button
                                                variant="outlined"
                                                onClick={handleDisplayMore}
                                                endIcon={
                                                    isFetchingMoreMedias ? (
                                                        <Loader
                                                            size="xxs"
                                                            margin="none"
                                                        />
                                                    ) : null
                                                }
                                                disabled={isFetchingMoreMedias}
                                            >
                                                Afficher plus
                                            </Button>
                                        </Box>
                                    )}
                                </>
                            ) : (
                                <Loader />
                            )}
                        </Box>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={3}
                        className={classes.aside}
                    >
                        {selectedImage && (
                            <Box>
                                <img
                                    src={selectedImage.sourceUrl}
                                    alt={selectedImage.altText}
                                    className={classes.selectedImageThumb}
                                    width="150px"
                                    height="150px"
                                />

                                <Box mt={1}>
                                    <Typography variant="body2">
                                        Ajoutée le{' '}
                                        {moment(selectedImage.date).format('DD MMMM YYYY')}
                                    </Typography>
                                    <Typography variant="body2">
                                        Dimensions : {selectedImage.mediaDetails.width}px par{' '}
                                        {selectedImage.mediaDetails.height}px
                                    </Typography>
                                </Box>

                                <Box mt={1}>
                                    <AltLink
                                        onClick={() => handleDeleteMedia(selectedImage.id)}
                                        color="error"
                                    >
                                        Supprimer définitivement
                                    </AltLink>
                                </Box>

                                <Box mt={3}>
                                    <TextField
                                        label="Titre"
                                        value={decodeHtmlEntities(title)}
                                        onChange={(e) => handleChange('title', e)}
                                        {...textFieldProps}
                                    />
                                    <TextField
                                        label="Texte alternatif"
                                        value={altText}
                                        onChange={(e) => handleChange('altText', e)}
                                        {...textFieldProps}
                                    />
                                    <TextField
                                        label="Légende"
                                        value={caption}
                                        onChange={(e) => handleChange('caption', e)}
                                        {...textFieldProps}
                                    />
                                    <TextField
                                        multiline
                                        rows={5}
                                        label="Description"
                                        value={description}
                                        onChange={(e) => handleChange('description', e)}
                                        {...textFieldProps}
                                    />
                                </Box>

                                <Box mt={1}>
                                    <AltButton
                                        variant="contrib"
                                        onClick={handleSaveMedia}
                                        size="small"
                                        disabled={isFetchingUpdateMedia}
                                        endIcon={
                                            isFetchingUpdateMedia ? (
                                                <Loader
                                                    size="xxs"
                                                    margin="none"
                                                />
                                            ) : null
                                        }
                                    >
                                        Enregistrer
                                    </AltButton>
                                </Box>
                            </Box>
                        )}
                    </Grid>
                </Grid>
            </DialogContent>

            {setSelectedImageUrl && (
                <DialogActions>
                    <AltButton
                        variant="contrib"
                        disabled={!id}
                        onClick={handleClose}
                    >
                        Valider
                    </AltButton>
                </DialogActions>
            )}
        </>
    );
}

export default MediaForm;
