import { Box, Card, CardContent, CardMedia, Grid } from '@mui/material';

import { EVENT_IMAGE_PLACEHOLDER } from '../../../../constants';
import EventHeaderHour from '../../EventHeaderHour/EventHeaderHour';
import EventOrganizer from '../../EventInfos/EventOrganizer/EventOrganizer';
import { Link } from 'react-router-dom';
import { ROUTE_PATH_EVENT } from '../../../../AppRoutes';
import React from 'react';
import Typography from '@mui/material/Typography';
import WriteHtml from '../../../common/WriteHtml';
import classnames from 'classnames';
import { decodeHtmlEntities } from '../../../../utils';
import { makeStyles } from '@mui/styles';
import styles from './AltAgendaEventStyles';
import { toUpper } from 'lodash';
import useMediaQuery from '@mui/material/useMediaQuery';

const useStyles = makeStyles(styles);

function AltAgendaEvent({
    detailsDense,
    event,
    event: { allDay, id, image, venue, name, organizer, start },
}) {
    const classes = useStyles();
    const isMediumOrMore = useMediaQuery((theme) => theme.breakpoints.up('md'));

    const venueCity = venue.city ? `${toUpper(venue.city)} ` : '';
    const venueName = venue.venue && venue.venue !== venue.city ? `(${venue.venue})` : '';
    const venueTitle = `${venueCity}${venueName}`;

    const organizerName = decodeHtmlEntities(organizer?.[0]?.organizer);
    const eventName = decodeHtmlEntities(name);

    const baseLinkTitle = `En savoir plus sur l'événement ${eventName}`;
    const linkTitle = venue.city ? `${baseLinkTitle}, à ${venue.city}` : baseLinkTitle;

    return event ? (
        <Card
            className={classes.cardWithDivider}
            elevation={0}
            square
        >
            <Link
                to={`${ROUTE_PATH_EVENT}/${id}`}
                className={classes.boxLink}
                title={linkTitle}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    className={classnames(classes.details, {
                        [classes.detailsDense]: detailsDense,
                    })}
                >
                    <CardContent className={classes.content}>
                        <Box display={isMediumOrMore ? 'flex' : 'block'}>
                            <EventHeaderHour
                                start={start}
                                allDay={allDay}
                                className={classes.hour}
                            />

                            <Grid
                                item
                                xs
                                zeroMinWidth
                                className={classes.description}
                            >
                                <Typography
                                    component="h2"
                                    variant="h3"
                                    color="secondary"
                                    className={classes.name}
                                    gutterBottom
                                    noWrap
                                >
                                    {eventName}
                                </Typography>

                                {venueTitle && (
                                    <Typography
                                        component="h3"
                                        variant="body2"
                                        noWrap
                                    >
                                        <WriteHtml>{venueTitle}</WriteHtml>
                                    </Typography>
                                )}

                                <EventOrganizer
                                    organizer={{ ...organizer, organizer: organizerName }}
                                    dense
                                    noWrap
                                    showLabel={isMediumOrMore}
                                />
                            </Grid>
                        </Box>
                    </CardContent>
                </Box>

                {isMediumOrMore && (
                    <CardMedia
                        className={classes.image}
                        image={image.url || EVENT_IMAGE_PLACEHOLDER}
                        title={eventName}
                    />
                )}
            </Link>
        </Card>
    ) : null;
}

export default AltAgendaEvent;
