import React, { useEffect } from 'react';
import { organizerDialog, postVenueDialog } from '../../common/AltDialog/DialogsData';
import { useDispatch, useSelector } from 'react-redux';

import AltPage from '../../common/AltPage';
import Organizer from './Organizer';
import OrganizerForm from '../OrganizerForm/OrganizerForm';
import VenueForm from '../../Venues/VenueForm/VenueForm';
import { filterPastEvents } from '../../AltAgenda/AltAgenda.utils';
import { getEvents } from '../../Events/EventsActions';
import { getOrganizer } from '../OrganizersActions';
import { getUsers } from '../../User/UserActions';
import { getVenues } from '../../Venues/VenuesActions';
import { openDialog } from '../../common/AltDialog/AltDialogActions';
import { removeHtmlTags } from '../../../utils';
import { useParams } from 'react-router-dom';

function OrganizerContainer() {
    const params = useParams();
    const dispatch = useDispatch();

    const {
        events,
        isFetchingEvents,
        isFetchingOrganizers,
        isFetchingVenues,
        organizer,
        user,
        users,
        venues,
    } = useSelector((state) => ({
        events: state.events.list,
        venues: state.venues.list,
        organizer: state.organizers.organizer,
        isFetchingEvents: state.events.isFetchingEvents,
        isFetchingOrganizers: state.organizers.isFetchingOrganizers,
        isFetchingVenues: state.venues.isFetchingVenues,
        user: state.users.user,
        users: state.users.list,
    }));

    const organizerId = parseInt(params.id, 10);

    useEffect(() => {
        dispatch(getVenues(user.isLogged));
        dispatch(getOrganizer(organizerId));
        dispatch(getEvents(user.isLogged));
        dispatch(getUsers());
    }, [dispatch, organizerId, user]);

    const openDialogOrganizer = () => {
        dispatch(
            openDialog({
                ...organizerDialog,
                component: <OrganizerForm user={user} />,
            }),
        );
    };

    const openDialogPostVenue = (venue) => {
        dispatch(
            openDialog({
                ...postVenueDialog,
                component: <VenueForm venue={venue} />,
            }),
        );
    };

    const organizerEvents = organizer
        ? events.filter((event) => event.author === organizer.author)
        : [];
    const organizerVenues = organizer
        ? venues.filter((venue) => venue.author === organizer.author)
        : [];

    const futureOrganizerEvents = filterPastEvents(organizerEvents);

    return (
        <AltPage
            user={user}
            meta={
                organizer
                    ? {
                          title: organizer.organizer,
                          description: removeHtmlTags(organizer.description),
                      }
                    : { metaTagsId: 'default' }
            }
        >
            <Organizer
                organizer={organizer}
                events={futureOrganizerEvents}
                venues={organizerVenues}
                isLoading={isFetchingOrganizers}
                isFetchingEvents={isFetchingEvents}
                isFetchingVenues={isFetchingVenues}
                openDialogOrganizer={openDialogOrganizer}
                openDialogPostVenue={openDialogPostVenue}
                user={user}
                users={users}
            />
        </AltPage>
    );
}

export default OrganizerContainer;
